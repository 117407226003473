.header {
  display: block;
  border-bottom: 2px solid black;
  /* overflow: hidden; */
}

.row {
  display: grid;
  /*grid-gap: 0px 24px;*/
  block-size: 87px;
  align-items: stretch;
  padding: 0px 24px;
  background-color: #cadce2;

  @media (--md) {

  }
}
