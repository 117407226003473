.overlay {
  position: fixed;
  top: 0;
  display: none;
  inline-size: 100%;
  block-size: 100%;
  background-color: rgba(36, 41, 44, 0.2);
  z-index: 3;

  &:hover {
    cursor: pointer;
  }
}

.overlay-open {
  display: block;
}

.sidebar {
  inline-size: 100%;
  block-size: 100%;
  max-inline-size: 320px;
  padding: 24px 32px;
  background-color: #24292c;
  overflow-y: auto;
  z-index: 3;
}

.button-close {
  margin-inline-end: -8px;
  margin-block-end: 24px;

  & :global(svg) {
    stroke: var(--white);
    inline-size: 14px;
    block-size: 14px;
  }
}

.navigation {
  flex-direction: column;

  & :global(li) {
    &:not(:last-of-type) {
      margin-inline-end: 0;
      margin-block-end: 24px;
    }
  }
}
