.container {
  position: relative;
  inline-size: 100%;
  block-size: auto;
  padding-block-start: 68.8752%;
  border-radius: 10px;
  overflow: hidden;

  @media (--sm) {
    padding-block-start: 0;
    inline-size: 312px;
    block-size: 312px;
    margin: auto;
  }

  @media (--md) {
    grid-area: 1 / 2;
  }

  @media (--lg) {
    grid-area: initial;
  }
}

.container-empty {
  border: 2px dashed #c1d1d8;
}

.btn-upload {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  &:hover {
    cursor: pointer;
  }
}

.icon {
  inline-size: 72px;
  block-size: 72px;
  margin-block-end: 16px;
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  inline-size: 100%;
  object-fit: cover;
}

.btn-remove {
  position: absolute;
  top: 16px;
  right: 16px;

  & svg {
    inline-size: 12px;
    block-size: 16px;
  }
}

.file-input {
  display: none;
}

.error {
  color: var(--red);

  @media (--md) {
    grid-area: 2 / 2;
  }

  @media (--lg) {
    grid-area: initial;
  }
}
