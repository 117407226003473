.container {
    padding-block-start: 40px;
    padding-block-end: 40px;

}
.wrapper {
  max-inline-size: 1380px;
}
.blocks-wrapper{
  display: block;
  @media (--md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.4rem;
  }
}