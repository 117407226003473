.Header_header__1peyf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
  background: var(--blue)
}

.Header_img__25tBV {
  width: 6.375rem;
  height: 6.1875rem
}

@media all and (min-width: 768px) {

.Header_header__1peyf {
    height: 25.5625rem
}

.Header_img__25tBV {
    width: 16.8125rem;
    height: 16.3125rem
}
}

@media all and (min-width: 1440px) {

.Header_header__1peyf {
    width: 100%;
    max-width: 43%;
    height: auto;
    height: initial
}
}


  .Layout_content__2eknG {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}

@media all and (min-width: 768px) {

.Layout_content__2eknG {
    padding: 4.75rem 0;
    background-color: #e5e5e5
}
}

@media all and (min-width: 1440px) {

.Layout_wrapper__2wx5I {
    display: flex;
    height: 100vh
}
}

.ErrorText_error__1DL0q {
  font-size: 0.875rem;
  line-height: 145%;
  color: var(--red);
}

.SubmitButton_btn__xU6fh {
  width: 100%;
  height: 3rem;
  background-color: var(--blue);
  color: var(--white);
  font-weight: 700;
  border-radius: 4px
}

.SubmitButton_btn__xU6fh[disabled]:hover {
    cursor: not-allowed;
  }

.SubmitButton_submitting__vX-Fb {
  padding: 0.375rem 0;
}

.SubmitButton_img__NY7o4 {
  height: 100%;
}

.AuthForm_form__15rFg {
  width: 100%;
  background-color: var(--white);
  padding: 1.5rem 1.5rem 2.5rem
}

.AuthForm_title__t-oVU {
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  color: var(--black);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 116%
}

.AuthForm_error__3cNUn {
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem
}

.AuthForm_btn__28EJ8 {
  font-size: 1.125rem
}

@media all and (min-width: 768px) {

.AuthForm_form__15rFg {
    width: 100%;
    max-width: 23rem;
    padding: 2rem 2.5rem;
    border-radius: 10px
}

.AuthForm_title__t-oVU {
    -webkit-margin-after: 1.5rem;
            margin-bottom: 1.5rem
}

.AuthForm_error__3cNUn {
    -webkit-margin-after: 1.5rem;
            margin-bottom: 1.5rem
}

.AuthForm_btn__28EJ8 {
    height: 3.5rem
}
}

.FieldSet_fieldset__h6t6j {
  border: 0;
}

.FieldSet_wrapper__3LIYf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem;
}

.FieldSet_label__3Pp8w {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--black);
}

.FieldSet_error__2w5JF {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
}

.helpers_truncate__1oj33 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.helpers_visually-hidden__2s-76 {
  position: absolute !important;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
  overflow: hidden;
}

.TextField_field__1k3j8 {
  height: 3rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  transition: border-color ease-in-out 0.2s
}

.TextField_field__1k3j8:hover {
    border-color: #b0c9d3;
  }

.TextField_field__1k3j8:focus {
    border-color: #8fb3c1;
  }

.TextField_field-error__p8caa {
  border-color: var(--red) !important;
}

.TextField_error__YScqI {
  font-size: 0.875rem;
  line-height: 145%;
  color: var(--red);
}

.PasswordField_wrapper__1ru87 {
  position: relative;
  display: flex;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  background-color: var(--white);
  overflow: hidden;
  transition: border-color ease-in-out 0.2s
}

.PasswordField_wrapper__1ru87:hover {
    border-color: #b0c9d3;
  }

.PasswordField_wrapper-focused__ogcRe {
  border-color: #8fb3c1
}

.PasswordField_wrapper-focused__ogcRe:hover {
    border-color: #8fb3c1;
  }

.PasswordField_wrapper-error__1nYyi {
  border-color: var(--red);
  background-color: #faf5f6
}

.PasswordField_wrapper-error__1nYyi:hover {
    border-color: var(--red);
  }

[dir="ltr"] .PasswordField_field__39FPM {
          padding-left: 1rem
}

[dir="rtl"] .PasswordField_field__39FPM {
          padding-right: 1rem
}

[dir="ltr"] .PasswordField_field__39FPM {
          padding-right: 3.4375rem
}

[dir="rtl"] .PasswordField_field__39FPM {
          padding-left: 3.4375rem
}

.PasswordField_field__39FPM {
  height: 3rem;
  width: 100%;
  -webkit-padding-start: 1rem;
  -webkit-padding-end: 3.4375rem;
  border: 0;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  background-color: transparent;
}

.PasswordField_btn__1vOTr {
  position: absolute;
  right: 0;
  height: 100%;
  border: 0;
  background-color: transparent;
}

.PasswordField_icon__3AaI7 {
  height: 1.4375rem;
  width: 1.4375rem;
  margin: 0 1rem;
  fill: #95b4c1 !important;
}

.PasswordField_error__3D3qH {
  font-size: 0.875rem;
  line-height: 145%;
  color: var(--red);
}

.CheckboxField_wrapper__1EU6h {
  display: flex;
}

.CheckboxField_field__1LK-u {
  display: none;
}

[dir="ltr"] .CheckboxField_checkbox__3nBsL {
          margin-right: 0.75rem;
}

[dir="rtl"] .CheckboxField_checkbox__3nBsL {
          margin-left: 0.75rem;
}

.CheckboxField_checkbox__3nBsL {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  -webkit-margin-end: 0.75rem;
  border-radius: 4px;
  border: 1px solid #d0dfe6;
  background-color: transparent;
}

.CheckboxField_checkbox-checked__3-iVi {
  border-color: var(--blue);
}

.CheckboxField_checkmark__1mf0n {
  height: 0.625rem;
  width: 1.0625rem;
  -webkit-margin-before: 0.0625rem;
          margin-top: 0.0625rem;
}

.CheckboxField_label__3E1UI {
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
}


  .Login_fieldset__30Lta:first-of-type {
    -webkit-margin-after: 0.625rem;
            margin-bottom: 0.625rem;
  }

.Login_link__1PqcD {
  font-size: 1rem;
  line-height: 150%;
  color: var(--blue);
}

.Login_checkbox__1qyZJ {
  margin: 1.5rem 0;
}

.BaseModal_overlay__Ro9m4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 0.5rem;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.BaseModal_modal__1lwgg {
  width: 100%;
  margin: auto;
  background: var(--white);
  outline: none;
  overflow: auto;
}

[dir="ltr"] .ButtonClose_btn__2Qfg6 {
          margin-left: auto;
}

[dir="rtl"] .ButtonClose_btn__2Qfg6 {
          margin-right: auto;
}

.ButtonClose_btn__2Qfg6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-margin-start: auto;
  border: 0;
  background-color: transparent;
}

.ButtonClose_icon__1IPMS {
  width: 0.75rem;
  height: 0.75rem;
}

.ResetPasswordModal_modal__2FfmJ {
  max-width: 23rem;
  padding: 1.5rem;
  border-radius: 10px
}

[dir="ltr"] .ResetPasswordModal_btn__2D2ew {
          margin-right: -0.625rem
}

[dir="rtl"] .ResetPasswordModal_btn__2D2ew {
          margin-left: -0.625rem
}

.ResetPasswordModal_btn__2D2ew {
  -webkit-margin-before: -0.625rem;
          margin-top: -0.625rem;
  -webkit-margin-end: -0.625rem;
}

.ResetPasswordModal_text__Vn947 {
  font-size: 1.125rem;
  line-height: 133%;
  color: var(--black);
  font-weight: 500
}

@media all and (min-width: 768px) {

.ResetPasswordModal_modal__2FfmJ {
    padding: 2rem
}

.ResetPasswordModal_text__Vn947 {
    font-size: 1.5rem
}
}

.ResetPassword_text__2_eBh {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.ResetPassword_fieldset__194bD {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem;
}

@media all and (min-width: 768px) {

.ResetPassword_text__2_eBh {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}
}

.ChangePasswordModal_modal__1CUQW {
  max-width: 23rem;
  padding: 1.5rem;
  border-radius: 6px
}

[dir="ltr"] .ChangePasswordModal_mark__GEyuW {
          margin-left: auto
}

[dir="rtl"] .ChangePasswordModal_mark__GEyuW {
          margin-right: auto
}

[dir="ltr"] .ChangePasswordModal_mark__GEyuW {
          margin-right: auto
}

[dir="rtl"] .ChangePasswordModal_mark__GEyuW {
          margin-left: auto
}

.ChangePasswordModal_mark__GEyuW {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  -webkit-margin-after: 1.375rem;
          margin-bottom: 1.375rem;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  border-radius: 50%;
  box-shadow: 0 1rem 1.5rem -0.25rem rgba(2, 45, 63, 0.16)
}

.ChangePasswordModal_text__1tBF1 {
  -webkit-margin-after: 1.375rem;
          margin-bottom: 1.375rem;
  font-size: 1.125rem;
  line-height: 133%;
  color: var(--black);
  font-weight: 500;
  text-align: center
}

.ChangePasswordModal_icon__3uc81 {
  width: 2.3125rem;
  height: 1.4375rem;
}

.ChangePasswordModal_link__2ZIHD {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  background-color: var(--blue);
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--white);
}

@media all and (min-width: 768px) {

.ChangePasswordModal_modal__1CUQW {
    padding: 2rem
}

.ChangePasswordModal_mark__GEyuW {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.ChangePasswordModal_text__1tBF1 {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem;
    font-size: 1.5rem
}
}

.ChangePassword_fieldset__2FYca {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}
.ChangePassword_fieldset__2FYca:last-of-type {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem;
  }

.Container_container__guXhm {
  width: 100%;
  padding: 0 0.5rem
}

.Container_wrapper__OWctk {
  max-width: 95.625rem;
  margin: 0 auto;
}

@media all and (min-width: 768px) {

.Container_container__guXhm {
    padding: 0 1rem
}
}

.Logo_logo__1SxUl {
  width: 5.1875rem;
  height: 1.625rem;
}

[dir="ltr"] .NavList_list__3adXT {
          margin-left: 1.25rem
}

[dir="rtl"] .NavList_list__3adXT {
          margin-right: 1.25rem
}

.NavList_list__3adXT {
  -webkit-margin-start: 1.25rem
}

.NavList_item__F3nO8 {
  display: flex;
  align-items: center
}

.NavList_item__F3nO8:not(:last-of-type) {
    -webkit-margin-after: 1.5rem;
            margin-bottom: 1.5rem
  }

.NavList_link__2iXQ2 {
  font-size: 1rem;
  line-height: 120%;
  color: var(--white);
  font-weight: 700;
}

@media all and (min-width: 992px) {

.NavList_list__3adXT {
    display: flex
}

[dir="ltr"] .NavList_item__F3nO8:not(:last-of-type) {
              margin-right: 2rem
          }

[dir="rtl"] .NavList_item__F3nO8:not(:last-of-type) {
              margin-left: 2rem
          }

.NavList_item__F3nO8:not(:last-of-type) {
      -webkit-margin-after: 0;
              margin-bottom: 0;
      -webkit-margin-end: 2rem
  }
}

[dir="ltr"] .UserMenu_container__32WHa {
          margin-right: -0.5rem
}

[dir="rtl"] .UserMenu_container__32WHa {
          margin-left: -0.5rem
}

.UserMenu_container__32WHa {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-margin-end: -0.5rem;
  padding: 0.5rem
}

.UserMenu_container__32WHa:hover {
    background-color: #404a50;
    cursor: pointer;
  }

.UserMenu_container-active__vhO3N {
  background-color: #404a50;
}

.UserMenu_image-wrapper__iDWmB {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #565f64;
  overflow: hidden
}

.UserMenu_image__1AgRi {
  width: 100%;
}

[dir="ltr"] .UserMenu_user-icon__2Uydt {
          margin-left: 0.0625rem
}

[dir="rtl"] .UserMenu_user-icon__2Uydt {
          margin-right: 0.0625rem
}

.UserMenu_user-icon__2Uydt {
  -webkit-margin-before: -0.25rem;
          margin-top: -0.25rem;
  -webkit-margin-start: 0.0625rem;
  width: 1.0625rem;
  height: 1.375rem;
}

.UserMenu_user-name__1dOLZ {
  display: flex;
  align-items: center;
  max-width: 10.3125rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--white);
  font-weight: 700;
}

[dir="ltr"] .UserMenu_triangle__X6718 {
          margin-left: 1rem
}

[dir="rtl"] .UserMenu_triangle__X6718 {
          margin-right: 1rem
}

.UserMenu_triangle__X6718 {
  -webkit-margin-start: 1rem;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--white);
}

.UserMenu_triangle-active__1iTIQ {
  transform: rotate(180deg);
}

.UserMenu_list__KXE6l {
  position: absolute;
  top: 3.875rem;
  right: 0.5rem;
  width: 9.375rem;
  -webkit-margin-before: 0.125rem;
          margin-top: 0.125rem;
  padding: 0.625rem 0;
  border-radius: 5px;
  background-color: #24292c;
  box-shadow: 0 0.375rem 0.625rem 0.125rem rgba(134, 134, 134, 0.2);
  z-index: 3
}

.UserMenu_list__KXE6l::before {
    content: '';
    position: absolute;
    top: -0.3125rem;
    right: 1rem;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #24292c
  }

[dir="ltr"] .UserMenu_button__TAVl7 {
  text-align: left
}

[dir="rtl"] .UserMenu_button__TAVl7 {
  text-align: right
}

.UserMenu_button__TAVl7 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.75rem;
  padding: 0 1.0625rem;
  font-size: 0.875rem;
  color: var(--white);
  font-weight: 500
}

.UserMenu_button__TAVl7:hover {
    background-color: var(--blue);
  }

@media all and (min-width: 768px) {

[dir="ltr"] .UserMenu_container__32WHa {
            margin-right: -1rem
          }

[dir="rtl"] .UserMenu_container__32WHa {
            margin-left: -1rem
          }

.UserMenu_container__32WHa {
    -webkit-margin-end: -1rem;
    padding: 0.75rem
}

[dir="ltr"] .UserMenu_image-wrapper__iDWmB {
            margin-right: 1rem
          }

[dir="rtl"] .UserMenu_image-wrapper__iDWmB {
            margin-left: 1rem
          }

.UserMenu_image-wrapper__iDWmB {
    -webkit-margin-end: 1rem
}

.UserMenu_image-wrapper-active__2OVY0 {
    background-color: #24292c
}

.UserMenu_list__KXE6l {
    top: 4.375rem
}

.UserMenu_list__KXE6l::before {
      right: 1.25rem
  }
}

@media all and (min-width: 992px) {

.UserMenu_list__KXE6l {
    right: auto;
    left: 0.5rem;
    width: calc(100% - 1rem)
}

.UserMenu_list__KXE6l::before {
      right: auto;
      left: 1.25rem
  }
}

.NotificationItem_wrapper__31xBo {
  position: relative;
  display: flex;
}

.NotificationItem_wrapper-unread__194Jj::before {
    position: absolute;
    top: -0.25rem;
    left: -0.5rem;
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--blue);
  }

[dir="ltr"] .NotificationItem_avatar__23nZJ {
          margin-right: 0.75rem;
}

[dir="rtl"] .NotificationItem_avatar__23nZJ {
          margin-left: 0.75rem;
}

.NotificationItem_avatar__23nZJ {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  height: 3rem;
  -webkit-margin-end: 0.75rem;
  border-radius: 50%;
  border: 1px solid #baebff;
  background-color: #f1fbff
}

.NotificationItem_char__FkbPu {
  font-size: 1.3125rem;
  color: var(--blue);
  font-weight: 700
}

.NotificationItem_title__2zVVi {
  -webkit-margin-after: 0.375rem;
          margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 133%
}

.NotificationItem_date__3T3v5 {
  font-size: 0.875rem;
  line-height: 114%;
  color: #6e8189;
}

.NotificationItem_name__u9dEK {
  font-weight: 700;
}

.NotificationItem_link__kSxsG {
  font-weight: 700;
  color: var(--blue);
  text-decoration: underline
}

.NotificationItem_link__kSxsG:hover {
    text-decoration: none;
  }

@media all and (min-width: 768px) {

[dir="ltr"] .NotificationItem_avatar__23nZJ {
            margin-right: 1rem;
  }

[dir="rtl"] .NotificationItem_avatar__23nZJ {
            margin-left: 1rem;
  }

.NotificationItem_avatar__23nZJ {
    -webkit-margin-end: 1rem
}

.NotificationItem_char__FkbPu {
    font-size: 1.5rem
}

.NotificationItem_title__2zVVi {
    font-size: 1rem;
    line-height: 150%
}
}

.NotificationsMenu_container__3Y4Go {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem
}

.NotificationsMenu_container__3Y4Go:hover {
    background-color: #404a50;
    cursor: pointer
  }

.NotificationsMenu_container__3Y4Go:hover .NotificationsMenu_counter__1Z8DF {
      border-color: #404a50;
    }

.NotificationsMenu_container-active__39QkC {
  background-color: #404a50;
}

.NotificationsMenu_wrapper__3xzqs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
}

[dir="ltr"] .NotificationsMenu_icon__2Jt4S {
          margin-left: 0.0625rem
}

[dir="rtl"] .NotificationsMenu_icon__2Jt4S {
          margin-right: 0.0625rem
}

.NotificationsMenu_icon__2Jt4S {
  -webkit-margin-before: -0.25rem;
          margin-top: -0.25rem;
  -webkit-margin-start: 0.0625rem;
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--white) !important;
}

.NotificationsMenu_menu__2-egN {
  position: absolute;
  top: 3.875rem;
  right: -3rem;
  width: 19rem;
  -webkit-margin-before: 0.125rem;
          margin-top: 0.125rem;
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 1.875rem 4.375rem rgba(5, 26, 35, 0.06),
    0 1.25rem 2.5rem rgba(0, 23, 33, 0.06);
  z-index: 3
}

.NotificationsMenu_menu__2-egN::before {
    content: '';
    position: absolute;
    top: -0.3125rem;
    right: 4.375rem;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white)
  }

.NotificationsMenu_counter__1Z8DF {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.375rem;
  min-height: 1.375rem;
  border: 2px solid #24292c;
  color: var(--white);
  font-weight: 700;
  font-size: 0.5625rem;
  background-color: var(--red);
  border-radius: 50%
}

.NotificationsMenu_counter-active__bkRzf {
  border-color: #404a50;
}

.NotificationsMenu_link__2lQsV {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.875rem;
  background-color: #f3f7f9;
  border-top: 1px solid #d2e1e7;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: #538093;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.NotificationsMenu_menu-content__1sBm2 {
  padding: 1rem 1rem 1.875rem
}

.NotificationsMenu_menu-title__DtWXI {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem;
  color: var(--black);
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 134%
}

.NotificationsMenu_list-item__2hIH9:not(:last-of-type) {
    -webkit-margin-after: 1.5rem;
            margin-bottom: 1.5rem
  }

.NotificationsMenu_content__1DywG {
  color: var(--black);
}

@media all and (min-width: 768px) {

.NotificationsMenu_container__3Y4Go {
    padding: 0.75rem 1rem
}

.NotificationsMenu_wrapper-active__3kgNM {
    background-color: #24292c
}

.NotificationsMenu_menu__2-egN {
    top: 4.375rem;
    width: 25rem
}

.NotificationsMenu_menu__2-egN::before {
      right: 4.875rem
  }

.NotificationsMenu_counter__1Z8DF {
    min-width: 1.5rem;
    min-height: 1.5rem;
    border: 0;
    font-size: 0.75rem
}

.NotificationsMenu_menu-content__1sBm2 {
    padding: 1.5rem 1.5rem 2.5rem
}

.NotificationsMenu_menu-title__DtWXI {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.NotificationsMenu_list-item__2hIH9:not(:last-of-type) {
      -webkit-margin-after: 2rem;
              margin-bottom: 2rem
  }
}

@media all and (min-width: 992px) {

.NotificationsMenu_menu__2-egN {
    left: -10.5625rem
}

.NotificationsMenu_menu__2-egN::before {
      right: auto;
      left: 12.5rem
  }
}

.Header_header__2PjI0 {
  display: flex;
  align-items: center;
  height: 3.5rem;
  background-color: #24292c;
  color: var(--white)
}

.Header_wrapper__3c_Fs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header_left-block__1HXnr {
  display: flex;
  align-items: center;
}

.Header_right-block__1x33Y {
  display: flex;
  align-items: center;
}

[dir="ltr"] .Header_icon-open__3wgU2 {
          margin-left: 1.125rem
}

[dir="rtl"] .Header_icon-open__3wgU2 {
          margin-right: 1.125rem
}

.Header_icon-open__3wgU2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  -webkit-margin-start: 1.125rem
}

.Header_icon-open__3wgU2 svg {
    height: 1rem;
    width: 0.875rem;
    stroke: var(--white);
  }

[dir="ltr"] .Header_actions-wrapper__1Nvv5 {
          margin-left: 1rem
}

[dir="rtl"] .Header_actions-wrapper__1Nvv5 {
          margin-right: 1rem
}

.Header_actions-wrapper__1Nvv5 {
  display: flex;
  align-items: center;
  -webkit-margin-start: 1rem;
}

@media all and (min-width: 768px) {

.Header_header__2PjI0 {
    height: 4rem
}
}

.Sidebar_overlay__34k5J {
  position: fixed;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 41, 44, 0.2);
  z-index: 3
}

.Sidebar_overlay__34k5J:hover {
    cursor: pointer;
  }

.Sidebar_overlay-open__3e3UT {
  display: block;
}

.Sidebar_sidebar__2E4li {
  width: 100%;
  height: 100%;
  max-width: 20rem;
  padding: 1.5rem 2rem;
  background-color: #24292c;
  overflow-y: auto;
  z-index: 3;
}

[dir="ltr"] .Sidebar_button-close__X-Dmz {
          margin-right: -0.5rem
}

[dir="rtl"] .Sidebar_button-close__X-Dmz {
          margin-left: -0.5rem
}

.Sidebar_button-close__X-Dmz {
  -webkit-margin-end: -0.5rem;
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.Sidebar_button-close__X-Dmz svg {
    stroke: var(--white);
    width: 0.875rem;
    height: 0.875rem;
  }

.Sidebar_navigation__3R_h_ {
  flex-direction: column
}

[dir="ltr"] .Sidebar_navigation__3R_h_ li:not(:last-of-type) {
              margin-right: 0
}

[dir="rtl"] .Sidebar_navigation__3R_h_ li:not(:last-of-type) {
              margin-left: 0
}

.Sidebar_navigation__3R_h_ li:not(:last-of-type) {
      -webkit-margin-end: 0;
      -webkit-margin-after: 1.5rem;
              margin-bottom: 1.5rem;
    }

[dir="ltr"] .Toast_container__AixGK {
          margin-left: 0.5rem
}

[dir="rtl"] .Toast_container__AixGK {
          margin-right: 0.5rem
}

.Toast_container__AixGK {
  right: 0.5rem;
  bottom: 1rem;
  left: auto;
  left: initial;
  width: auto;
  width: initial;
  -webkit-margin-start: 0.5rem
}

.Toast_toast__2Hubc {
  height: 3.5rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  padding: 0;
  border: 1px solid var(--dark-blue);
  border-radius: 5px;
  box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.9375rem 1.875rem rgba(5, 26, 35, 0.07),
    0 0.4375rem 0.9375rem rgba(0, 23, 33, 0.07)
}

.Toast_body__KKkUg {
  display: flex;
  align-items: center;
  margin: 0;
}

.Toast_info__R8_df {
  display: inherit;
  align-items: inherit;
  justify-content: center;
  min-width: 2.5rem;
  width: 2.5rem;
  height: 100%;
  background-color: var(--dark-blue);
}

.Toast_icon__RXyPq {
  width: 1.125rem;
  height: 1.125rem;
}

[dir="ltr"] .Toast_text__2o20Q {
          padding-left: 0.75rem
}

[dir="rtl"] .Toast_text__2o20Q {
          padding-right: 0.75rem
}

[dir="ltr"] .Toast_text__2o20Q {
          padding-right: 0.5rem
}

[dir="rtl"] .Toast_text__2o20Q {
          padding-left: 0.5rem
}

.Toast_text__2o20Q {
  -webkit-padding-start: 0.75rem;
  -webkit-padding-end: 0.5rem;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black)
}

[dir="ltr"] .Toast_btn__6_gxx {
          margin-right: 0.5rem
}

[dir="rtl"] .Toast_btn__6_gxx {
          margin-left: 0.5rem
}

.Toast_btn__6_gxx {
  -webkit-margin-end: 0.5rem;
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem
}

@media all and (min-width: 480px) {

.Toast_container__AixGK {
    right: 1.5rem;
    bottom: 0.5rem;
    padding: 0
}
}

@media all and (min-width: 768px) {

.Toast_toast__2Hubc {
    height: 4.5rem
}

[dir="ltr"] .Toast_text__2o20Q {
            padding-left: 1.5rem
          }

[dir="rtl"] .Toast_text__2o20Q {
            padding-right: 1.5rem
          }

[dir="ltr"] .Toast_text__2o20Q {
            padding-right: 1.25rem
          }

[dir="rtl"] .Toast_text__2o20Q {
            padding-left: 1.25rem
          }

.Toast_text__2o20Q {
    -webkit-padding-start: 1.5rem;
    -webkit-padding-end: 1.25rem;
    font-size: 1rem;
    line-height: 150%
}

[dir="ltr"] .Toast_btn__6_gxx {
            margin-right: 0.75rem
          }

[dir="rtl"] .Toast_btn__6_gxx {
            margin-left: 0.75rem
          }

.Toast_btn__6_gxx {
    -webkit-margin-end: 0.75rem;
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem
}
}

.Layout_wrapper__3jqo3 {
  min-height: 100vh;
  background-color: #f4f7f9;
}

.PreviewTable_wrapper__3Mwk0 {
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem;
  background-color: var(--white);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02);
  border-radius: 10px;
}

.PreviewTable_header__1oBLF {
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem
}

.PreviewTable_title__jrA6C a {
    color: var(--black);
    font-size: 1.3125rem;
    line-height: 116%;
    font-weight: 700;
  }

.PreviewTable_link__2nngQ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  background-color: var(--blue);
  border-radius: 5px
}

.PreviewTable_link__2nngQ svg {
    width: 0.875rem;
    height: 0.875rem;
    fill: var(--white) !important;
  }

.PreviewTable_content__qBuR4 .ScrollbarsCustom-Track {
    height: 0.25rem !important;
    background-color: transparent !important;
    bottom: -0.8125rem !important;
  }

.PreviewTable_content__qBuR4 .ScrollbarsCustom-Thumb {
    background-color: #c1d1d8 !important;
  }

.PreviewTable_content__qBuR4 .ScrollbarsCustom-Wrapper {
    bottom: 0 !important;
  }

/* .content-hidden {
  display: none;
} */

.PreviewTable_row-content__2MzBU:hover {
    background-color: #f3f7f9;
    cursor: pointer
  }

.PreviewTable_row-content__2MzBU:hover td {
      border-bottom-color: #d2e1e7;
    }

.PreviewTable_row-content__2MzBU:hover:not(:first-child) td {
        box-shadow: 0 -0.0625rem #d2e1e7;
      }

.PreviewTable_cell__f7VXs {
  min-height: 2rem;
  padding: 0.375rem 2rem;
  border-top: 1px solid #f0f5f8;
  border-bottom: 1px solid #f0f5f8;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black)
  /* min-inline-size: 200px; */
}

.PreviewTable_cell__f7VXs:last-child {
    min-width: 11.5625rem;
  }

.PreviewTable_cell-heading__3fuBR {
  background-color: #f9fbfc;
  border-top-color: #dce8ed;
  border-bottom-color: #dce8ed;
  font-weight: 500;
  text-align: left;
  white-space: nowrap !important
}

.PreviewTable_cell-heading__3fuBR:nth-child(2) {
    white-space: nowrap !important;
  }

.PreviewTable_placeholder__2W2Ko {
  -webkit-margin-before: -0.3125rem;
          margin-top: -0.3125rem;
  padding: 0 1.5rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

/* .placeholder-hidden {
  display: none;
} */

@media all and (min-width: 768px) {

.PreviewTable_header__1oBLF {
    height: 4.5rem
}

.PreviewTable_title__jrA6C {
    font-size: 1.5rem;
    line-height: 99%
}

.PreviewTable_placeholder__2W2Ko {
    -webkit-margin-before: 0;
            margin-top: 0
}
}

.Main_container__2C7ys {
  -webkit-padding-before: 1rem;
          padding-top: 1rem;
  -webkit-padding-after: 1rem;
          padding-bottom: 1rem;
}

.Main_title__nl_Xu {
}

.Main_button-reload__1uMKV {
  display: flex;
  align-items: center;
  height: 2rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  padding: 0 0.625rem;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background-color: var(--white)
}

[dir="ltr"] .Main_button-reload__1uMKV svg {
            margin-right: 0.4375rem;
}

[dir="rtl"] .Main_button-reload__1uMKV svg {
            margin-left: 0.4375rem;
}

.Main_button-reload__1uMKV svg {
    width: 1rem;
    height: 0.75rem;
    -webkit-margin-end: 0.4375rem
  }

.Main_button-reload__1uMKV p {
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
    color: var(--black)
  }

.Main_grid__nz-Ph {
  display: grid;
  grid-gap: 1rem 0
}

.Main_cell-goats__1yDz4 {
  min-width: 0;
  min-width: initial
}

.Main_cell-goats__1yDz4:first-child {
    min-width: 18.75rem;
  }

.Main_cell-goats__1yDz4:nth-child(2),
  .Main_cell-goats__1yDz4:nth-child(4) {
    text-align: right;
  }

.Main_cell-emr__1nrp8:nth-child(2) {
    min-width: 18.75rem;
  }

.Main_cell-emr__1nrp8:first-child,
  .Main_cell-emr__1nrp8:nth-child(3) {
    text-align: right;
  }

.Main_cell-logs__I3Fud:first-child {
    text-align: right;
  }

.Main_cell-logs__I3Fud:nth-child(2) {
    min-width: 18.75rem;
  }

.Main_cell-researches__2-yY5:first-child {
    text-align: right;
  }

.Main_cell-researches__2-yY5:nth-child(2),
  .Main_cell-researches__2-yY5:nth-child(3) {
    min-width: 18.75rem;
  }

@media all and (min-width: 768px) {

.Main_button-reload__1uMKV {
    height: 2.5rem;
    padding: 0 0.8125rem
}

.Main_button-reload__1uMKV svg {
      width: 1rem;
      height: 1.25rem
  }

.Main_button-reload__1uMKV p {
      font-size: 1rem;
      line-height: 150%
  }
}

@media all and (min-width: 992px) {

.Main_grid__nz-Ph {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 1rem
}
}

.SearchTable_table-wrapper__35fH9 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

}
& .ScrollbarsCustom-Track {
  height: 0.25rem !important;
  background-color: transparent !important;
  bottom: -0.8125rem !important;
}

& .ScrollbarsCustom-Thumb {
  background-color: #c1d1d8 !important;
}

& .ScrollbarsCustom-Wrapper {
  bottom: 0 !important;
}

& .ScrollbarsCustom-Content {
  padding: 0 !important;
}


.SearchTable_table__3jWq9 {
  width: 100%;
  overflow: hidden;
  overflow: visible;
}

.FilterField_wrapper__3Mapn {
  position: relative;
  height: 2rem;
}

.FilterField_field__2POH8 {
  /* composes: truncate from '../../../../assets/styles/helpers.css'; */
  height: 100%;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d2e1e7;
  padding: 0 0.625rem 0 1.875rem;
  font-size: 0.875rem;
  color: var(--black);
  background-color: var(--white);
}

.FilterField_icon-wrapper__1HE7V {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 100%;
}

.SearchTableHeadCell_cell__3DyL4 {
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  text-align: left;
  justify-content: space-between !important
}

.SearchTableHeadCell_cell__3DyL4 button {
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);
  }

.SearchTableHeadCell_field__2DY7_ {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.625rem 1.25rem rgba(0, 23, 33, 0.04),
    0 0.375rem 0.75rem rgba(0, 23, 33, 0.04), 0 0.1875rem 0.375rem rgba(0, 23, 33, 0.04);
}

.SearchTableHeadCell_icon__3XWR6 {
  width: 0.625rem;
  height: 0.6875rem;
}

.react-datepicker {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 1.25rem 3.125rem rgba(5, 26, 35, 0.07),
    0 0.9375rem 1.5625rem rgba(0, 23, 33, 0.07);
  border-radius: 6px;
  border: 0;
  padding: 0 1rem;
  z-index: 10;
  color: inherit;
}

.react-datepicker__header {
  padding: 0.5rem 0;
  background: var(--white);
  border-bottom: 1px solid #dce8ed;
}

.react-datepicker__month {
  margin: 0.625rem 0;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__navigation {
  display: none;
  width: auto;
  width: initial;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  z-index: 2
}

.react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0;
  }

.react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 0;
  }

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year {
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--black)
}

.react-datepicker__month-read-view--selected-month::first-letter, .react-datepicker__year-read-view--selected-year::first-letter {
    text-transform: uppercase;
  }

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  visibility: visible !important;

  /* &::-webkit-scrollbar {
    inline-size: 1rem;
  } */
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  top: 0.5rem;
  width: auto;
  padding: 0.375rem 0;
  background-color: var(--white);
  box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.375rem 0.75rem rgba(0, 23, 33, 0.07),
    0 0.1875rem 0.375rem rgba(0, 23, 33, 0.07);
  border-radius: 6px;
  border: 0;
}

.react-datepicker__month-dropdown {
  left: 0;
}

.react-datepicker__year-dropdown {
  right: 0;
  left: auto;
}

[dir="ltr"] .react-datepicker__month-option {
  text-align: left;
}

[dir="rtl"] .react-datepicker__month-option {
  text-align: right;
}

.react-datepicker__month-option {
  padding: 0.3125rem 0.75rem;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black)
}

.react-datepicker__month-option:hover {
    border-radius: 0;
    background: #f0f5f8;
  }

.react-datepicker__month-option::first-letter {
    text-transform: uppercase;
  }

.react-datepicker__year-option {
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.75rem;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black)
}

.react-datepicker__year-option:hover {
    border-radius: 0;
    background: #f0f5f8;
  }

.react-datepicker__navigation--years-upcoming {
  display: block;
  border-bottom: 4px solid #242b37 !important;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #242b37 !important;
}

.react-datepicker__navigation--years-previous {
  display: block;
  border-top: 4px solid #242b37 !important;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #242b37 !important;
}

.react-datepicker__month-option--selected,
.react-datepicker__year-option--selected {
  display: none;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option.--selected_month {
  border-radius: 0 !important;
  background: var(--blue);
  color: var(--white)
}

.react-datepicker__year-option--selected_year:hover, .react-datepicker__month-option.--selected_month:hover {
    background: var(--blue);
    color: var(--white);
  }

[dir="ltr"] .react-datepicker__month-read-view--down-arrow,[dir="ltr"] 
.react-datepicker__year-read-view--down-arrow {
          margin-left: 0.375rem;
}

[dir="rtl"] .react-datepicker__month-read-view--down-arrow,[dir="rtl"] 
.react-datepicker__year-read-view--down-arrow {
          margin-right: 0.375rem;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  animation: none 0s ease 0s 1 normal none running;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  border: medium none currentColor;
  border-collapse: separate;
  border-image: none;
  border-radius: 0;
  border-spacing: 0;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: #000;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  grid-column-gap: normal;
  grid-column-gap: normal;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  height: auto;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: disc outside none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 2;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  right: auto;
  -moz-tab-size: 8;
       tab-size: 8;
  table-layout: auto;
  text-align: left;
  text-align-last: auto;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  animation: none 0s ease 0s 1 normal none running;
  backface-visibility: visible;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  border: medium none currentColor;
  border-collapse: separate;
  border-image: none;
  border-radius: 0;
  border-spacing: 0;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: #000;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  grid-column-gap: normal;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: disc outside none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 2;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: left;
  text-align-last: auto;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  all: initial;
  display: flex;
  flex-direction: column;
  height: 0.5rem;
  -webkit-margin-start: 0.375rem
}

.react-datepicker__month-read-view--down-arrow::before, .react-datepicker__year-read-view--down-arrow::before {
    animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    columns: auto;
    column-count: auto;
    column-fill: balance;
    grid-column-gap: normal;
    grid-column-gap: normal;
    column-gap: normal;
    column-rule: medium none currentColor;
    column-span: 1;
    column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    right: auto;
    -moz-tab-size: 8;
         tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    transform: none;
    transform-origin: 50% 50% 0;
    transform-style: flat;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    animation: none 0s ease 0s 1 normal none running;
    backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    columns: auto;
    column-count: auto;
    column-fill: balance;
    grid-column-gap: normal;
    column-gap: normal;
    column-rule: medium none currentColor;
    column-span: 1;
    column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    right: auto;
    tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    transform: none;
    transform-origin: 50% 50% 0;
    transform-style: flat;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    all: initial;
    content: '';
    display: block;
    -webkit-margin-after: 0.125rem;
            margin-bottom: 0.125rem;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #95b4c1;
    border-top: 0;
  }

.react-datepicker__month-read-view--down-arrow::after, .react-datepicker__year-read-view--down-arrow::after {
    content: '';
    display: block;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #95b4c1;
  }

.react-datepicker__day-names {
  display: none;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin: 0.0625rem;
  font-size: 0.875rem;
  border-radius: 4px;
  color: var(--black)
}

.react-datepicker__day:hover {
    background: #f3f7f9;
    border: 1px solid #d2e1e7;
    border-radius: 4px;
  }

.react-datepicker__day--selected {
  font-weight: 400;
  color: var(--white) !important;
  background: var(--blue)
}

.react-datepicker__day--selected:hover {
    border: 0;
    border-radius: 4px;
    background: var(--blue);
  }

.react-datepicker__day--weekend {
  color: var(--blue);
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__current-date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2rem);
  height: 2.5rem;
  margin: 0 -1rem;
  border-top: 1px solid #dce8ed;
  font-size: 0.875rem;
  color: var(--blue);
}

.react-datepicker__day--disabled {
  color: #95b4c1;
}

.react-datepicker__day--today {
  font-weight: 400;
}

.react-datepicker__portal .react-datepicker__day {
    width: 2rem
  }

@media all and (min-width: 768px) {

.react-datepicker__portal .react-datepicker__day {
      width: 2.875rem;
      height: 2.875rem
  }

.react-datepicker__portal .react-datepicker__current-date {
      height: 3.5rem
  }

.react-datepicker__portal .react-datepicker__header {
      padding: 1rem 0
  }
}

.FieldDatePicker_wrapper__2qqor {
  position: relative;
  height: 2rem
}

.FieldDatePicker_wrapper__2qqor .react-datepicker-wrapper {
    height: 100%;
  }

.FieldDatePicker_wrapper__2qqor .react-datepicker__input-container {
    height: 100%;
  }

.FieldDatePicker_wrapper__2qqor {

  /* stylelint-disable-next-line */
}

.FieldDatePicker_wrapper__2qqor input {
    /* composes: truncate from '../../../../assets/styles/helpers.css'; */
    height: 100%;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #d2e1e7;
    padding: 0 0.625rem 0 1.875rem;
    font-size: 0.875rem;
    color: var(--black);
    background-color: var(--white);
  }

.FieldDatePicker_icon-wrapper__1N9B4 {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 100%;
  z-index: 1
}

.FieldDatePicker_icon-wrapper__1N9B4 + div {
    height: 100%;
  }

.DatepickerTableHeadCell_cell__3lH-u {
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  text-align: left;
  justify-content: space-between !important;
}

.DatepickerTableHeadCell_field__2TFot {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.625rem 1.25rem rgba(0, 23, 33, 0.04),
    0 0.375rem 0.75rem rgba(0, 23, 33, 0.04), 0 0.1875rem 0.375rem rgba(0, 23, 33, 0.04);
}

.DatepickerTableHeadCell_icon__1Phav {
  width: 0.625rem;
  height: 0.6875rem;
}

.SearchTableBody_body__2T4MB {
  background-color: var(--white);
}

.SearchTableBody_cell__PWC0j {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #f0f5f8;
  font-size: 1.125rem;
  line-height: 134%;
  color: var(--black)
}

@media all and (min-width: 768px) {

.SearchTableBody_cell__PWC0j {
    padding: 1.5rem 2rem
}
}

.SearchTableBodyCell_cell__KnPev {
  font-size: 0.875rem;
  line-height: 114%;
  color: inherit;
  padding: 0.375rem 0;
}

.LambSearchTableAnother_wrapper__2BRe7 {
  margin-right: 1rem;
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  width: calc(100% + 0.125rem);
  border: 2px solid black;
  overflow: visible
}

.LambSearchTableAnother_preHead__2Vmhw {
  padding: 0 1.5rem;
  height: 3.75rem;
  display: grid;
  grid-template: auto / 5.9375rem 5.3125rem 5rem 6.5625rem 8rem 6.25rem 6.25rem 6.25rem 5.125rem 6.875rem 4.6875rem 4.6875rem 4.0625rem 4.375rem 4.375rem 4.6875rem minmax(
    7.5rem,
    1fr
  );
  font-weight: 700;
  background: rgb(145, 255, 0);
  border-bottom: 2px solid black;
}

.LambSearchTableAnother_preHead__2Vmhw th {
  padding: 0 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
}

.LambSearchTableAnother_year__2YI8n {  
  grid-column: 1 / span 6;
}

.LambSearchTableAnother_today__24ghC {
  grid-column: 7 / span 4;
  background: white;
}

.LambSearchTableAnother_gland__22H6I {
  grid-column: 11 / span 2;
}

.LambSearchTableAnother_child__23BFz {
  grid-column: 13 / span 4;
}

.LambSearchTableAnother_datepicker__2oIlG {
  width: 100%;
  border: none;
  background-color: inherit;
  color: inherit;
}

.LambSearchTableAnother_table-row__3014h {
  grid-template: auto / 5.9375rem 5.3125rem 5rem 6.5625rem 8rem 6.25rem 6.25rem 6.25rem 5.125rem 6.875rem 4.6875rem 4.6875rem 4.0625rem 4.375rem 4.375rem 4.6875rem minmax(
      7.5rem,
      1fr
    );
  animation: LambSearchTableAnother_fadeIn__QX_v5 0.3s linear;
}

@keyframes LambSearchTableAnother_fadeIn__QX_v5 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.LambSearchTableAnother_table-body-row__3_8an {
  display: grid;
  /*grid-gap: 0px 24px;*/
  min-height: 2rem;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid black;
  transition: box-shadow 0.2s linear
}

.LambSearchTableAnother_table-body-row__3_8an:hover {
    /* box-shadow: 1px -1px 25px 2px rgba(0, 0, 0, 0.75); */
    cursor: pointer;
  }

.LambSearchTableAnother_table-cell__2I2cx {
  position: relative;
  padding: 0 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  width: 100%;
  color: inherit
}

.LambSearchTableAnother_table-cell__2I2cx:not(:last-child) {
    border-right: 1px solid black;
  }

.LambSearchTableAnother_table-cell__2I2cx:first-child {
    justify-content: center;
  }

.LambSearchTableAnother_table-cell__2I2cx {

  /*&:nth-child(2) {*/
  /*  text-align: right;*/
  /*}*/

  /*&:nth-child(5) {*/
  /*  text-align: right;*/
  /*}*/
}

.LambSearchTableAnother_table-cell__2I2cx:last-child {
    align-items: center;
    justify-content: center;
  }

.LambSearchTableAnother_footer__13r7j {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  /* @media (--md) { */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* } */
}

[dir="ltr"] .LambSearchTableAnother_arrow__20zAO {
          margin-left: 0.5625rem
}

[dir="rtl"] .LambSearchTableAnother_arrow__20zAO {
          margin-right: 0.5625rem
}

.LambSearchTableAnother_arrow__20zAO {
  -webkit-margin-start: 0.5625rem;
  width: 0.5625rem;
  height: 0.9375rem;
}

.LambSearchTableAnother_arrow-ordered__2tKYS {
  transform: rotate(180deg);
}

.LambSearchTableAnother_button__1n8Zd {
  padding: 0.3125rem 0.3125rem;
  border-radius: 5px;
  font-weight: 500;
  background-color: var(--blue);
  color: var(--white);
}

.LambSearchTableAnother_button-add__2t51l {
  width: 1.875rem;
  height: 1.875rem;
  background-color: var(--blue);
  text-align: center
}

.LambSearchTableAnother_button-add__2t51lsvg {
    width: 0.875rem;
    height: 0.875rem;
  }

@media all and (min-width: 768px) {

.LambSearchTableAnother_wrapper__2BRe7 {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}

.LambSearchTableAnother_table-body-row__3_8an {
}
}

@media all and (min-width: 992px) {

.LambSearchTableAnother_wrapper__2BRe7 {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

.ColorPicker_picker__Zu1Dn {
  position: absolute !important;
  top: 0.8125rem;
  left: 0;
  z-index: 100;
  background: #d4d2d2 !important;
  animation: ColorPicker_fadeIn__1pJSk .3s linear
}

.ColorPicker_picker__Zu1Dn div {
    border-color: transparent transparent #24292c !important;
   }

@keyframes ColorPicker_fadeIn__1pJSk {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.NoteAnother_note__fpWXu {
  width: 100%;
  resize: none;
  border: none;
  background-color: inherit;
  font-family: inherit;
  color: inherit;
}



.LambTableHeadAnother_header__2W7mm {
  display: block;
  border-bottom: 2px solid black;
  /* overflow: hidden; */
}

.LambTableHeadAnother_row__2gl-S {
  display: grid;
  /*grid-gap: 0px 24px;*/
  height: 5.4375rem;
  align-items: stretch;
  padding: 0 1.5rem;
  background-color: #cadce2
}

@media all and (min-width: 768px) {

.LambTableHeadAnother_row__2gl-S {
}
}

.CustomPagination_list__3gN87 {
  display: flex;
  flex-wrap: wrap
}

.CustomPagination_list__3gN87 a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem
  }

.CustomPagination_list__3gN87 a:focus {
      outline: 0;
    }

.CustomPagination_list__3gN87 {

  /* stylelint-disable-next-line */
}

.CustomPagination_list__3gN87 > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    -webkit-margin-after: 0.0625rem;
            margin-bottom: 0.0625rem;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
    color: var(--black)
  }

.CustomPagination_list__3gN87 > li:hover {
      border: 1px solid #d2e1e7;
      cursor: pointer;
    }

[dir="ltr"] .CustomPagination_list__3gN87 > li:not(:last-of-type) {
              margin-right: 0.1875rem
}

[dir="rtl"] .CustomPagination_list__3gN87 > li:not(:last-of-type) {
              margin-left: 0.1875rem
}

.CustomPagination_list__3gN87 > li:not(:last-of-type) {
      -webkit-margin-end: 0.1875rem;
    }

.CustomPagination_list__3gN87 > li.disabled svg {
        fill: #c4cdd0 !important;
      }

.CustomPagination_list__3gN87 > li {

    /* stylelint-disable-next-line */
  }

.CustomPagination_list__3gN87 > li.selected {
      color: var(--white);
      background-color: var(--blue)
    }

.CustomPagination_list__3gN87 > li.selected:hover {
        border: 0;
      }

.CustomPagination_arrow__3XxYc {
  width: 0.625rem;
  height: 0.8125rem;
  fill: var(--blue) !important;
}

.CustomPagination_arrow-left__2SA6M {
  transform: rotate(180deg);
}

.CustomPagination_dots__3SXcW {
  display: flex;
}

.CustomPagination_dot__2krIq {
  width: 0.25rem;
  height: 0.25rem;
  background-color: var(--blue);
  border-radius: 50%
}

[dir="ltr"] .CustomPagination_dot__2krIq:not(:last-child) {
            margin-right: 0.125rem
}

[dir="rtl"] .CustomPagination_dot__2krIq:not(:last-child) {
            margin-left: 0.125rem
}

.CustomPagination_dot__2krIq:not(:last-child) {
    -webkit-margin-end: 0.125rem;
  }

[dir="ltr"] .PageSizeSelect_wrapper__30E7C {
          margin-left: auto
}

[dir="rtl"] .PageSizeSelect_wrapper__30E7C {
          margin-right: auto
}

.PageSizeSelect_wrapper__30E7C {
  width: -webkit-max-content;
  width: max-content;
  -webkit-margin-before: 0.625rem;
          margin-top: 0.625rem;
  -webkit-margin-start: auto;
  display: flex;
  align-items: center
}

[dir="ltr"] .PageSizeSelect_text__1Duz9 {
          margin-right: 0.625rem
}

[dir="rtl"] .PageSizeSelect_text__1Duz9 {
          margin-left: 0.625rem
}

.PageSizeSelect_text__1Duz9 {
  -webkit-margin-end: 0.625rem;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black);
}

.PageSizeSelect_select__3xoFs {
  width: 4.125rem;
}

@media all and (min-width: 768px) {

[dir="ltr"] .PageSizeSelect_wrapper__30E7C {
            margin-left: 0;
            margin-left: initial
          }

[dir="rtl"] .PageSizeSelect_wrapper__30E7C {
            margin-right: 0;
            margin-right: initial
          }

.PageSizeSelect_wrapper__30E7C {
    -webkit-margin-before: 0;
            margin-top: 0;
    -webkit-margin-start: initial
}
}

.CustomSelect_icon__1y-de {
  width: 0.625rem;
  height: 0.375rem;
}

.CustomSelect_icon-reverse__2eRqV {
  transform: rotate(180deg);
}

.ActionButton_btn__11Xky {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background-color: var(--white);
  white-space: nowrap
}

.ActionButton_text__3wZQA {
  font-size: 0
}

@media all and (min-width: 768px) {

.ActionButton_btn__11Xky {
    height: 2.5rem;
    width: auto;
    width: initial;
    padding: 0 0.875rem
}

[dir="ltr"] .ActionButton_text__3wZQA {
            margin-left: 0.5625rem
  }

[dir="rtl"] .ActionButton_text__3wZQA {
            margin-right: 0.5625rem
  }

.ActionButton_text__3wZQA {
    -webkit-margin-start: 0.5625rem;
    font-size: 1rem;
    line-height: 150%;
    font-weight: 500;
    color: var(--black)
}
}

.LambHeader_header__1-GM- {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LambHeader_title__pB7SO {
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.LambHeader_buttons__3iuTX {
  display: flex;
}

[dir="ltr"] .LambHeader_button__14hi_:not(:last-child) {
            margin-right: 0.5rem;
}

[dir="rtl"] .LambHeader_button__14hi_:not(:last-child) {
            margin-left: 0.5rem;
}

.LambHeader_button__14hi_:not(:last-child) {
    -webkit-margin-end: 0.5rem
  }

.LambHeader_button-add__3VP_N {
  background-color: var(--blue)
}

.LambHeader_button-add__3VP_N p {
    color: var(--white);
  }

.LambHeader_button-add__3VP_N svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.LambHeader_button-reload__3eGFv svg {
    width: 1rem;
    height: 1.25rem;
  }

@media all and (min-width: 768px) {

.LambHeader_title__pB7SO {
    font-size: 2rem;
    line-height: 2.5rem
}

[dir="ltr"] .LambHeader_button__14hi_:not(:last-child) {
              margin-right: 1rem;
  }

[dir="rtl"] .LambHeader_button__14hi_:not(:last-child) {
              margin-left: 1rem;
  }

.LambHeader_button__14hi_:not(:last-child) {
      -webkit-margin-end: 1rem
  }
}

.Lamb_container__27qfB {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem;
  overflow-x: scroll
}

.Lamb_wrapper__13wtT {
  /*max-inline-size: 1070px;*/
  min-width: 100.0625rem;
}

.Lamb_header__phd7M {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.Lamb_filters-wrapper__1RlTI {
  padding: 1.5rem;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
}

.Lamb_empty-results__u9Lkd {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

@media all and (min-width: 768px) {

.Lamb_container__27qfB {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.Lamb_header__phd7M {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.Lamb_filters-wrapper__1RlTI {
    display: flex
}

.Lamb_empty-results__u9Lkd {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}
}

.PageTitle_wrapper__3X2tz {
  display: flex;
  justify-content: space-between;
  -webkit-margin-before: 1.5rem;
          margin-top: 1.5rem;
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.PageTitle_title__smy8p {
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black)
}

@media all and (min-width: 768px) {

.PageTitle_wrapper__3X2tz {
    -webkit-margin-before: 2rem;
            margin-top: 2rem;
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.PageTitle_title__smy8p {
    font-size: 2rem;
    line-height: 2.5rem
}
}

.BlockWrapper_wrapper__3KdQg {
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02);
}

.ProfileAvatar_wrapper__3H07r {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5rem;
  min-height: 5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #565f64
}

.ProfileAvatar_img__2F-m6 {
  width: 100%;
}

.ProfileAvatar_icon__3ON6b {
  height: 2.5rem
}

@media all and (min-width: 768px) {

.ProfileAvatar_wrapper__3H07r {
    min-width: 6rem;
    min-height: 6rem;
    width: 6rem;
    height: 6rem
}

.ProfileAvatar_icon__3ON6b {
    height: 3.125rem
}
}

@media all and (min-width: 992px) {

.ProfileAvatar_wrapper__3H07r {
    min-width: 11rem;
    min-height: 11rem;
    width: 11rem;
    height: 11rem
}

.ProfileAvatar_icon__3ON6b {
    height: 5.625rem
}
}

.ChangeAvatarButton_input__17Bar {
  display: none;
}

.ChangeAvatarButton_btn__1taz3 {
  height: 3rem;
  border: 1px dashed #a0b5bf;
  border-radius: 4px;
}

.ChangeAvatarButton_label__5wkpI {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  font-size: 1rem;
  color: #54656c;
  font-weight: 700
}

.ChangeAvatarButton_label__5wkpI:hover {
    cursor: pointer;
  }

[dir="ltr"] .ChangeAvatarButton_icon__2H0cA {
          margin-right: 0.9375rem;
}

[dir="rtl"] .ChangeAvatarButton_icon__2H0cA {
          margin-left: 0.9375rem;
}

.ChangeAvatarButton_icon__2H0cA {
  width: 1.3125rem;
  height: 1.1875rem;
  -webkit-margin-before: -0.3125rem;
          margin-top: -0.3125rem;
  -webkit-margin-end: 0.9375rem
}

@media all and (min-width: 768px) {

.ChangeAvatarButton_label__5wkpI {
    padding: 0 1.5rem;
    font-size: 1.125rem
}

[dir="ltr"] .ChangeAvatarButton_icon__2H0cA {
            margin-right: 0.625rem;
  }

[dir="rtl"] .ChangeAvatarButton_icon__2H0cA {
            margin-left: 0.625rem;
  }

.ChangeAvatarButton_icon__2H0cA {
    -webkit-margin-before: -0.1875rem;
            margin-top: -0.1875rem;
    -webkit-margin-end: 0.625rem
}
}

.ProfileAvatarBlock_wrapper__1kgCR {
  padding: 1.25rem
}

.ProfileAvatarBlock_form__7kSnx {
  display: flex;
  align-items: center
}

.ProfileAvatarBlock_title__bM4n6 {
}

[dir="ltr"] .ProfileAvatarBlock_avatar__wu6AW {
          margin-right: 1.75rem
}

[dir="rtl"] .ProfileAvatarBlock_avatar__wu6AW {
          margin-left: 1.75rem
}

.ProfileAvatarBlock_avatar__wu6AW {
  -webkit-margin-end: 1.75rem;
  overflow: hidden
}

.ProfileAvatarBlock_error__f51HK {
  -webkit-margin-before: 1rem;
          margin-top: 1rem;
  font-size: 0.9375rem;
  line-height: 142%;
  color: var(--red)
}

@media all and (min-width: 768px) {

.ProfileAvatarBlock_wrapper__1kgCR {
    display: grid;
    grid-template: auto / repeat(2, auto);
    padding: 2.5rem
}

.ProfileAvatarBlock_form__7kSnx {
    flex-direction: row-reverse
}

[dir="ltr"] .ProfileAvatarBlock_avatar__wu6AW {
            margin-right: 0
  }

[dir="rtl"] .ProfileAvatarBlock_avatar__wu6AW {
            margin-left: 0
  }

[dir="ltr"] .ProfileAvatarBlock_avatar__wu6AW {
            margin-left: 2.5rem
  }

[dir="rtl"] .ProfileAvatarBlock_avatar__wu6AW {
            margin-right: 2.5rem
  }

.ProfileAvatarBlock_avatar__wu6AW {
    -webkit-margin-end: 0;
    -webkit-margin-start: 2.5rem
}

[dir="ltr"] .ProfileAvatarBlock_error__f51HK {
    text-align: right
  }

[dir="rtl"] .ProfileAvatarBlock_error__f51HK {
    text-align: left
  }

.ProfileAvatarBlock_error__f51HK {
    grid-area: auto / span 2;
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

@media all and (min-width: 768px) and (max-width: 991px) {

.ProfileAvatarBlock_title__bM4n6 {
    animation: none 0s ease 0s 1 normal none running !important;
    -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
    border: medium none currentColor !important;
    border-collapse: separate !important;
    border-image: none !important;
    border-radius: 0 !important;
    border-spacing: 0 !important;
    bottom: auto !important;
    box-shadow: none !important;
    box-sizing: content-box !important;
    caption-side: top !important;
    clear: none !important;
    clip: auto !important;
    color: #000 !important;
    columns: auto !important;
    column-count: auto !important;
    column-fill: balance !important;
    grid-column-gap: normal !important;
    grid-column-gap: normal !important;
    column-gap: normal !important;
    column-rule: medium none currentColor !important;
    column-span: 1 !important;
    column-width: auto !important;
    content: normal !important;
    counter-increment: none !important;
    counter-reset: none !important;
    cursor: auto !important;
    direction: ltr !important;
    display: inline !important;
    empty-cells: show !important;
    float: none !important;
    font-family: serif !important;
    font-size: medium !important;
    font-style: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    height: auto !important;
    -webkit-hyphens: none !important;
        -ms-hyphens: none !important;
            hyphens: none !important;
    left: auto !important;
    letter-spacing: normal !important;
    list-style: disc outside none !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    opacity: 1 !important;
    orphans: 2 !important;
    outline: medium none invert !important;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    padding: 0 !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
    page-break-inside: auto !important;
    perspective: none !important;
    perspective-origin: 50% 50% !important;
    position: static !important;
    right: auto !important;
    -moz-tab-size: 8 !important;
         tab-size: 8 !important;
    table-layout: auto !important;
    text-align: left !important;
    text-align-last: auto !important;
    text-decoration: none !important;
    text-indent: 0 !important;
    text-shadow: none !important;
    text-transform: none !important;
    top: auto !important;
    transform: none !important;
    transform-origin: 50% 50% 0 !important;
    transform-style: flat !important;
    transition: none 0s ease 0s !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: visible !important;
    white-space: normal !important;
    widows: 2 !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
    animation: none 0s ease 0s 1 normal none running !important;
    backface-visibility: visible !important;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
    border: medium none currentColor !important;
    border-collapse: separate !important;
    border-image: none !important;
    border-radius: 0 !important;
    border-spacing: 0 !important;
    bottom: auto !important;
    box-shadow: none !important;
    box-sizing: content-box !important;
    caption-side: top !important;
    clear: none !important;
    clip: auto !important;
    color: #000 !important;
    columns: auto !important;
    column-count: auto !important;
    column-fill: balance !important;
    grid-column-gap: normal !important;
    column-gap: normal !important;
    column-rule: medium none currentColor !important;
    column-span: 1 !important;
    column-width: auto !important;
    content: normal !important;
    counter-increment: none !important;
    counter-reset: none !important;
    cursor: auto !important;
    direction: ltr !important;
    display: inline !important;
    empty-cells: show !important;
    float: none !important;
    font-family: serif !important;
    font-size: medium !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    height: auto !important;
    hyphens: none !important;
    left: auto !important;
    letter-spacing: normal !important;
    list-style: disc outside none !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    opacity: 1 !important;
    orphans: 2 !important;
    outline: medium none invert !important;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    padding: 0 !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
    page-break-inside: auto !important;
    perspective: none !important;
    perspective-origin: 50% 50% !important;
    position: static !important;
    right: auto !important;
    tab-size: 8 !important;
    table-layout: auto !important;
    text-align: left !important;
    text-align-last: auto !important;
    text-decoration: none !important;
    text-indent: 0 !important;
    text-shadow: none !important;
    text-transform: none !important;
    top: auto !important;
    transform: none !important;
    transform-origin: 50% 50% 0 !important;
    transform-style: flat !important;
    transition: none 0s ease 0s !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: visible !important;
    white-space: normal !important;
    widows: 2 !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
    all: initial !important;
    align-self: center !important;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 99%;
    color: var(--black)
}
}

@media all and (min-width: 992px) {

.ProfileAvatarBlock_wrapper__1kgCR {
    grid-area: span 2 / auto;
    min-height: 20.5625rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2rem 1.5rem
}

.ProfileAvatarBlock_form__7kSnx {
    grid-area: auto / span 2;
    flex-direction: column
}

[dir="ltr"] .ProfileAvatarBlock_avatar__wu6AW {
            margin-left: 0
  }

[dir="rtl"] .ProfileAvatarBlock_avatar__wu6AW {
            margin-right: 0
  }

.ProfileAvatarBlock_avatar__wu6AW {
    -webkit-margin-start: 0;
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.ProfileAvatarBlock_error__f51HK {
    text-align: center
}
}

.ProfileBlockWrapper_wrapper__3LlKy {
  padding: 1.25rem 1.25rem 0.75rem
}

.ProfileBlockWrapper_title__1j2VB {
  -webkit-margin-after: 1.25rem;
          margin-bottom: 1.25rem;
  font-size: 1.3125rem;
  line-height: 116%;
  font-weight: 700;
  color: var(--black)
}

@media all and (min-width: 768px) {

.ProfileBlockWrapper_wrapper__3LlKy {
    padding: 2.5rem
}

.ProfileBlockWrapper_title__1j2VB {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 99%
}
}

.PhoneField_field__3es_F {
  height: 3rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  transition: border-color ease-in-out 0.2s
}

.PhoneField_field__3es_F:hover {
    border-color: #b0c9d3;
  }

.PhoneField_field__3es_F:focus {
    border-color: #8fb3c1;
  }

.PhoneField_field-error__qH_64 {
  border-color: var(--red) !important;
}

.PhoneField_error__lXJmx {
  font-size: 0.875rem;
  line-height: 145%;
  color: var(--red);
}

.CustomFieldSet_label__dZfJy {
  display: block;
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 200%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #54656c
}

.CustomFieldSet_label__dZfJy:not(:first-of-type) {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
  }

.CustomFieldSet_field__1RyoO {
  grid-area: auto / 2
}

.CustomFieldSet_error__1qSk3 {
  grid-area: auto / 2;
}

@media all and (min-width: 768px) {

.CustomFieldSet_label__dZfJy {
    grid-area: auto / 1;
    align-self: center;
    justify-self: end;
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.CustomFieldSet_label__dZfJy:not(:first-of-type) {
      -webkit-margin-before: 1rem;
              margin-top: 1rem
  }

.CustomFieldSet_field__1RyoO:not(:first-of-type) {
      -webkit-margin-before: 1rem;
              margin-top: 1rem
  }
}


  .ProfileInfoForm_btn__1xqfP {
  -webkit-margin-before: 1.5rem;
          margin-top: 1.5rem;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem;
  font-size: 1rem
}

@media all and (min-width: 768px) {

.ProfileInfoForm_form__z4kHx {
    display: grid;
    grid-template: auto / 11.25rem 1fr;
    grid-column-gap: 2rem
}

[dir="ltr"] .ProfileInfoForm_btn__1xqfP {
            margin-right: 1.1875rem
  }

[dir="rtl"] .ProfileInfoForm_btn__1xqfP {
            margin-left: 1.1875rem
  }

.ProfileInfoForm_btn__1xqfP {
    grid-area: auto / 2;
    max-width: 10rem;
    -webkit-margin-before: 2rem;
            margin-top: 2rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 1.1875rem;
    font-size: 1.125rem
}
}

.ActionModal_modal__C4oI5 {
  max-width: 26rem;
  border-radius: 10px;
}

.ActionModal_content__2aWLQ {
  padding: 1.125rem 1.125rem 2.75rem
}

.ActionModal_header__3cCoX {
  display: grid;
  grid-template: repeat(2, auto) / 1.25rem 1fr 1.25rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem
}

.ActionModal_title__2p5Cr {
  grid-area: 2 / 2;
  text-align: center;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 116%;
  color: var(--black);
}

.ActionModal_text__1eM14 {
  text-align: center;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--black)
}

.ActionModal_btn-close__1Nyti {
  grid-area: 1 / 3;
}

.ActionModal_buttons__1AjZk {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 5rem;
  border-top: 1px solid #d2e1e7;
  background-color: #f3f7f9
}

.ActionModal_btn-accept__1bdMS {
  height: 2.5rem;
  -webkit-margin-before: -1.25rem;
          margin-top: -1.25rem;
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem;
  padding: 0 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  color: var(--white);
  background-color: var(--blue)
}

.ActionModal_btn-accept-danger__3ZjGj {
  background-color: var(--red);
}

.ActionModal_btn-decline__2_dlv {
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  color: var(--black);
  background-color: transparent;
}

@media all and (min-width: 768px) {

.ActionModal_content__2aWLQ {
    -webkit-padding-after: 3.5625rem;
            padding-bottom: 3.5625rem
}

.ActionModal_header__3cCoX {
    font-size: 1.5rem;
    line-height: 99%
}

.ActionModal_text__1eM14 {
    font-size: 1rem;
    line-height: 150%
}

.ActionModal_buttons__1AjZk {
    height: 5.9375rem
}

.ActionModal_btn-accept__1bdMS {
    height: 3rem;
    -webkit-margin-before: -1.5rem;
            margin-top: -1.5rem;
    -webkit-margin-after: 1rem;
            margin-bottom: 1rem;
    padding: 0 1.5rem
}
}

.ProfilePasswordBlock_wrapper__3wCvU {
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.ProfilePasswordBlock_error__dHo10 {
  grid-area: auto / 2;
  -webkit-margin-before: -0.25rem;
          margin-top: -0.25rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 145%;
  color: var(--red)
}

.ProfilePasswordBlock_buttons__1BLkO {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-margin-before: 1.5rem;
          margin-top: 1.5rem
}

.ProfilePasswordBlock_btn__jUTuz {
  font-size: 1rem
}

@media all and (min-width: 768px) {

.ProfilePasswordBlock_wrapper__3wCvU {
    -webkit-padding-after: 2.5rem;
            padding-bottom: 2.5rem
}

.ProfilePasswordBlock_form__Sr9mo {
    display: grid;
    grid-template: auto / 11.25rem 1fr;
    grid-column-gap: 2rem
}

.ProfilePasswordBlock_error__dHo10 {
    -webkit-margin-before: -1rem;
            margin-top: -1rem
}

.ProfilePasswordBlock_buttons__1BLkO {
    grid-area: auto / 2;
    flex-direction: row;
    -webkit-margin-before: 2rem;
            margin-top: 2rem
}

.ProfilePasswordBlock_btn__jUTuz {
    max-width: 10rem;
    font-size: 1.125rem
}
}

@media all and (min-width: 992px) {

.ProfilePasswordBlock_wrapper__3wCvU {
    grid-area: 2 / 2
}
}

.Profile_wrapper__2NyDN {
  max-width: 60rem
}

.Profile_grid__5XaAi {
  display: grid;
  grid-gap: 1rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

@media all and (min-width: 768px) {

.Profile_grid__5XaAi {
    grid-gap: 1.5rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}
}

@media all and (min-width: 992px) {

.Profile_wrapper__2NyDN {
    padding: 0
}

.Profile_grid__5XaAi {
    grid-template: auto / 17rem 1fr
}
}

.CardHeader_header__1je_8 {
  display: flex;
  flex-direction: column;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem
}

.CardHeader_container__31SXx {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem
}

[dir="ltr"] .CardHeader_title__1WS0P {
          margin-right: 0.625rem
}

[dir="rtl"] .CardHeader_title__1WS0P {
          margin-left: 0.625rem
}

.CardHeader_title__1WS0P {
  -webkit-margin-end: 0.625rem;
  font-size: 1.75rem;
  line-height: 116%;
  font-weight: 700;
  color: var(--black)
}

[dir="ltr"] .CardHeader_title__1WS0P + * {
            margin-right: 0.625rem
}

[dir="rtl"] .CardHeader_title__1WS0P + * {
            margin-left: 0.625rem
}

.CardHeader_title__1WS0P + * {
    -webkit-margin-end: 0.625rem;
  }

.CardHeader_info__1tTOc {
  display: flex;
  flex-wrap: wrap;
}

.CardHeader_actions__9XcT2 {
  display: flex;
  align-items: center
}

[dir="ltr"] .CardHeader_actions__9XcT2 > *:first-child {
            margin-right: 0.5rem
}

[dir="rtl"] .CardHeader_actions__9XcT2 > *:first-child {
            margin-left: 0.5rem
}

.CardHeader_actions__9XcT2 > *:first-child {
    -webkit-margin-end: 0.5rem
  }

@media all and (min-width: 768px) {

.CardHeader_header__1je_8 {
    grid-area: 1 / span 2;
    width: calc(100% + 4.5rem);
    margin: 0 -2.25rem 0.5rem
}

.CardHeader_container__31SXx {
    -webkit-margin-after: 0;
            margin-bottom: 0
}

[dir="ltr"] .CardHeader_title__1WS0P {
            margin-right: 1.5rem
  }

[dir="rtl"] .CardHeader_title__1WS0P {
            margin-left: 1.5rem
  }

.CardHeader_title__1WS0P {
    -webkit-margin-end: 1.5rem;
    font-size: 2rem;
    line-height: 2.5rem
}

[dir="ltr"] .CardHeader_actions__9XcT2 > *:first-child {
              margin-right: 1rem
  }

[dir="rtl"] .CardHeader_actions__9XcT2 > *:first-child {
              margin-left: 1rem
  }

.CardHeader_actions__9XcT2 > *:first-child {
      -webkit-margin-end: 1rem
  }
}

@media all and (min-width: 1200px) {

.CardHeader_header__1je_8 {
    width: auto;
    width: initial;
    margin: 0;
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem
}
}

.WeighingBlock_header__28mvv {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #dce8ed
}

.WeighingBlock_title__3s32n {
  font-size: 1.3125rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.WeighingBlock_btn-add__6LbUt {
  height: 2rem;
  margin: 1rem 1.5rem;
  padding: 0 0.75rem;
  border-radius: 3px;
  background-color: var(--blue);
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--white)
}

.WeighingBlock_empty-block__3Z_z4 {
  padding: 1rem 1.5rem 1.5rem
}

.WeighingBlock_full-block__20E9w {
  -webkit-padding-after: 1rem;
          padding-bottom: 1rem;
}

.WeighingBlock_empty-text__CmsZh {
  margin: 0.5rem 0 0;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.WeighingBlock_table__3Fz_D {
  width: 100%;
  margin: -0.0625rem 0 0;
}

.WeighingBlock_hidden-row__2ajlp {
  display: none;
}

.WeighingBlock_visible-row__3jQoY {
  display: table-row;
}

.WeighingBlock_cell__254j4 {
  position: relative;
  width: 50%;
  height: 2.5rem;
  padding: 0;
  border: 1px solid #f0f5f8;
  font-size: 1rem;
  color: var(--black)
}

.WeighingBlock_cell__254j4 .react-datepicker-wrapper {
    height: 100%;
    width: 100%;
  }

.WeighingBlock_cell__254j4 .react-datepicker__input-container {
    height: 100%;
    width: 100%;
  }

[dir="ltr"] .WeighingBlock_head-cell__3t0de {
  text-align: left
}

[dir="rtl"] .WeighingBlock_head-cell__3t0de {
  text-align: right
}

.WeighingBlock_head-cell__3t0de {
  padding: 0 2rem;
  font-weight: 500;
  background-color: #f9fbfc
}

.WeighingBlock_head-cell__3t0de:last-child {
    text-align: right;
  }

.WeighingBlock_field__1tpLZ {
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  border: 0;
  color: var(--black)
}

.WeighingBlock_field__1tpLZ:hover {
    cursor: pointer;
    box-shadow: 0 0 0 0.0625rem #d2e1e7;
    background-color: #f3f7f9;
  }

[dir="ltr"] .WeighingBlock_field__1tpLZ:focus {
            margin-left: -0.0625rem
}

[dir="rtl"] .WeighingBlock_field__1tpLZ:focus {
            margin-right: -0.0625rem
}

.WeighingBlock_field__1tpLZ:focus {
    width: calc(100% + 0.125rem);
    height: calc(100% + 0.125rem);
    -webkit-margin-start: -0.0625rem;
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.625rem 1.25rem rgba(0, 23, 33, 0.07),
      0 0.375rem 0.75rem rgba(0, 23, 33, 0.07), 0 0.1875rem 0.375rem rgba(0, 23, 33, 0.07);
    cursor: auto;
    cursor: initial;
  }

.WeighingBlock_field__1tpLZ:disabled {
    background-color: var(--white);
    cursor: auto;
    cursor: initial
  }

.WeighingBlock_field__1tpLZ:disabled:hover {
      background-color: var(--white);
      box-shadow: none;
    }

.WeighingBlock_error__3sW1E {
  position: absolute;
  top: -2.375rem;
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  height: 2.5rem;
  padding: 0 0.875rem;
  color: var(--white);
  border-radius: 5px;
  background-color: var(--dark-blue);
  z-index: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.WeighingBlock_error__3sW1E::before {
    position: absolute;
    bottom: -0.375rem;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--dark-blue);
  }

.WeighingBlock_field-weight__1--5G {
  text-align: right
}

.WeighingBlock_field-weight__1--5G:focus {
    text-align: left;
    text-align: initial;
  }

.WeighingBlock_error-date__2SsT0 {
  left: 0.875rem
}

.WeighingBlock_error-date__2SsT0::before {
    left: 1.6875rem;
  }

.WeighingBlock_error-weight__2BrLd {
  right: 0.875rem
}

.WeighingBlock_error-weight__2BrLd::before {
    right: 1.6875rem;
  }

.WeighingBlock_show-more-button__3i8yi {
  height: 2.625rem;
  width: calc(100% - 4rem);
  padding: 0 1rem;
  margin: 1rem 2rem 0;
  border: 1px solid var(--blue);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--blue);
  font-weight: 500
}

@media all and (min-width: 768px) {

.WeighingBlock_header__28mvv {
    padding: 2rem
}

.WeighingBlock_title__3s32n {
    font-size: 1.5rem;
    line-height: 99%
}

.WeighingBlock_btn-add__6LbUt {
    margin: 1.5rem 2rem;
    height: 2.5rem;
    font-size: 1rem;
    padding: 0 1rem
}

.WeighingBlock_empty-block__3Z_z4 {
    padding: 2rem
}

.WeighingBlock_empty-text__CmsZh {
    margin: 1rem 0 0
}

.WeighingBlock_show-more-button__3i8yi {
    margin: 1.5rem 2rem 1rem
}
}

.DatesSelect_icon__pHZcr {
  width: 0.625rem;
  height: 0.375rem;
}

.DatesSelect_icon-reverse__1owyb {
  transform: rotate(180deg);
}

.CardMilkAmounts_header__2XwSl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem
}

.CardMilkAmounts_title__358rY {
  font-size: 1.3125rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.CardMilkAmounts_select__JaI2u {
  width: 5rem;
}

[dir="ltr"] .CardMilkAmounts_table__-Y4AB {
          margin-left: -0.0625rem
}

[dir="rtl"] .CardMilkAmounts_table__-Y4AB {
          margin-right: -0.0625rem
}

[dir="ltr"] .CardMilkAmounts_table__-Y4AB {
          margin-right: -0.0625rem
}

[dir="rtl"] .CardMilkAmounts_table__-Y4AB {
          margin-left: -0.0625rem
}

.CardMilkAmounts_table__-Y4AB {
  width: calc(100% + 0.125rem);
  -webkit-margin-start: -0.0625rem;
  -webkit-margin-end: -0.0625rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

.CardMilkAmounts_cell__1MzOy {
  height: 2.5rem;
  border: 1px solid #dce8ed;
  padding: 0 1.5rem;
  font-size: 1rem;
  color: var(--black);
  text-align: left
}

.CardMilkAmounts_cell__1MzOy:last-child {
    text-align: right;
  }

.CardMilkAmounts_head-cell__rzVpO {
  width: 50%;
  font-weight: 500;
  background-color: #f9fbfc;
}

.CardMilkAmounts_editable-cell__2O8F3 {
  border: 1px solid #dce8ed;
}

.CardMilkAmounts_field__1GrEP {
  width: 100%;
  height: 2.5rem;
  padding: 0 1.5rem;
  border: 0;
  text-align: right
}

.CardMilkAmounts_field__1GrEP::-ms-input-placeholder {
    color: #7f8d94;
    opacity: 1;
  }

&::-ms-input-placeholder {
    color: #7f8d94;
    opacity: 1;
  }

.CardMilkAmounts_field__1GrEP::placeholder {
    color: #7f8d94;
    opacity: 1;
  }

.CardMilkAmounts_field-editable__3MIA5:hover {
    cursor: pointer;
    box-shadow: 0 0 0 0.0625rem #d2e1e7;
    background-color: #f3f7f9;
  }

.CardMilkAmounts_field-editable__3MIA5:focus {
    width: calc(100% + 0.125rem);
    height: 2.625rem;
    margin: -0.0625rem;
    border-radius: 3px;
    text-align: left;
    text-align: initial;
    background-color: var(--white);
    box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.625rem 1.25rem rgba(0, 23, 33, 0.07),
      0 0.375rem 0.75rem rgba(0, 23, 33, 0.07), 0 0.1875rem 0.375rem rgba(0, 23, 33, 0.07);
    cursor: auto;
    cursor: initial;
  }

.CardMilkAmounts_field-disabled__Diz3T {
  background-color: var(--white)
}

.CardMilkAmounts_field-disabled__Diz3T:hover {
    cursor: unset;
  }

.CardMilkAmounts_field-with-content__2KICZ {
  color: var(--black) !important;
}

.CardMilkAmounts_text__3jKMR {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2.5rem;
  padding: 0 1.5rem;
  color: #7f8d94
}

.CardMilkAmounts_content-row__dH8yZ {
  display: none;
}

.CardMilkAmounts_visible-row__3VOfz {
  display: table-row;
}

.CardMilkAmounts_btn-container__2_Ocn {
  padding: 1rem;
  border-top: 1px solid #dce8ed
}

.CardMilkAmounts_add-btn__3WWOu {
  height: 2rem;
  padding: 0 0.75rem;
  border-radius: 3px;
  background-color: var(--blue);
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--white)
}

@media all and (min-width: 768px) {

.CardMilkAmounts_header__2XwSl {
    padding: 1.5rem 2rem
}

.CardMilkAmounts_title__358rY {
    font-size: 1.5rem;
    line-height: 99%
}

.CardMilkAmounts_cell__1MzOy {
    padding: 0 2rem
}

.CardMilkAmounts_field__1GrEP {
    padding: 0 2rem
}

.CardMilkAmounts_text__3jKMR {
    padding: 0 2rem
}

.CardMilkAmounts_btn-container__2_Ocn {
    padding: 1.5rem 2.25rem
}

.CardMilkAmounts_add-btn__3WWOu {
    height: 2.5rem;
    font-size: 1rem;
    padding: 0 1rem
}
}

.CardInfoBlock_container__2u053 {
  -webkit-padding-after: 0.5rem;
          padding-bottom: 0.5rem
}

.CardInfoBlock_table__2cTsh {
  width: 100%
}

.CardInfoBlock_table__2cTsh:first-child {
    -webkit-margin-after: 1rem;
            margin-bottom: 1rem
  }

.CardInfoBlock_table-title__A58zz {
  padding: 1rem;
  border-bottom: 1px solid #f0f5f8;
  font-size: 1.3125rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700;
  text-align: left
}

.CardInfoBlock_row__UiDQM {
  display: grid;
  grid-template: auto / auto;
  padding: 0.6875rem 1rem 1.0625rem;
  border-bottom: 1px solid #f0f5f8
}

.CardInfoBlock_cell__5J7Za {
  width: 100%
}

.CardInfoBlock_cell__5J7Za .react-datepicker-wrapper {
    width: inherit
  }

.CardInfoBlock_cell__5J7Za .react-datepicker-wrapper .CardInfoBlock_field__25Mff {
      border-radius: 5px;
    }

[dir="ltr"] .CardInfoBlock_row-title__1zV5q {
  text-align: left
}

[dir="rtl"] .CardInfoBlock_row-title__1zV5q {
  text-align: right
}

.CardInfoBlock_row-title__1zV5q {
  display: block;
  -webkit-margin-after: 0.25rem;
          margin-bottom: 0.25rem;
  font-size: 0.625rem;
  line-height: 200%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--dark-gray)
}

[dir="ltr"] .CardInfoBlock_row-read__4mYCS {
  text-align: left
}

[dir="rtl"] .CardInfoBlock_row-read__4mYCS {
  text-align: right
}

.CardInfoBlock_field__25Mff {
  width: 100%;
  height: 2rem;
  padding: 0 0.5rem;
  border: 1px solid #d2e1e7;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  background: var(--gray);
  transition: border-color ease-in-out 0.2s;
  font-family: PT Root UI, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif
}

.CardInfoBlock_field__25Mff:hover {
    border-color: #b0c9d3;
  }

.CardInfoBlock_field__25Mff:focus {
    border-color: #8fb3c1;
  }

.CardInfoBlock_field-error__2bvFh {
  border-color: var(--red) !important;
  background-color: #faf5f6;
}

.CardInfoBlock_error__3HvkZ {
  color: var(--red)
}

.CardInfoBlock_emk-btn__2cHak {
  font-size: 1rem;
  line-height: 150%;
  color: var(--blue);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.CardInfoBlock_emk-btn__2cHak:hover {
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }

.CardInfoBlock_text__MFV-C {
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.CardInfoBlock_adding-field-wrapper__3aB0i {
  display: flex;
  align-items: center;
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
}

[dir="ltr"] .CardInfoBlock_adding-button__3_YXk {
          margin-left: 0.5rem
}

[dir="rtl"] .CardInfoBlock_adding-button__3_YXk {
          margin-right: 0.5rem
}

.CardInfoBlock_adding-button__3_YXk {
  height: 2rem;
  -webkit-margin-start: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  color: var(--white);
  background: var(--blue);
  white-space: nowrap
}

@media all and (min-width: 768px) {

.CardInfoBlock_container__2u053 {
    grid-area: 3 / span 2;
    -webkit-padding-after: 1rem;
            padding-bottom: 1rem
}

.CardInfoBlock_table-title__A58zz {
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 99%
}

.CardInfoBlock_row__UiDQM {
    grid-template: auto / 8rem auto;
    grid-gap: 0 2.25rem;
    padding: 0.5rem 2rem
}

[dir="ltr"] .CardInfoBlock_row-title__1zV5q {
    text-align: right
  }

[dir="rtl"] .CardInfoBlock_row-title__1zV5q {
    text-align: left
  }

.CardInfoBlock_row-title__1zV5q {
    align-self: center;
    min-width: 8rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    font-size: 0.75rem
}

[dir="ltr"] .CardInfoBlock_row-read__4mYCS {
    text-align: left
  }

[dir="rtl"] .CardInfoBlock_row-read__4mYCS {
    text-align: right
  }

.CardInfoBlock_field__25Mff {
    height: 2.5rem;
    padding: 0 0.8125rem;
    font-size: 1.125rem;
    line-height: 134%
}

.CardInfoBlock_error__3HvkZ {
    grid-area: 2 / 2
}

.CardInfoBlock_emk-btn__2cHak {
    font-size: 1.125rem;
    line-height: 134%
}

.CardInfoBlock_text__MFV-C {
    font-size: 1.125rem;
    line-height: 134%
}

[dir="ltr"] .CardInfoBlock_adding-button__3_YXk {
            margin-left: 0.625rem
  }

[dir="rtl"] .CardInfoBlock_adding-button__3_YXk {
            margin-right: 0.625rem
  }

.CardInfoBlock_adding-button__3_YXk {
    -webkit-margin-start: 0.625rem;
    height: 2.5rem;
    padding: 0 0.625rem;
    font-size: 1.125rem
}
}

@media all and (min-width: 1200px) {

.CardInfoBlock_container__2u053 {
    grid-area: span 2 / auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.CardInfoBlock_table__2cTsh:first-child {
      -webkit-margin-after: 1.5rem;
              margin-bottom: 1.5rem
  }
}

.GoatsTreeButton_button__2iNvH {
    height: 2rem;
    padding: 0 0.625rem;
    font-size: 0.875rem;
    border-radius: 5px;
    font-weight: 500
  }
  
  .GoatsTreeButton_button-show__30_Ly {
    background-color: var(--blue);
    color: var(--white);
  }
  
  @media all and (min-width: 768px) {
  
  [dir="ltr"] .GoatsTreeButton_button__2iNvH:not(:last-of-type) {
                margin-right: 0.5rem
    }
  
  [dir="rtl"] .GoatsTreeButton_button__2iNvH:not(:last-of-type) {
                margin-left: 0.5rem
    }
  
  .GoatsTreeButton_button__2iNvH:not(:last-of-type) {
        -webkit-margin-end: 0.5rem
    }
}

.Branch_branch__3Zact {
    padding-top: 1.25rem;
    position: relative;
}
/*Add downward connectors from parents*/
.Branch_branch__3Zact .Branch_branch__3Zact::before{
	content: '';
	position: absolute;
    top: 0;
    left: 50%;
	border-left: 1px solid #ccc;
	width: 0; 
    height: 1.25rem;
}

.Leaf_leaf__1RQAY{
    width: 7.438rem;
    border-radius: 8px;
    border: 1px solid #C1D1D8;
    background: #F0F5F8;
    cursor: pointer;
}
.Leaf_inner__21nuT{
    padding: 1rem;
    text-align: center;
}
.Leaf_image__3E-Ku{
    width: 100%;
    height: 3.5rem;
    border-radius: 4px;
    margin-bottom: 0.8rem;
    overflow: hidden;
}
.Leaf_image__3E-Ku img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Leaf_title__2lUYg{
    font-weight: 500;
    font-size: 1rem;
    position: relative;
}
.Leaf_title__2lUYg[data-tooltip] {
    position: relative;
}
.Leaf_title__2lUYg[data-tooltip]:hover::before {
    visibility: visible;
    opacity: 1;
  }
.Leaf_title__2lUYg[data-tooltip]::before {
    text-align:center;
    content: attr(data-tooltip);
    visibility: hidden;
    opacity: 0;
    min-width: 100%;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;
    position: absolute;
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    padding: 0.25rem 0.5rem;
    background-color: #5a5a5a;
    color: white;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: 500;
    white-space: nowrap;
    transition: opacity ease-out 150ms, bottom ease-out 150ms;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
.Leaf_tag__3JVf9{
    font-weight: 400;
    font-size: 0.875rem;
    margin-top: 0.4rem;
}
.LeafBranch_node__1756I{
    list-style-type: none;
	float: left;
    text-align: center;
	
	position: relative;
	padding: 1.25rem 0.3125rem 0 0.3125rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
/*We will use ::before and ::after to draw the connectors*/
.LeafBranch_node__1756I::before, .LeafBranch_node__1756I::after{
	content: '';
	position: absolute;
    top: 0;
    right: 50%;
	border-top: 1px solid #ccc;
	width: 50%;
    height: 1.25rem;
}
.LeafBranch_node__1756I::after{
	right: auto;
    left: 50%;
	border-left: 1px solid #ccc;
}
/*We need to remove left-right connectors from elements without 
any siblings*/
.LeafBranch_node__1756I:only-child::after, .LeafBranch_node__1756I:only-child::before {
	display: none;
}
/*Remove space from the top of single children*/
.LeafBranch_node__1756I:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.LeafBranch_node__1756I:first-child::before, .LeafBranch_node__1756I:last-child::after{
	border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.LeafBranch_node__1756I:last-child::before{
	border-right: 1px solid #ccc;
	border-radius: 0 5px 0 0;
	-webkit-border-radius: 0 0.3125rem 0 0;
	-moz-border-radius: 0 0.3125rem 0 0;
}
.LeafBranch_node__1756I:first-child::after{
	border-radius: 5px 0 0 0;
	-webkit-border-radius: 0.3125rem 0 0 0;
	-moz-border-radius: 0.3125rem 0 0 0;
}
.TreeWrapper_treeWrapper__ynF5S{
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-margin-after: 3.25rem;
            margin-bottom: 3.25rem;
    
  }
.GoatContentModal_overlay__1UZW1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 0 0.5rem;
    background-color: rgba(12, 17, 22, 0.35);
    z-index: 1;
  }
  
  .GoatContentModal_modal__2gs-C {
    width: 100%;
    margin: auto;
    background: var(--white);
    outline: none;
    overflow: auto;
    border-radius: 10px;
  }
  
  [dir="ltr"] .GoatContentModal_btn-close__1I5V7 {
            margin-right: 1rem;
}
  
  [dir="rtl"] .GoatContentModal_btn-close__1I5V7 {
            margin-left: 1rem;
}
  
  .GoatContentModal_btn-close__1I5V7 {
    -webkit-margin-before: 1rem;
            margin-top: 1rem;
    -webkit-margin-end: 1rem;
  }
.SearchSelect_icon__3nzzp {
    width: 0.625rem;
    height: 0.375rem;
  }
  
  .SearchSelect_icon-reverse__1GxOM {
    transform: rotate(180deg);
  }
.GalleryField_wrapper__2HV-8{
  position: relative;
}
.GalleryField_row__3Lz_G {
    display: grid;
    grid-template: auto / auto;
    padding: 0.6875rem 1rem 1.0625rem;
    border-bottom: 1px solid #f0f5f8
  }
  
  .GalleryField_cell__1J2HL {
    width: 100%
  }
  
  .GalleryField_cell__1J2HL .react-datepicker-wrapper {
      width: inherit
    }
  
  .GalleryField_cell__1J2HL .react-datepicker-wrapper .GalleryField_field__2phkH {
        border-radius: 5px;
      }
  
  [dir="ltr"] .GalleryField_row-title__w8yS0{
    text-align: left;
}
  
  [dir="rtl"] .GalleryField_row-title__w8yS0{
    text-align: right;
}
  
  .GalleryField_row-title__w8yS0 {
    display: block;
    -webkit-margin-after: 0.25rem;
            margin-bottom: 0.25rem;
    font-size: 0.625rem;
    line-height: 200%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--dark-gray)
  }
  
  [dir="ltr"] .GalleryField_row-read__2ecIC{
    text-align: left;
}
  
  [dir="rtl"] .GalleryField_row-read__2ecIC{
    text-align: right;
}
  .GalleryField_button__2nphC {
    height: 2rem;
    padding: 0 0.625rem;
    font-size: 0.875rem;
    border-radius: 5px;
    font-weight: 500
  }
  
  .GalleryField_button-show__36xO_ {
    background-color: var(--blue);
    color: var(--white);
  }
  .GalleryField_disabled__3JjrH{
    opacity: 0.5;
    pointer-events: none
  }
  .GalleryField_saved__J7XqI{
    background-color: var(--green);
  }
  .GalleryField_error__N1dEA {
    color: var(--red)
  }
  .GalleryField_btn-remove__3uho_ {
    position: absolute;
    bottom: 1rem;
    right: 2rem
  }
  .GalleryField_btn-remove__3uho_ svg {
      width: 0.75rem;
      height: 1rem;
    }
  .GalleryField_red__1h2fy{
    background-color: var(--red);
  }
  .GalleryField_image-wrapper__28AL7{
    flex-shrink: 0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .GalleryField_date__2lGH4{
    position: absolute;
    left: 0.625rem;
    top: 0.625rem;
    color: var(--black);
    display: inline-block;
    border-radius: 5px;
    padding: 0.125rem 0.3125rem;
    background: var(--gray);
    opacity: 0.8
  }
  .GalleryField_image__3ATr_ {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .GalleryField_preview-wrapper__xHtHO{
    display: grid;
    grid-template-columns: repeat(5, 5rem);
    grid-column-gap: 0.125rem;
    grid-column-gap: 2px;
    column-gap: 0.125rem;
    grid-row-gap: 0.125rem;
    grid-row-gap: 2px;
    row-gap: 0.125rem;
    margin-top: 0.625rem
  }
  .GalleryField_preview__oFyNm{
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .GalleryField_preview-inner__6-QRk{
    border-radius: 12px;
    overflow: hidden;
    position: relative;
  }
  .GalleryField_preview-image__3a01e {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .GalleryField_icon-wrapper__2c8Pe{
    width: 1.5625rem;
    height: 1.5625rem;
    background-color: var(--gray);
    border-radius: 50%;
    position: absolute;
    right: 0.125rem;
    top: 0.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.7;
  }
  .GalleryField_close-icon__2aiN_ {
  width: 0.625rem;
  height: 0.8125rem;
  fill: var(--black) !important;
  }
  .GalleryField_preview-date__EwifP{
    font-size: 0.75rem;
  }
  @media screen and (min-width: 320px) and (max-width: 480px){
  .GalleryField_preview-wrapper__xHtHO{
      grid-template-columns: repeat(3, 5rem)
  }
}
  @media screen and (min-width: 320px) and (max-width: 768px){
  .GalleryField_date__2lGH4{
      font-size: 0.75rem
  }
}
  @media all and (min-width: 768px){
.GalleryField_row__3Lz_G {
      grid-template: auto / 8rem auto;
      grid-gap: 0 2.25rem;
      padding: 0.5rem 2rem
  }
  
  [dir="ltr"] .GalleryField_row-title__w8yS0{
      text-align: right;
  }
  
  [dir="rtl"] .GalleryField_row-title__w8yS0{
      text-align: left;
  }
  
  .GalleryField_row-title__w8yS0 {
      align-self: center;
      min-width: 8rem;
      -webkit-margin-after: 0;
              margin-bottom: 0;
      font-size: 0.75rem
  }
  
  [dir="ltr"] .GalleryField_row-read__2ecIC{
      text-align: left;
  }
  
  [dir="rtl"] .GalleryField_row-read__2ecIC{
      text-align: right;
  }
  [dir="ltr"] .GalleryField_button__2nphC:not(:last-of-type){
                margin-right: 0.5rem;
  }
  [dir="rtl"] .GalleryField_button__2nphC:not(:last-of-type){
                margin-left: 0.5rem;
  }
  .GalleryField_button__2nphC:not(:last-of-type) {
        -webkit-margin-end: 0.5rem
    }
  .GalleryField_error__N1dEA {
      grid-area: 2 / 2
  }
}
  @media all and (min-width: 992px){
  .GalleryField_error__N1dEA {
      grid-area: initial
  }
}
.Slider_wrapper__3n6W3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.25rem;
}
.Slider_container__1HSwQ{
    display: flex;
}
.Slider_frame__p3uwu{
     overflow: hidden;
     width: 9.375rem
}
.Slider_buttons__1EYYQ{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.625rem;
}
.Slider_arrow-wrapper__1VJyk{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    -webkit-margin-after: 0.0625rem;
            margin-bottom: 0.0625rem;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);
}
.Slider_arrow-wrapper__1VJyk:hover{
  border: 1px solid #d2e1e7;
  cursor: pointer;
}
.Slider_arrow__1opKy {
  width: 0.625rem;
  height: 0.8125rem;
  fill: var(--blue) !important;
}
.Slider_arrow-right__2cfbL{
  transform: rotate(180deg);
}
.Slider_disabled__3DF25{
  pointer-events: none;
  opacity: 0.3;
  
}
@media all and (min-width: 768px){
.Slider_frame__p3uwu{
      width: 32rem
}
}
[dir="ltr"] .CommentActions_wrapper__2AvK_ {
          margin-left: 0.625rem;
}

[dir="rtl"] .CommentActions_wrapper__2AvK_ {
          margin-right: 0.625rem;
}

.CommentActions_wrapper__2AvK_ {
  position: relative;
  -webkit-margin-start: 0.625rem;
}

.CommentActions_dots__5dQEl {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.CommentActions_dot__1o1Sy {
  display: block;
  min-width: 0.3125rem;
  min-height: 0.3125rem;
  background-color: var(--black);
  border-radius: 50%
}

[dir="ltr"] .CommentActions_dot__1o1Sy:not(:last-of-type) {
            margin-right: 0.25rem;
}

[dir="rtl"] .CommentActions_dot__1o1Sy:not(:last-of-type) {
            margin-left: 0.25rem;
}

.CommentActions_dot__1o1Sy:not(:last-of-type) {
    -webkit-margin-end: 0.25rem;
  }

.CommentActions_list__1xMVW {
  position: absolute;
  right: -1rem;
  width: -webkit-max-content;
  width: max-content;
  -webkit-margin-before: 0.125rem;
          margin-top: 0.125rem;
  padding: 0.625rem 0;
  border-radius: 3px;
  background-color: #24292c;
  box-shadow: 0 0.375rem 0.625rem 0.125rem rgba(134, 134, 134, 0.2)
}

.CommentActions_list__1xMVW::before {
    content: '';
    position: absolute;
    top: -0.3125rem;
    right: 1.375rem;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #24292c
  }

[dir="ltr"] .CommentActions_btn__1rbwJ {
  text-align: left;
}

[dir="rtl"] .CommentActions_btn__1rbwJ {
  text-align: right;
}

.CommentActions_btn__1rbwJ {
  width: 100%;
  height: 1.75rem;
  padding: 0 1.0625rem;
  font-size: 0.875rem;
  color: var(--white)
}

.CommentActions_btn__1rbwJ:hover {
    background-color: var(--blue);
  }

@media all and (min-width: 1200px) {

.CommentActions_list__1xMVW {
    right: -1.5rem
}

.CommentActions_list__1xMVW::before {
      right: 1.875rem
  }
}

.Comment_wrapper__2RVm8 {
  display: grid;
  grid-template: auto / auto 1fr auto;
  padding: 1.5rem
}

.Comment_wrapper__2RVm8:not(:last-of-type) {
    border-bottom: 1px solid #dce8ed;
  }

[dir="ltr"] .Comment_avatar__rs2SZ {
          margin-right: 1rem
}

[dir="rtl"] .Comment_avatar__rs2SZ {
          margin-left: 1rem
}

.Comment_avatar__rs2SZ {
  grid-area: span 2 / 1;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  -webkit-margin-end: 1rem;
  border-radius: 50%;
  border: 1px solid #baebff;
  background-color: #f1fbff;
}

.Comment_char__x5sz8 {
  font-size: 1.5rem;
  line-height: 99%;
  color: var(--blue);
  font-weight: 700;
}

.Comment_name__33nPK {
  grid-area: 1 / 2;
  align-self: center;
  -webkit-margin-after: 0.25rem;
          margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 129%;
  color: var(--black);
  font-weight: 700;
}

.Comment_date__CvIyv {
  grid-area: 2 / 2;
  align-self: center;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--dark-gray);
}

.Comment_comment__2W38M {
  grid-area: 3 / span 3;
  -webkit-margin-before: 1rem;
          margin-top: 1rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
}

.Comment_comment-edited__mQP4s {
  font-size: 0.875rem;
  color: var(--dark-gray);
}

@media all and (min-width: 1200px) {

.Comment_wrapper__2RVm8 {
    padding: 1.5rem 2rem
}
}

.DeletedComment_wrapper__3CdIP {
  padding: 1.625rem;
  border-bottom: 1px solid #dce8ed;
}

.DeletedComment_comment__2cB3p {
  border: 1px solid #d0dfe6;
  border-radius: 5px;
  background: #f3f8fa;
  padding: 1.125rem 1.5rem;
}

.DeletedComment_text__1dkIH {
  -webkit-margin-after: 0.125rem;
          margin-bottom: 0.125rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
}

.DeletedComment_button__wzy8R {
  font-size: 1rem;
  line-height: 150%;
  color: var(--blue);
}

.CommentsBlock_wrapper__1aM4V {
  background-color: var(--white);
  border-top: 1px solid #dce8ed
}

.CommentsBlock_wrapper__1aM4V::-webkit-scrollbar {
    width: 0;
  }

.CommentsBlock_wrapper-close__2TCp6 {
  display: flex;
  justify-content: center;
  min-height: 3.75rem;
  align-items: center
}

.CommentsBlock_header__U50p6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.5625rem;
  padding: 0 1.5rem;
  border-bottom: 1px solid #dce8ed
}

.CommentsBlock_title__2WAt5 {
  font-size: 1.3125rem;
  line-height: 116%;
  font-weight: 700;
  color: var(--black)
}

.CommentsBlock_open-btn__1gk07 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CommentsBlock_close-icon__1mwGq {
  width: 0.9375rem;
  height: 0.75rem;
}

.CommentsBlock_open-icon__1Tm3x {
  width: 1.25rem;
  height: 1.25rem;
}

.CommentsBlock_open-text__2DQAb {
  -webkit-margin-before: 0.3125rem;
          margin-top: 0.3125rem;
  font-size: 0.5625rem;
  line-height: 114%;
}

.CommentsBlock_form__1xozY {
  padding: 1.5rem;
  border-bottom: 1px solid #dce8ed
}

.CommentsBlock_user-block__1kQPO {
  display: flex;
  align-items: center;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

[dir="ltr"] .CommentsBlock_user-avatar__1KMH0 {
          margin-right: 1rem
}

[dir="rtl"] .CommentsBlock_user-avatar__1KMH0 {
          margin-left: 1rem
}

.CommentsBlock_user-avatar__1KMH0 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  -webkit-margin-end: 1rem;
  border-radius: 50%;
  border: 1px solid #baebff;
  background-color: #f1fbff;
}

.CommentsBlock_user-char__1FVg7 {
  font-size: 1.5rem;
  line-height: 99%;
  color: var(--blue);
  font-weight: 700;
}

.CommentsBlock_user-name__6Cbxg {
  font-size: 1rem;
  line-height: 129%;
  color: var(--black);
  font-weight: 700;
}

.CommentsBlock_textarea__xl3oa {
  width: 100%;
  max-height: 12.5rem;
  border: 0;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  resize: none
}

.CommentsBlock_textarea__xl3oa::-ms-input-placeholder {
    color: var(--dark-gray);
  }

&::-ms-input-placeholder {
    color: var(--dark-gray);
  }

.CommentsBlock_textarea__xl3oa::placeholder {
    color: var(--dark-gray);
  }

.CommentsBlock_textarea__xl3oa::-webkit-scrollbar {
    width: 0;
  }

.CommentsBlock_btns__hJoIu {
  display: flex;
  justify-content: flex-end;
  -webkit-margin-before: 1.5rem;
          margin-top: 1.5rem;
}

.CommentsBlock_btn__1jUhk {
  padding: 0 0.625rem;
  height: 2rem;
  font-size: 0.875rem;
  border-radius: 3px;
  font-weight: 500
}

[dir="ltr"] .CommentsBlock_btn__1jUhk:not(:last-child) {
            margin-right: 0.5625rem
}

[dir="rtl"] .CommentsBlock_btn__1jUhk:not(:last-child) {
            margin-left: 0.5625rem
}

.CommentsBlock_btn__1jUhk:not(:last-child) {
    -webkit-margin-end: 0.5625rem;
  }

.CommentsBlock_btn-cancel__23AuV {
  border: 1px solid #cddbe4;
  color: var(--black);
}

.CommentsBlock_btn-publish__34VPi {
  color: var(--white);
  background-color: var(--blue);
}

@media all and (min-width: 768px) {

.CommentsBlock_wrapper__1aM4V {
    margin: 0 8rem 3rem;
    border: 1px solid #dce8ed;
    overflow-y: scroll
}
}

@media all and (min-width: 1200px) {

.CommentsBlock_wrapper__1aM4V {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: 0;
    border-left: 1px solid #dce8ed
}

.CommentsBlock_wrapper-open__3y-qV {
    width: 23rem
}

.CommentsBlock_wrapper-close__2TCp6 {
    align-items: initial;
    width: 5rem;
    height: auto;
    height: initial;
    -webkit-padding-before: 1.75rem;
            padding-top: 1.75rem
}

.CommentsBlock_header__U50p6 {
    padding: 0 2rem
}

.CommentsBlock_title__2WAt5 {
    font-size: 1.125rem;
    line-height: 134%
}

.CommentsBlock_form__1xozY {
    padding: 1.5rem 2rem
}

.CommentsBlock_textarea__xl3oa {
    font-size: 1rem;
    line-height: 150%
}
}

.AvatarLoader_AvatarLoader__2Dkf- {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.AvatarLoader_lds-ring__2pvBP {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
}

.AvatarLoader_lds-ring__2pvBP div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 4rem;
    height: 4rem;
    margin: 0.5rem;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: AvatarLoader_lds-ring__2pvBP 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00a6ed transparent transparent transparent;
}

.AvatarLoader_lds-ring__2pvBP div:nth-child(1) {
    animation-delay: -0.45s;
}

.AvatarLoader_lds-ring__2pvBP div:nth-child(2) {
    animation-delay: -0.3s;
}

.AvatarLoader_lds-ring__2pvBP div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes AvatarLoader_lds-ring__2pvBP {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.CardPhoto_wrapper__2gk9H {
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem
}

.CardPhoto_container__AXg59 {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-padding-before: 68.8752%;
          padding-top: 68.8752%;
  border-radius: 10px;
  overflow: hidden
}

.CardPhoto_container-empty__84wvY {
  border: 2px dashed #c1d1d8;
}

.CardPhoto_btn-upload__1bDU7 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.CardPhoto_btn-upload__1bDU7:hover {
    cursor: pointer;
  }

.CardPhoto_icon__2glWY {
  width: 4.5rem;
  height: 4.5rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

.CardPhoto_image__1g9RY {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
}

.CardPhoto_btn-remove__1wE5g {
  position: absolute;
  bottom: 1rem;
  right: 1rem
}

.CardPhoto_btn-remove__1wE5g svg {
    width: 0.75rem;
    height: 1rem;
  }

.CardPhoto_file-input__2zhIR {
  display: none;
}

.CardPhoto_error__2iloJ {
  color: var(--red)
 
}

@media all and (min-width: 480px) {

.CardPhoto_container__AXg59 {
    -webkit-padding-before: 0;
            padding-top: 0;
    width: 19.5rem;
    height: 19.5rem;
    margin: auto
}
}

@media all and (min-width: 768px) {

.CardPhoto_wrapper__2gk9H {
    display: grid;
    grid-template: auto / 1fr 19.5rem 1fr;
    grid-area: 2 / span 2;
    grid-gap: 0 5rem;
    justify-self: center;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    width: calc(100% + 4.5rem)
    /* max-inline-size: 312px; */
}

.CardPhoto_container__AXg59 {
    grid-area: 1 / 2
}

.CardPhoto_error__2iloJ {
    grid-area: 2 / 2
 
}
}

@media all and (min-width: 992px) {

.CardPhoto_container__AXg59 {
    grid-area: initial
}

.CardPhoto_error__2iloJ {
    grid-area: initial
 
}
}

@media all and (min-width: 1200px) {

.CardPhoto_wrapper__2gk9H {
    grid-template: initial;
    grid-area: 2 / 1;
    grid-gap: initial;
    justify-self: initial;
    width: 100%
}
}
.BindingModal_overlay__1voBD {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 0.5rem;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.BindingModal_modal__1mBFk {
  max-width: 26.25rem;
  width: 100%;
  margin: auto;
  background: var(--white);
  outline: none;
  overflow: auto;
  border-radius: 10px;
}

[dir="ltr"] .BindingModal_btn-close__2dJs2 {
          margin-right: 1rem;
}

[dir="rtl"] .BindingModal_btn-close__2dJs2 {
          margin-left: 1rem;
}

.BindingModal_btn-close__2dJs2 {
  -webkit-margin-before: 1rem;
          margin-top: 1rem;
  -webkit-margin-end: 1rem;
}

.BindingModal_title__1qvIb {
  -webkit-margin-after: 1.375rem;
          margin-bottom: 1.375rem;
  padding: 0 1.75rem;
  font-size: 1.3125rem;
  line-height: 138%;
  color: var(--black);
  font-weight: 700;
}

.BindingModal_list__3NDgz {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem;
}

.BindingModal_binding-btn__16F3P {
  height: 2rem;
  padding: 0 0.75rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 114%;
  border: 1px solid #95b4c1;
  color: #95b4c1;
  border-radius: 3px;
  white-space: nowrap;
  transition: background-color ease-in-out 0.12s, color ease-in-out 0.12s;
}

.BindingModal_list-item__HdLiI {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding: 0 1.75rem;
  border-top: 1px solid #f0f5f8;
  border-bottom: 1px solid #f0f5f8
}

.BindingModal_list-item__HdLiI:not(:first-child) {
    -webkit-margin-before: -0.0625rem;
            margin-top: -0.0625rem;
  }

.BindingModal_list-item__HdLiI:hover {
    background-color: #f3f7f9;
    border-color: #d2e1e7
  }

.BindingModal_list-item__HdLiI:hover .BindingModal_binding-btn__16F3P {
      background-color: #5c899c;
      color: #fff;
    }

.BindingModal_list-item__HdLiI:hover + .BindingModal_list-item__HdLiI {
      border-top-color: #d2e1e7;
    }

.BindingModal_identifier__1HWaj {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--black);
  box-shadow: 0 0.0625rem 0 black;
  transition: box-shadow ease-in-out 0.1s
}

.BindingModal_identifier__1HWaj:hover {
    box-shadow: 0 0.0625rem 0 transparent;
  }

.BindingModal_empty-block__ZfzlL {
  -webkit-margin-after: 1.75rem;
          margin-bottom: 1.75rem;
  padding: 0 1.75rem;
}

.BindingModal_empty-text__3XN9a {
  -webkit-margin-after: 1.125rem;
          margin-bottom: 1.125rem;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--black);
}

.BindingModal_make-btn__gDjSH {
  display: inline-flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
}

.BindingModal_pagination__2tdnB {
  -webkit-margin-after: 1.125rem;
          margin-bottom: 1.125rem;
  padding: 0 1.75rem;
}

.BindingModal_pagination-wrapper__3tbT6 {
  display: flex;
  justify-content: center;
}

[dir="ltr"] .BindingBlock_wrapper__2x4Ah {
          padding-left: 1rem
}

[dir="rtl"] .BindingBlock_wrapper__2x4Ah {
          padding-right: 1rem
}

.BindingBlock_wrapper__2x4Ah {
  display: flex;
  align-items: flex-start;
  /* inline-size: max-content; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 2.5rem;
  padding: 0.25rem;
  -webkit-padding-start: 1rem;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background-color: var(--white)
}

.BindingBlock_title__X28GJ {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: var(--black);
  font-weight: 500;
  vertical-align: middle;
}

[dir="ltr"] .BindingBlock_text__2zO58 {
          margin-right: 1rem
}

[dir="rtl"] .BindingBlock_text__2zO58 {
          margin-left: 1rem
}

.BindingBlock_text__2zO58 {
  -webkit-margin-end: 1rem;
  font-size: 0.875rem;
  line-height: 1.6875rem;
  font-weight: 500
}

.BindingBlock_number__28Im8 {
  display: inline-block;
  vertical-align: middle;
}

.BindingBlock_link__35l4G {
  font-size: inherit;
  line-height: inherit;
  color: var(--blue);
}

.BindingBlock_buttons__vm2Ue {
  display: flex;
}

.BindingBlock_button__fMM07 {
  min-height: 2rem;
  padding: 0 0.75rem;
  border-radius: 3px;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap
}

[dir="ltr"] .BindingBlock_button__fMM07:not(:last-child) {
            margin-right: 0.3125rem
}

[dir="rtl"] .BindingBlock_button__fMM07:not(:last-child) {
            margin-left: 0.3125rem
}

.BindingBlock_button__fMM07:not(:last-child) {
    -webkit-margin-end: 0.3125rem;
  }

.BindingBlock_button-make__2xj-1 {
  display: flex;
  align-items: center;
  background-color: var(--blue);
}

.BindingBlock_button-bind__1TlQR {
  background-color: #5c899c;
}

.BindingBlock_button-unbind__2uCMi {
  background-color: #5c899c;
}

@media all and (min-width: 768px) {

[dir="ltr"] .BindingBlock_wrapper__2x4Ah {
            padding-left: 1rem
          }

[dir="rtl"] .BindingBlock_wrapper__2x4Ah {
            padding-right: 1rem
          }

.BindingBlock_wrapper__2x4Ah {
    padding: 0.1875rem;
    -webkit-padding-start: 1rem
}

.BindingBlock_text__2zO58 {
    font-size: 1rem
}
}

.Goat_wrapper__Tz9M1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: calc(100vh - 3.5rem)
}

.Goat_container__jl4ae {
  width: 100%;
  padding: 1.5rem 0.5rem 2rem;
  background-color: #f4f7f9
}

.Goat_blocks-wrapper__2hha4 {
  grid-area: auto / span 2;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 1.5rem
}

/* Header styles */
.Goat_btn-save__TeURl {
  background-color: var(--green)
}
.Goat_btn-save__TeURl p {
    color: white;
  }
.Goat_btn-save__TeURl svg {
    width: 0.9375rem;
    height: 0.5625rem;
  }

.Goat_btn-cancel__ZQUbt svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.Goat_btn-edit__2TSjE {
  background-color: var(--blue)
}

.Goat_btn-edit__2TSjE p {
    color: white;
  }

.Goat_btn-edit__2TSjE svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.Goat_btn-remove__Th8xY svg {
    width: 0.75rem;
    height: 1rem;
  }

/* Mobile tabs */
.Goat_tabs__2xCoN .react-tabs__tab-list {
    display: flex;
    margin: 0 -0.5rem 0.75rem;
    padding: 0 0.5rem;
    border-bottom: 1px solid #dde7ec;
  }
.Goat_tabs__2xCoN .react-tabs__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
  }
.Goat_tabs__2xCoN .react-tabs__tab--selected {
    color: var(--blue);
    box-shadow: inset 0 -0.125rem var(--blue);
  }

.Goat_emr__3pSG6 {

  /* stylelint-disable-next-line */
}

.Goat_emr__3pSG6 {

  /* stylelint-disable-next-line */
}

.Goat_emr__3pSG6 {

  /* stylelint-disable-next-line */
}

.Goat_emr__3pSG6 button {
    min-width: 6rem;

    /* &:not(:last-child) {
      margin-inline-end: 0;
      margin-block-end: 8px;
    } */
  }

@media all and (min-width: 768px) {

.Goat_wrapper__Tz9M1 {
    height: auto;
    height: initial
}

.Goat_container__jl4ae {
    padding: 2rem 3.75rem 3rem
}

.Goat_form__DI5Uv {
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 1.5rem
}
}

@media all and (min-width: 768px) and (max-width: 1199px) {

.Goat_emr__3pSG6 {
    flex-direction: column;
    align-items: center;
    padding: 0.625rem 0.875rem 0.875rem
}

[dir="ltr"] .Goat_emr__3pSG6 p {
              margin-right: 0
  }

[dir="rtl"] .Goat_emr__3pSG6 p {
              margin-left: 0
  }

.Goat_emr__3pSG6 p {
      -webkit-margin-end: 0;
      -webkit-margin-after: 0.625rem;
              margin-bottom: 0.625rem
  }

.Goat_emr__3pSG6 div {
      display: flex;
      flex-direction: column
  }

[dir="ltr"] .Goat_emr-button__zFrGg:not(:last-child) {
              margin-right: 0
  }

[dir="rtl"] .Goat_emr-button__zFrGg:not(:last-child) {
              margin-left: 0
  }

.Goat_emr-button__zFrGg:not(:last-child) {
      -webkit-margin-end: 0;
      -webkit-margin-after: 0.5rem;
              margin-bottom: 0.5rem;
      justify-content: center
  }
}

@media all and (min-width: 1200px) {

.Goat_wrapper__Tz9M1 {
    padding: 0 3rem
}

.Goat_container__jl4ae {
    max-width: 84rem;
    margin: 0 auto;
    padding: 2rem 0 3rem
}

.Goat_form__DI5Uv {
    grid-template: auto minmax(19.5rem, auto) auto / 19.5rem 1fr;
    width: calc(100% - var(--comment-block-size));
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem
}

.Goat_form-create__2kDuc {
    width: 100%
}

.Goat_blocks-wrapper__2hha4 {
    grid-template: initial;
    grid-area: 3 / 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}
}

.GoatsHeader_header__Jt5V- {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.GoatsHeader_title__UuXRM {
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.GoatsHeader_buttons__3o92e {
  display: flex;
}

[dir="ltr"] .GoatsHeader_button__1JRc2:not(:last-child) {
            margin-right: 0.5rem;
}

[dir="rtl"] .GoatsHeader_button__1JRc2:not(:last-child) {
            margin-left: 0.5rem;
}

.GoatsHeader_button__1JRc2:not(:last-child) {
    -webkit-margin-end: 0.5rem
  }

.GoatsHeader_button-add__2V7u- {
  background-color: var(--blue)
}

.GoatsHeader_button-add__2V7u- p {
    color: var(--white);
  }

.GoatsHeader_button-add__2V7u- svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.GoatsHeader_button-reload__2ICY1 svg {
    width: 1rem;
    height: 1.25rem;
  }

@media all and (min-width: 768px) {

.GoatsHeader_title__UuXRM {
    font-size: 2rem;
    line-height: 2.5rem
}

[dir="ltr"] .GoatsHeader_button__1JRc2:not(:last-child) {
              margin-right: 1rem;
  }

[dir="rtl"] .GoatsHeader_button__1JRc2:not(:last-child) {
              margin-left: 1rem;
  }

.GoatsHeader_button__1JRc2:not(:last-child) {
      -webkit-margin-end: 1rem
  }
}

.FilterCheckbox_button__2k7eh {
  height: 2rem;
  padding: 0 0.75rem;
  border: 1px solid #d2e1e7;
  border-radius: 3px;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black);
  background-color: var(--white);
}

.FilterCheckbox_button-selected__2hY0E {
  border: 0;
  background: #5c899c;
  color: var(--white);
  font-weight: 500;
}

.FilterCheckbox_checkbox__1bSOd {
  display: none;
}

[dir="ltr"] .FilterCheckbox_icon__1cYWo {
          margin-left: 0.5rem;
}

[dir="rtl"] .FilterCheckbox_icon__1cYWo {
          margin-right: 0.5rem;
}

.FilterCheckbox_icon__1cYWo {
  width: 0.5rem;
  height: 0.5rem;
  -webkit-margin-start: 0.5rem;
}

.HeadFilters_filter-list__1hjhZ {
  display: flex;
  flex-wrap: wrap;
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem
}

.HeadFilters_filter-list-item__3GcmL {
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem
}

[dir="ltr"] .HeadFilters_filter-list-item__3GcmL:not(:last-child) {
            margin-right: 0.5rem
}

[dir="rtl"] .HeadFilters_filter-list-item__3GcmL:not(:last-child) {
            margin-left: 0.5rem
}

.HeadFilters_filter-list-item__3GcmL:not(:last-child) {
    -webkit-margin-end: 0.5rem;
  }

.HeadFilters_filter-title__3crcj {
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 700
}

.HeadFilters_fields__13bAi {
  display: flex;
  align-items: center;
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.HeadFilters_separator__3zQsc {
  display: block;
  width: 0.5rem;
  height: 0.0625rem;
  margin: 0 0.5rem;
  background-color: #d2e1e7;
}

.HeadFilters_field__asMvp {
  width: 7.75rem
}

[dir="ltr"] .HeadFilters_field__asMvp:not(:last-of-type) {
            margin-right: 0.5rem
}

[dir="rtl"] .HeadFilters_field__asMvp:not(:last-of-type) {
            margin-left: 0.5rem
}

.HeadFilters_field__asMvp:not(:last-of-type) {
    -webkit-margin-end: 0.5rem
  }

.HeadFilters_field-icon__2PmOA {
  width: 0.6875rem;
  height: 0.625rem;
}

.HeadFilters_buttons__2VooQ {
  display: flex;
  justify-content: space-between
}

[dir="ltr"] .HeadFilters_buttons__2VooQ:not(:last-of-type) {
            margin-right: 0.5rem
}

[dir="rtl"] .HeadFilters_buttons__2VooQ:not(:last-of-type) {
            margin-left: 0.5rem
}

.HeadFilters_buttons__2VooQ:not(:last-of-type) {
    -webkit-margin-end: 0.5rem;
  }

.HeadFilters_button__2IvGY {
  height: 2rem;
  padding: 0 0.625rem;
  font-size: 0.875rem;
  border-radius: 5px;
  font-weight: 500
}

.HeadFilters_button-submit__19nCd {
  background-color: var(--blue);
  color: var(--white);
}

.HeadFilters_button-clear__2Nj24 {
  display: flex;
  align-items: center;
  border: 1px solid #cddbe4;
  background: var(--white);
  color: var(--black);
}

[dir="ltr"] .HeadFilters_icon-clear__3AeIt {
          margin-right: 0.4375rem
}

[dir="rtl"] .HeadFilters_icon-clear__3AeIt {
          margin-left: 0.4375rem
}

.HeadFilters_icon-clear__3AeIt {
  width: 0.75rem;
  height: 0.875rem;
  -webkit-margin-end: 0.4375rem;
}

@media all and (min-width: 768px) {

[dir="ltr"] .HeadFilters_button__2IvGY:not(:last-of-type) {
              margin-right: 0.5rem
  }

[dir="rtl"] .HeadFilters_button__2IvGY:not(:last-of-type) {
              margin-left: 0.5rem
  }

.HeadFilters_button__2IvGY:not(:last-of-type) {
      -webkit-margin-end: 0.5rem
  }
}

@media all and (min-width: 992px) {

.HeadFilters_filter-list__1hjhZ {
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.HeadFilters_filter-list-item__3GcmL {
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.HeadFilters_filter-title__3crcj {
    position: absolute !important;
    width: 0.0625rem !important;
    height: 0.0625rem !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    overflow: hidden
}

[dir="ltr"] .HeadFilters_fields__13bAi {
            margin-left: auto
  }

[dir="rtl"] .HeadFilters_fields__13bAi {
            margin-right: auto
  }

[dir="ltr"] .HeadFilters_fields__13bAi {
            margin-right: 0.5rem
  }

[dir="rtl"] .HeadFilters_fields__13bAi {
            margin-left: 0.5rem
  }

.HeadFilters_fields__13bAi {
    -webkit-margin-start: auto;
    -webkit-margin-end: 0.5rem;
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.HeadFilters_field__asMvp {
    width: 7.125rem
}

[dir="ltr"] .HeadFilters_field__asMvp:not(:last-of-type) {
              margin-right: 0
  }

[dir="rtl"] .HeadFilters_field__asMvp:not(:last-of-type) {
              margin-left: 0
  }

.HeadFilters_field__asMvp:not(:last-of-type) {
      -webkit-margin-end: 0
  }
}

.MenuFilters_wrapper__2OTaD {
  position: relative;
  z-index: 1;
}

.MenuFilters_text__2yIWE {
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black);
  font-weight: 500;
}

.MenuFilters_menu__-WoeA {
  top: 0;
  left: 0;
  position: absolute;
  max-width: 24.6875rem;
  width: 100%;
  padding: 1rem 1.5rem 1.5rem;
  border: 1px solid #dce8ed;
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12);
}

.MenuFilters_button-open__2GjtD {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.625rem;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background: var(--white);
}

.MenuFilters_button-close__1dB82 {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  padding: 0.5rem;
}

[dir="ltr"] .MenuFilters_filter-icon__2gwMf {
          margin-right: 0.375rem;
}

[dir="rtl"] .MenuFilters_filter-icon__2gwMf {
          margin-left: 0.375rem;
}

.MenuFilters_filter-icon__2gwMf {
  width: 0.75rem;
  height: 0.75rem;
  -webkit-margin-end: 0.375rem;
}

.MenuFilters_close-icon__33G-l {
  width: 0.75rem;
  height: 0.75rem;
}

.SearchTableHead_header__3ofdB {
  display: block;
  border-bottom: 1px solid #dce8ed;
  /* overflow: hidden; */
}

.SearchTableHead_row__2WRY7 {
  display: grid;
  grid-gap: 0 1.5rem;
  height: 5.4375rem;
  align-items: center;
  padding: 0 1.5rem;
  background-color: #f8fbfc
}

@media all and (min-width: 768px) {

.SearchTableHead_row__2WRY7 {
    grid-gap: 0 2rem;
    padding: 0 2rem
}
}

.GoatsSearchTable_wrapper__3Czam {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  width: calc(100% + 0.125rem);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden
}

.GoatsSearchTable_table-row__1Uuld {
  grid-template: auto / 7.25rem 8rem 13.25rem 8.75rem 6.6875rem 10.1875rem;
}

.GoatsSearchTable_table-body-row__XIkWV {
  display: grid;
  grid-gap: 0 1.5rem;
  min-height: 2rem;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid #f0f5f8
}

.GoatsSearchTable_table-body-row__XIkWV:hover {
    box-shadow: 0 -0.0625rem 0 0 #d2e1e7;
    border-bottom-color: #d2e1e7;
    background-color: #f3f7f9;
    cursor: pointer;
  }

.GoatsSearchTable_table-cell__1vvW2:first-child {
    text-align: right;
  }

.GoatsSearchTable_table-cell__1vvW2:nth-child(2) {
    text-align: right;
  }

.GoatsSearchTable_table-cell__1vvW2:nth-child(5) {
    text-align: right;
  }

.GoatsSearchTable_footer__2xPVU {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px
}

[dir="ltr"] .GoatsSearchTable_arrow__CLnPU {
          margin-left: 0.5625rem
}

[dir="rtl"] .GoatsSearchTable_arrow__CLnPU {
          margin-right: 0.5625rem
}

.GoatsSearchTable_arrow__CLnPU {
  -webkit-margin-start: 0.5625rem;
  width: 0.5625rem;
  height: 0.9375rem;
}

.GoatsSearchTable_arrow-ordered__20LSJ {
  transform: rotate(180deg);
}

@media all and (min-width: 768px) {

.GoatsSearchTable_wrapper__3Czam {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}

.GoatsSearchTable_table-body-row__XIkWV {
    grid-gap: 0 2rem;
    padding: 0 2rem
}

.GoatsSearchTable_footer__2xPVU {
    display: flex;
    justify-content: space-between;
    align-items: center
}
}

@media all and (min-width: 992px) {

.GoatsSearchTable_wrapper__3Czam {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

.Goats_container__3spDF {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.Goats_wrapper__2KHxR {
  max-width: 66.875rem;
}

.Goats_header__268J7 {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.Goats_filters-wrapper__PaIMo {
  padding: 1.5rem;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
}

.Goats_empty-results__SzDZQ {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

@media all and (min-width: 768px) {

.Goats_container__3spDF {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.Goats_header__268J7 {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.Goats_filters-wrapper__PaIMo {
    display: flex
}

.Goats_empty-results__SzDZQ {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}
}


  .TitledTableWrapper_wrapper__2oZYJ {
  border: 1px solid #dde7ec;
  border-radius: 10px;
  -webkit-padding-after: 1rem;
          padding-bottom: 1rem;
  background-color: var(--white);
}

.TitledTableWrapper_wrapper-empty__1vB2T {
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem;
}

.TitledTableWrapper_title__-lxSX {
  padding: 1rem 1.5rem;
  font-size: 1.3125rem;
  line-height: 116%;
  font-weight: 700;
  color: var(--black)
}

.TitledTableWrapper_title-empty__3wRRB {
  padding: 1.5rem 1.5rem 0.5rem;
}

.TitledTableWrapper_content__1gEWf {
  border-top: 1px solid #dce8ed
}

.TitledTableWrapper_content__1gEWf .ScrollbarsCustom-Content {
    width: 46.8125rem;
  }

.TitledTableWrapper_content__1gEWf .ScrollbarsCustom-Track {
    height: 0.25rem !important;
    background-color: transparent !important;
    bottom: -0.6875rem !important;
  }

.TitledTableWrapper_content__1gEWf .ScrollbarsCustom-Thumb {
    background-color: #c1d1d8 !important;
  }

.TitledTableWrapper_content__1gEWf .ScrollbarsCustom-Wrapper {
    bottom: 0 !important;
  }

/* .content-hidden {
  display: none;
} */

.TitledTableWrapper_table__3YLxd {
  width: 100%;
}

.TitledTableWrapper_table-readable__3GYni {
  border-bottom: 1px solid #f0f5f8;
}

.TitledTableWrapper_text__3HbgP {
  padding: 0 1.5rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
}

.TitledTableWrapper_button-wrapper__3fiyw {
  padding: 1rem 1.5rem 0 1.5rem;
  border-top: 1px solid #dce8ed;
}

.TitledTableWrapper_button__2jOSz {
  height: 2rem;
  padding: 0 0.75rem;
  border-radius: 3px;
  background-color: var(--blue);
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--white);
  font-weight: 500;
}

@media all and (min-width: 768px) {

.TitledTableWrapper_container__yGgK3 {
    grid-area: auto / span 2
}

.TitledTableWrapper_title__-lxSX {
    padding: 1.5rem
}
}

@media all and (min-width: 992px) {

.TitledTableWrapper_container__yGgK3 {
    grid-area: auto
}
}


  .TitledAnnotationWrapper_wrapper__2RVcG {
  padding: 1.5rem 0 0.5rem 0;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white)
}

.TitledAnnotationWrapper_title__1YUIL {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  font-size: 1.3125rem;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);
}

.TitledAnnotationWrapper_textarea__3S1DI {
  width: 100%;
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid transparent;
  resize: none
}

.TitledAnnotationWrapper_textarea__3S1DI:hover {
    background-color: #f3f7f9;
    border-color: #d2e1e7;
  }

[dir="ltr"] .TitledAnnotationWrapper_textarea__3S1DI:focus {
            margin-left: 0.5rem
}

[dir="rtl"] .TitledAnnotationWrapper_textarea__3S1DI:focus {
            margin-right: 0.5rem
}

[dir="ltr"] .TitledAnnotationWrapper_textarea__3S1DI:focus {
            margin-right: 0.5rem
}

[dir="rtl"] .TitledAnnotationWrapper_textarea__3S1DI:focus {
            margin-left: 0.5rem
}

.TitledAnnotationWrapper_textarea__3S1DI:focus {
    width: calc(100% - 1rem);
    -webkit-margin-start: 0.5rem;
    -webkit-margin-end: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background-color: var(--white);
    border-color: transparent;
    box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 -0.125rem 0.375rem rgba(0, 23, 33, 0.07),
      0 0.0625rem 0.0625rem rgba(0, 23, 33, 0.07), 0 0.1875rem 0.1875rem rgba(0, 23, 33, 0.07);
    cursor: auto;
    cursor: initial
  }

.TitledAnnotationWrapper_textarea__3S1DI:placeholder {
    color: #7f8d94;
  }

.TitledAnnotationWrapper_text__2KhmI {
  padding: 0 1.5rem;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.TitledAnnotationWrapper_text-full__3Iw5I {
  -webkit-margin-before: 1rem;
          margin-top: 1rem
}

.TitledAnnotationWrapper_text-empty__1jdGJ {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem
}

@media all and (min-width: 768px) {

.TitledAnnotationWrapper_container__14LdB {
    grid-area: auto / span 2
}

.TitledAnnotationWrapper_wrapper__2RVcG {
    padding: 1.5rem 0 1rem 0
}

.TitledAnnotationWrapper_textarea__3S1DI {
    padding: 1rem 1.5rem
}

.TitledAnnotationWrapper_textarea__3S1DI:focus {
      padding: 1rem
  }

.TitledAnnotationWrapper_text__2KhmI {
    padding: 0 1.5rem 0.5rem
}

.TitledAnnotationWrapper_text-full__3Iw5I {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}

.TitledAnnotationWrapper_text-empty__1jdGJ {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}
}

.DiseasesModal_overlay__Y200B {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 0.5rem;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.DiseasesModal_modal__3Lmm6 {
  display: grid;
  width: 100%;
  max-width: 43.125rem;
  margin: 0.625rem auto;
  outline: none;
  border-radius: 10px;
  overflow: auto
}

.DiseasesModal_modal__3Lmm6::-webkit-scrollbar {
    width: 0;
  }

.DiseasesModal_wrapper__35SmP {
  -webkit-margin-before: auto;
          margin-top: auto;
  -webkit-margin-after: auto;
          margin-bottom: auto;
  -webkit-padding-after: 2rem;
          padding-bottom: 2rem;
  background: var(--white);
  border-radius: 10px;
  overflow: auto
}

[dir="ltr"] .DiseasesModal_btn-close__-MWWD {
          margin-right: 1.125rem;
}

[dir="rtl"] .DiseasesModal_btn-close__-MWWD {
          margin-left: 1.125rem;
}

.DiseasesModal_btn-close__-MWWD {
  -webkit-margin-before: 0.9375rem;
          margin-top: 0.9375rem;
  -webkit-margin-end: 1.125rem
}

.DiseasesModal_btn-close__-MWWD svg {
    height: 0.75rem;
    width: 0.75rem
  }

[dir="ltr"] .DiseasesModal_title__1BlRi {
          margin-left: 1.25rem;
}

[dir="rtl"] .DiseasesModal_title__1BlRi {
          margin-right: 1.25rem;
}

[dir="ltr"] .DiseasesModal_title__1BlRi {
          margin-right: 1.25rem;
}

[dir="rtl"] .DiseasesModal_title__1BlRi {
          margin-left: 1.25rem;
}

.DiseasesModal_title__1BlRi {
  -webkit-margin-start: 1.25rem;
  -webkit-margin-end: 1.25rem;
  -webkit-margin-after: 2rem;
          margin-bottom: 2rem;
  font-size: 1.75rem;
  line-height: 116%;
  font-weight: bold;
  color: var(--black)
}

.DiseasesModal_contet-row__Liarv {
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-bottom: 1px solid #f0f5f8
}

.DiseasesModal_contet-row__Liarv:first-of-type {
    border-top: 1px solid #f0f5f8;
  }

.DiseasesModal_contet-row-editable__oSPuO {
  border-color: transparent
}

.DiseasesModal_contet-row-editable__oSPuO:first-of-type {
    border-color: transparent;
  }

.DiseasesModal_field-title__15kFk {
  -webkit-margin-after: 0.25rem;
          margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #6e8189
}

.DiseasesModal_field-wrapper__30eNf {
}

.DiseasesModal_field-text__2gHwG {
  font-weight: 500;
  font-size: 1rem;
  color: var(--black);
}

.DiseasesModal_modal-field__23bxK {
  width: 100%;
  min-height: 3rem;
  padding: 0 1rem;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
}

.DiseasesModal_modal-textarea__1S912 {
  width: 100%;
  min-height: 6rem;
  padding: 0.75rem 1rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  resize: none;
}

.DiseasesModal_buttons__10aFx {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-margin-before: 2rem;
          margin-top: 2rem
}

.DiseasesModal_modal-button__hkIlk {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 0.8125rem;
  background-color: var(--white);
  border-radius: 5px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.DiseasesModal_modal-button__hkIlk:first-child {
    -webkit-margin-after: 1rem;
            margin-bottom: 1rem
  }

[dir="ltr"] .DiseasesModal_button-text__15uIC {
          margin-left: 0.625rem;
}

[dir="rtl"] .DiseasesModal_button-text__15uIC {
          margin-right: 0.625rem;
}

.DiseasesModal_button-text__15uIC {
  -webkit-margin-start: 0.625rem;
}

.DiseasesModal_button-pen__1GpSW {
  background: var(--blue);
  color: var(--white)
}

.DiseasesModal_button-pen__1GpSW svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.DiseasesModal_button-trash__1Zu5d {
  border: 1px solid #cddbe4
}

.DiseasesModal_button-trash__1Zu5d svg {
    width: 0.75rem;
    height: 1rem;
  }

.DiseasesModal_button-check-bold__1V66B {
  background: var(--green);
  color: var(--white)
}

.DiseasesModal_button-check-bold__1V66B svg {
    width: 1rem;
    height: 0.625rem;
  }

.DiseasesModal_button-close-bold__Z_85k {
  border: 1px solid #cddbe4
}

.DiseasesModal_button-close-bold__Z_85k svg {
    width: 0.75rem;
    height: 0.75rem;
  }

@media all and (min-width: 768px) {

.DiseasesModal_modal__3Lmm6 {
    margin: 2.5rem auto
}

.DiseasesModal_wrapper__35SmP {
    -webkit-padding-after: 2.5rem;
            padding-bottom: 2.5rem
}

[dir="ltr"] .DiseasesModal_btn-close__-MWWD {
            margin-right: 1.5625rem;
  }

[dir="rtl"] .DiseasesModal_btn-close__-MWWD {
            margin-left: 1.5625rem;
  }

.DiseasesModal_btn-close__-MWWD {
    -webkit-margin-before: 1.5625rem;
            margin-top: 1.5625rem;
    -webkit-margin-end: 1.5625rem
}

.DiseasesModal_btn-close__-MWWD svg {
      height: 0.875rem;
      width: 0.875rem
  }

[dir="ltr"] .DiseasesModal_title__1BlRi {
            margin-left: 2.5rem;
  }

[dir="rtl"] .DiseasesModal_title__1BlRi {
            margin-right: 2.5rem;
  }

[dir="ltr"] .DiseasesModal_title__1BlRi {
            margin-right: 2.5rem;
  }

[dir="rtl"] .DiseasesModal_title__1BlRi {
            margin-left: 2.5rem;
  }

.DiseasesModal_title__1BlRi {
    -webkit-margin-start: 2.5rem;
    -webkit-margin-end: 2.5rem;
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
    font-size: 1.5rem;
    line-height: 99%
}

.DiseasesModal_contet-row__Liarv {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 11.25rem 1fr;
    padding: 0.5rem 2.5rem 0.5rem 2.5rem
}

.DiseasesModal_field-title__15kFk {
    -webkit-margin-before: 0.25rem;
            margin-top: 0.25rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    font-size: 0.75rem;
    text-align: right
}

.DiseasesModal_field-title-editable__swcW7 {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}

[dir="ltr"] .DiseasesModal_buttons__10aFx {
            margin-left: 15.75rem;
  }

[dir="rtl"] .DiseasesModal_buttons__10aFx {
            margin-right: 15.75rem;
  }

.DiseasesModal_buttons__10aFx {
    flex-direction: row;
    -webkit-margin-start: 15.75rem;
    -webkit-margin-before: 2.5rem;
            margin-top: 2.5rem
}

[dir="ltr"] .DiseasesModal_modal-button__hkIlk:first-child {
              margin-right: 1rem;
  }

[dir="rtl"] .DiseasesModal_modal-button__hkIlk:first-child {
              margin-left: 1rem;
  }

.DiseasesModal_modal-button__hkIlk:first-child {
      -webkit-margin-after: 0;
              margin-bottom: 0;
      -webkit-margin-end: 1rem
  }
}

.ElectronicMediaclRecord_wrapper__1VoEZ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: calc(100vh - 3.5rem)
}

.ElectronicMediaclRecord_container__2g7px {
  width: 100%;
  padding: 1.5rem 0.5rem 2rem;
  background-color: #f4f7f9
}

.ElectronicMediaclRecord_form__f0xQ4 {
  display: grid;
  grid-gap: 1rem 0
}

.ElectronicMediaclRecord_blocks-wrapper__3I-6E {
  grid-area: auto / span 2;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 1.5rem
}

/* ---------------------------------------------------------------------------*/

.ElectronicMediaclRecord_btn-save__1AsKb {
  background-color: var(--green)
}

.ElectronicMediaclRecord_btn-save__1AsKb p {
    color: white;
  }

.ElectronicMediaclRecord_btn-save__1AsKb svg {
    width: 0.9375rem;
    height: 0.5625rem;
  }

.ElectronicMediaclRecord_btn-cancel__2zc7V svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.ElectronicMediaclRecord_btn-edit__1UoKN {
  background-color: var(--blue)
}

.ElectronicMediaclRecord_btn-edit__1UoKN p {
    color: white;
  }

.ElectronicMediaclRecord_btn-edit__1UoKN svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.ElectronicMediaclRecord_btn-remove__1zWs- svg {
    width: 0.75rem;
    height: 1rem;
  }

.ElectronicMediaclRecord_feed-table-content__1X4un {
  border-top: 0;
}

.ElectronicMediaclRecord_row__2efaU {
  display: grid;
  padding: 0 0.5rem;
  border-top: 1px solid #f0f5f8
}

.ElectronicMediaclRecord_row__2efaU:not(:last-of-type) {
    -webkit-margin-after: 0.0625rem;
            margin-bottom: 0.0625rem;
  }

.ElectronicMediaclRecord_head-row__EOSu9 {
  position: relative;
  -webkit-margin-before: -0.0625rem;
          margin-top: -0.0625rem;
  -webkit-margin-after: -0.0625rem;
          margin-bottom: -0.0625rem;
  padding: 0;
  border-bottom: 1px solid #dce8ed;
  z-index: 1;
}

.ElectronicMediaclRecord_vaccinations-row__3iG4n {
  grid-template: auto / 7.5rem 5.5625rem minmax(11.25rem, auto);
}

.ElectronicMediaclRecord_vaccinations-row-expanded__1j9US {
  grid-template: auto / 7.5rem 5.5625rem 11.25rem minmax(11.25rem, auto);
}

.ElectronicMediaclRecord_antiparasitics-row__1K1pN {
  grid-template: auto / 7.5rem 5.5625rem minmax(11.25rem, auto);
}

.ElectronicMediaclRecord_ills-row__2AfAt {
  grid-template: auto / 10rem 10rem minmax(11.25rem, auto);
}

.ElectronicMediaclRecord_matings-row__1-Udw {
  grid-template: auto / 7.5rem 5.5625rem minmax(11.25rem, auto);
}

[dir="ltr"] .ElectronicMediaclRecord_lambings-row__3_qrt {
          padding-right: 0
}

[dir="rtl"] .ElectronicMediaclRecord_lambings-row__3_qrt {
          padding-left: 0
}

.ElectronicMediaclRecord_lambings-row__3_qrt {
  grid-template: repeat(2, auto) / 7.5rem 6.25rem 5rem 5rem minmax(5rem, auto);
  -webkit-padding-end: 0;
}

.ElectronicMediaclRecord_lambings-row__3_qrt th:nth-child(3) {
    display: flex;
    justify-content: center;
    padding: 0;
  }

.ElectronicMediaclRecord_lambings-row__3_qrt th:nth-child(4) {
    display: flex;
    justify-content: center;
    padding: 0;
  }

.ElectronicMediaclRecord_feed-row__s_OUU {
  grid-template: auto / 3.75rem minmax(11.25rem, auto);
}

.ElectronicMediaclRecord_cell__1iDKH {
  position: relative;
  min-height: 2rem;
  padding: 0;
  border-right: 1px solid transparent;
  font-size: 1rem;
  color: var(--black)
}

.ElectronicMediaclRecord_cell__1iDKH .react-datepicker-wrapper {
    height: 100%;
    width: 100%;
  }

.ElectronicMediaclRecord_cell__1iDKH .react-datepicker__input-container {
    height: 100%;
    width: 100%;
  }

.ElectronicMediaclRecord_head-cell__1LIrr {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  background-color: #f9fbfc;
}

.ElectronicMediaclRecord_text-cell__1zJj2 {
  border-left: 1px solid #f0f5f8;
  border-bottom: 1px solid #f0f5f8;
}

.ElectronicMediaclRecord_last-text-cell__1FKuN {
  border-bottom: 0;
}

.ElectronicMediaclRecord_lambings-date-cell__3be-H {
  grid-area: span 2 / 1;
}

.ElectronicMediaclRecord_lambings-female-data-cell__2kAqf {
  border-bottom: 1px solid #f0f5f8;
}

[dir="ltr"] .ElectronicMediaclRecord_lambings-tight-cell__gyv0F {
          padding-right: 0.5rem
}

[dir="rtl"] .ElectronicMediaclRecord_lambings-tight-cell__gyv0F {
          padding-left: 0.5rem
}

.ElectronicMediaclRecord_lambings-tight-cell__gyv0F {
  -webkit-padding-end: 0.5rem;
}

.ElectronicMediaclRecord_field__Ky6r5 {
  width: 100%;
  min-height: 2rem;
  /* block-size: 100%; */
  padding: 0 1rem;
  border: 0
}

.ElectronicMediaclRecord_field__Ky6r5:hover {
    cursor: pointer;
    box-shadow: 0 0 0 0.0625rem #d2e1e7;
    background-color: #f3f7f9;
  }

.ElectronicMediaclRecord_field__Ky6r5:focus {
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 -0.125rem 0.375rem rgba(0, 23, 33, 0.07),
      0 0.0625rem 0.0625rem rgba(0, 23, 33, 0.07), 0 0.1875rem 0.1875rem rgba(0, 23, 33, 0.07);
    cursor: auto;
    cursor: initial;
  }

.ElectronicMediaclRecord_field__Ky6r5::-ms-input-placeholder {
    color: #7f8d94;
  }

&::-ms-input-placeholder {
    color: #7f8d94;
  }

.ElectronicMediaclRecord_field__Ky6r5::placeholder {
    color: #7f8d94;
  }

.ElectronicMediaclRecord_new-field__1O1Ib {
  border: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black);
  background-color: transparent
}

.ElectronicMediaclRecord_new-field__1O1Ib::-ms-input-placeholder {
    color: #809fad;
  }

&::-ms-input-placeholder {
    color: #809fad;
  }

.ElectronicMediaclRecord_new-field__1O1Ib::placeholder {
    color: #809fad;
  }

.ElectronicMediaclRecord_text__25jPC {
  display: flex;
  /* align-items: center; */
  height: 100%;
  padding: 0.25rem 1rem;
  font-size: 1rem;
  color: var(--black);
}

.ElectronicMediaclRecord_error__Gaxm6 {
  position: absolute;
  top: -1.875rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  height: 2rem;
  padding: 0 0.875rem;
  color: var(--white);
  border-radius: 5px;
  background-color: var(--dark-blue);
  box-shadow: 0 0 0.1875rem 0.0625rem var(--white);
  z-index: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.ElectronicMediaclRecord_error__Gaxm6:hover {
    z-index: 2;
  }

.ElectronicMediaclRecord_error__Gaxm6::before {
    position: absolute;
    bottom: -0.375rem;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--dark-blue);
  }

/* diseases table styles */
/* -------------------------------------------------------------------------- */

.ElectronicMediaclRecord_disease-button-wrapper__2tmds {
  display: flex;
  align-items: center;
}

.ElectronicMediaclRecord_disease-head-button__26mcg {
  border-bottom: 1px dashed #96b3c0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--black);
  white-space: nowrap
}

.ElectronicMediaclRecord_disease-head-button__26mcg:hover {
    border-bottom-color: transparent;
  }

[dir="ltr"] .ElectronicMediaclRecord_sort-icon__kYZ8Z {
          margin-left: 0.625rem
}

[dir="rtl"] .ElectronicMediaclRecord_sort-icon__kYZ8Z {
          margin-right: 0.625rem
}

.ElectronicMediaclRecord_sort-icon__kYZ8Z {
  -webkit-margin-start: 0.625rem;
  width: 0.5625rem;
  height: 0.75rem;
}

.ElectronicMediaclRecord_sort-icon-invert__3oGOj {
  transform: rotate(180deg);
}

.ElectronicMediaclRecord_disease-content-button__2PDqC {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 114%;
  color: var(--blue);
  text-align: left;
  text-align: initial;
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed
}

.ElectronicMediaclRecord_disease-content-button__2PDqC:hover {
    border-bottom-color: transparent;
    text-decoration: none;
  }

@media all and (min-width: 768px) {

.ElectronicMediaclRecord_wrapper__1VoEZ {
    height: auto;
    height: initial
}

.ElectronicMediaclRecord_container__2g7px {
    padding: 2rem 3.75rem 3rem
}

.ElectronicMediaclRecord_form__f0xQ4 {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 1.5rem
}

.ElectronicMediaclRecord_head__3pPEX {
    grid-area: auto / span 2
}
}

@media all and (min-width: 1200px) {

.ElectronicMediaclRecord_wrapper__1VoEZ {
    padding: 0 3rem
}

.ElectronicMediaclRecord_container__2g7px {
    max-width: 84rem;
    margin: 0 auto;
    padding: 2rem 0 3rem
}

.ElectronicMediaclRecord_form__f0xQ4 {
    width: calc(100% - var(--comment-block-size));
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem
}

.ElectronicMediaclRecord_form-create__17J30 {
    width: 100%
}

.ElectronicMediaclRecord_blocks-wrapper__3I-6E {
    grid-template: initial;
    grid-area: 3 / 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}
}

.EMRHeader_header__1Q_vm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EMRHeader_title__2rMZX {
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.EMRHeader_buttons__321RB {
  display: flex;
}

[dir="ltr"] .EMRHeader_button__31hze:not(:last-child) {
            margin-right: 0.5rem;
}

[dir="rtl"] .EMRHeader_button__31hze:not(:last-child) {
            margin-left: 0.5rem;
}

.EMRHeader_button__31hze:not(:last-child) {
    -webkit-margin-end: 0.5rem
  }

.EMRHeader_button-add__3WZKv {
  background-color: var(--blue)
}

.EMRHeader_button-add__3WZKv p {
    color: var(--white);
  }

.EMRHeader_button-add__3WZKv svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.EMRHeader_button-reload__2bVqD svg {
    width: 1rem;
    height: 1.25rem;
  }

@media all and (min-width: 768px) {

.EMRHeader_title__2rMZX {
    font-size: 2rem;
    line-height: 2.5rem
}

[dir="ltr"] .EMRHeader_button__31hze:not(:last-child) {
              margin-right: 1rem;
  }

[dir="rtl"] .EMRHeader_button__31hze:not(:last-child) {
              margin-left: 1rem;
  }

.EMRHeader_button__31hze:not(:last-child) {
      -webkit-margin-end: 1rem
  }
}

.EMRSearchTable_wrapper__2lTfP {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  width: calc(100% + 0.125rem);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden
}

.EMRSearchTable_table-row__14UTE {
  grid-template: auto / 7.25rem 13rem 7rem 9.9375rem;
}

.EMRSearchTable_table-body-row__3C7DT {
  display: grid;
  grid-gap: 0 1.5rem;
  min-height: 2rem;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid #f0f5f8
}

.EMRSearchTable_table-body-row__3C7DT:hover {
    box-shadow: 0 -0.0625rem 0 0 #d2e1e7;
    border-bottom-color: #d2e1e7;
    background-color: #f3f7f9;
    cursor: pointer;
  }

.EMRSearchTable_table-cell__1ZRqb {
  text-align: right
}

.EMRSearchTable_table-cell__1ZRqb:nth-child(2),
  .EMRSearchTable_table-cell__1ZRqb:nth-child(4) {
    text-align: left;
  }

.EMRSearchTable_footer__158Wc {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px
}

[dir="ltr"] .EMRSearchTable_arrow__1sg1R {
          margin-left: 0.5625rem
}

[dir="rtl"] .EMRSearchTable_arrow__1sg1R {
          margin-right: 0.5625rem
}

.EMRSearchTable_arrow__1sg1R {
  -webkit-margin-start: 0.5625rem;
  width: 0.5625rem;
  height: 0.9375rem;
}

.EMRSearchTable_arrow-ordered__2Kya_ {
  transform: rotate(180deg);
}

@media all and (min-width: 768px) {

.EMRSearchTable_wrapper__2lTfP {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}

.EMRSearchTable_table-body-row__3C7DT {
    grid-gap: 0 2rem;
    padding: 0 2rem
}

.EMRSearchTable_footer__158Wc {
    display: flex;
    justify-content: space-between;
    align-items: center
}
}

@media all and (min-width: 992px) {

.EMRSearchTable_wrapper__2lTfP {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

.ElectronicMediaclRecords_container__2Imp4 {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.ElectronicMediaclRecords_wrapper__kNETE {
  max-width: 57rem;
}

.ElectronicMediaclRecords_header__cirUc {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.ElectronicMediaclRecords_filters-wrapper__3S7ja {
  padding: 1.5rem;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
}

.ElectronicMediaclRecords_empty-results__2wO-l {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

@media all and (min-width: 768px) {

.ElectronicMediaclRecords_container__2Imp4 {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.ElectronicMediaclRecords_header__cirUc {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.ElectronicMediaclRecords_filters-wrapper__3S7ja {
    display: flex
}

.ElectronicMediaclRecords_empty-results__2wO-l {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}
}

.LogsHeader_header__3pnYa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LogsHeader_title__iiqvr {
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.LogsHeader_buttons__3qtdj {
  display: flex;
}

[dir="ltr"] .LogsHeader_button__3rBYc:not(:last-child) {
            margin-right: 0.5rem;
}

[dir="rtl"] .LogsHeader_button__3rBYc:not(:last-child) {
            margin-left: 0.5rem;
}

.LogsHeader_button__3rBYc:not(:last-child) {
    -webkit-margin-end: 0.5rem
  }

.LogsHeader_button-add__O6eQ_ {
  background-color: var(--blue)
}

.LogsHeader_button-add__O6eQ_ p {
    color: var(--white);
  }

.LogsHeader_button-add__O6eQ_ svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.LogsHeader_button-reload__3sxOb svg {
    width: 1rem;
    height: 1.25rem;
  }

@media all and (min-width: 768px) {

.LogsHeader_title__iiqvr {
    font-size: 2rem;
    line-height: 2.5rem
}

[dir="ltr"] .LogsHeader_button__3rBYc:not(:last-child) {
              margin-right: 1rem;
  }

[dir="rtl"] .LogsHeader_button__3rBYc:not(:last-child) {
              margin-left: 1rem;
  }

.LogsHeader_button__3rBYc:not(:last-child) {
      -webkit-margin-end: 1rem
  }
}

.LogsSearchTable_wrapper__3UZTu {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  width: calc(100% + 0.125rem);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden
}

.LogsSearchTable_table-row__p7XE_ {
  grid-template: auto / 8rem 15.5rem 8.5rem 9.9375rem;
}

.LogsSearchTable_table-body-row__2xO14 {
  display: grid;
  grid-gap: 0 1.5rem;
  min-height: 2rem;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid #f0f5f8
}

.LogsSearchTable_table-body-row__2xO14:hover {
    box-shadow: 0 -0.0625rem 0 0 #d2e1e7;
    border-bottom-color: #d2e1e7;
    background-color: #f3f7f9;
    cursor: pointer;
  }

.LogsSearchTable_table-cell__3S1X-:first-child {
    text-align: right;
  }

.LogsSearchTable_footer__3zqn8 {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px
}

[dir="ltr"] .LogsSearchTable_arrow__12iof {
          margin-left: 0.5625rem
}

[dir="rtl"] .LogsSearchTable_arrow__12iof {
          margin-right: 0.5625rem
}

.LogsSearchTable_arrow__12iof {
  -webkit-margin-start: 0.5625rem;
  width: 0.5625rem;
  height: 0.9375rem;
}

.LogsSearchTable_arrow-ordered__3NJfl {
  transform: rotate(180deg);
}

.LogsSearchTable_button-text__1YP66 {
  display: flex;
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  white-space: nowrap;
}

@media all and (min-width: 768px) {

.LogsSearchTable_wrapper__3UZTu {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}

.LogsSearchTable_table-body-row__2xO14 {
    grid-gap: 0 2rem;
    padding: 0 2rem
}

.LogsSearchTable_footer__3zqn8 {
    display: flex;
    justify-content: space-between;
    align-items: center
}
}

@media all and (min-width: 992px) {

.LogsSearchTable_wrapper__3UZTu {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

.Logs_container__2KEZU {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.Logs_wrapper__2uM6g {
  max-width: 57rem;
}

.Logs_header__AUasH {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.Logs_filters-wrapper__OR2n1 {
  padding: 1.5rem;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
}

.Logs_empty-results__3zpLx {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

@media all and (min-width: 768px) {

.Logs_container__2KEZU {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.Logs_header__AUasH {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.Logs_filters-wrapper__OR2n1 {
    display: flex
}

.Logs_empty-results__3zpLx {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}
}

.LogHeader_header__13zbb {
  display: flex;
  flex-direction: column;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem
}

.LogHeader_container__3wTED {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem
}

.LogHeader_containder-edit__2XNeD {
  align-items: center;
}

[dir="ltr"] .LogHeader_title__3QZwu {
          margin-right: 0.625rem
}

[dir="rtl"] .LogHeader_title__3QZwu {
          margin-left: 0.625rem
}

.LogHeader_title__3QZwu {
  -webkit-margin-end: 0.625rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: var(--black)
}

[dir="ltr"] .LogHeader_title__3QZwu + * {
            margin-right: 0.625rem
}

[dir="rtl"] .LogHeader_title__3QZwu + * {
            margin-left: 0.625rem
}

.LogHeader_title__3QZwu + * {
    -webkit-margin-end: 0.625rem;
  }

.LogHeader_info__2LeRH {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.LogHeader_actions__PYHVX {
  display: flex;
  align-items: center
}

[dir="ltr"] .LogHeader_actions__PYHVX > *:first-child {
            margin-right: 0.5rem
}

[dir="rtl"] .LogHeader_actions__PYHVX > *:first-child {
            margin-left: 0.5rem
}

.LogHeader_actions__PYHVX > *:first-child {
    -webkit-margin-end: 0.5rem
  }

[dir="ltr"] .LogHeader_field__1OQwD {
          margin-right: 0.5rem
}

[dir="rtl"] .LogHeader_field__1OQwD {
          margin-left: 0.5rem
}

.LogHeader_field__1OQwD {
  width: 100%;
  height: 2.5rem;
  -webkit-margin-end: 0.5rem;
  padding: 0 0.75rem;
  background: var(--white);
  border: 1px solid #cddbe4;
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--black)
}

.LogHeader_field__1OQwD::-ms-input-placeholder {
    color: #8ba7b4;
  }

&::-ms-input-placeholder {
    color: #8ba7b4;
  }

.LogHeader_field__1OQwD::placeholder {
    color: #8ba7b4;
  }

@media all and (min-width: 768px) {

.LogHeader_header__13zbb {
    grid-area: 1 / span 2;
    width: calc(100% + 4.5rem);
    margin: 0 -2.25rem 0.5rem
}

.LogHeader_container__3wTED {
    -webkit-margin-after: 0;
            margin-bottom: 0
}

[dir="ltr"] .LogHeader_title__3QZwu {
            margin-right: 1.5rem
  }

[dir="rtl"] .LogHeader_title__3QZwu {
            margin-left: 1.5rem
  }

.LogHeader_title__3QZwu {
    -webkit-margin-end: 1.5rem;
    font-size: 1.625rem
}

[dir="ltr"] .LogHeader_actions__PYHVX > *:first-child {
              margin-right: 1rem
  }

[dir="rtl"] .LogHeader_actions__PYHVX > *:first-child {
              margin-left: 1rem
  }

.LogHeader_actions__PYHVX > *:first-child {
      -webkit-margin-end: 1rem
  }

[dir="ltr"] .LogHeader_field__1OQwD {
            margin-right: 1rem
  }

[dir="rtl"] .LogHeader_field__1OQwD {
            margin-left: 1rem
  }

.LogHeader_field__1OQwD {
    height: 3rem;
    -webkit-margin-end: 1rem;
    font-size: 1.375rem
}
}

@media all and (min-width: 1200px) {

.LogHeader_header__13zbb {
    width: auto;
    width: initial;
    margin: 0;
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem
}
}

.Checkbox_checkbox-wrapper__HLTKI {
  position: relative;
  display: block;
  width: 1rem;
  height: 1rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.Checkbox_checkbox-wrapper__HLTKI:hover {
    cursor: pointer;
  }

.Checkbox_checkmark__R3Ah- {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 2px solid #c1d1d8;
  border-radius: 2px;
  background-color: var(--white)
}

.Checkbox_checkmark__R3Ah-::after {
    content: '';
    position: absolute;
    display: none;
    border: solid white;
  }

.Checkbox_checkmark__R3Ah-.Checkbox_check__3UD0p::after {
      left: 0.1875rem;
      top: 0;
      width: 0.375rem;
      height: 0.5625rem;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

.Checkbox_checkmark__R3Ah-.Checkbox_dash__16khs::after {
      left: 0.0625rem;
      top: 0.3125rem;
      width: 0.625rem;
      border-width: 0 2px 2px 0;
    }

.Checkbox_checked__1Ydr8 {
  background-color: #5c899c;
  border-color: #5c899c
}

.Checkbox_checked__1Ydr8::after {
    display: block;
  }


  .LogTableCell_clickable__2hpQP:hover {
    cursor: pointer;
  }

.LogTableCell_cell__3uRmP {
  height: 2.5rem;
  padding: 0.375rem 1rem;
  border: 1px solid #dce8ed;
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  background-color: transparent;
  vertical-align: top
}

.LogTableCell_cell__3uRmP:last-of-type {
    width: 100%;
  }

.LogTableCell_control-cell__k91dl {
  align-items: initial;
  height: 5rem;
  -webkit-padding-before: 0.875rem;
          padding-top: 0.875rem;
}

.LogTableCell_titled-cell__HvdXA {
  width: 9.375rem;
  min-width: 9.375rem;
  background-color: #f9fbfc;
  word-break: keep-all
}

.LogTableCell_checked-cell__3JrpY {
  vertical-align: middle;
}

.LogTableCell_input-cell__1cMsO {
  padding: 0;
}

.LogTableCell_selected-cell__oMt7S {
  background-color: #e9f0f3;
  border-color: #b1cbd6;
}

.LogTableCell_date-cell__3K4zY > div {
    height: 100%;
  }

.LogTableCell_date-cell__3K4zY .react-datepicker__input-container {
    height: 100%;
  }

.LogTableCell_field__xkVnH {
  display: flex;
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 0.375rem 1rem;
  border: 0;
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  background-color: transparent;
  resize: none;
  color: var(--black)
}

.LogTableCell_field__xkVnH::-webkit-scrollbar {
    width: 0;
  }

.LogTableCell_field__xkVnH:hover {
    background-color: #f3f7f9;
  }

.LogTableCell_field__xkVnH:focus {
    background-color: var(--white);
    box-shadow: 0 0 0.0625rem rgba(0, 98, 140, 0.7), 0 0.625rem 1.25rem rgba(0, 23, 33, 0.07),
      0 0.375rem 0.75rem rgba(0, 23, 33, 0.07), 0 0.1875rem 0.375rem rgba(0, 23, 33, 0.07);
    border-radius: 3px;
    cursor: auto;
    cursor: initial;
  }

.LogTableCell_field-date__3Dn7L {
  height: 100%;
  padding: 0.375rem 1rem;
}

@media all and (min-width: 768px) {

.LogTableCell_cell__3uRmP {
    font-size: 1rem;
    line-height: 150%
}

.LogTableCell_titled-cell__HvdXA {
    width: 10.9375rem;
    min-width: 10.9375rem
}

.LogTableCell_field__xkVnH {
    font-size: 1rem;
    line-height: 150%
}
}

.LogTable_wrapper__3CmfW {
  width: 100%;
  border: 1px solid #dce8ed;
  border-radius: 10px;
  background-color: var(--white);
  overflow: hidden;
}

.LogTable_table-wrapper__2RxI9 {
  overflow-x: auto
}

.LogTable_table-wrapper__2RxI9 .ScrollbarsCustom-Track {
    height: 0.25rem !important;
    background-color: transparent !important;
    bottom: 0.625rem !important;
  }

.LogTable_table-wrapper__2RxI9 .ScrollbarsCustom-Thumb {
    background-color: #c1d1d8 !important;
  }

.LogTable_table-wrapper__2RxI9 .ScrollbarsCustom-Wrapper {
    bottom: 0 !important;
  }

.LogTable_table__68u69 {
  /* inline-size: 100%; */
  background-color: var(--white);
  overflow: hidden;
}

.LogTable_plus-icon__23bki {
  width: 0.875rem;
  height: 0.875rem;
}

[dir="ltr"] .LogTable_plus-icon-filled__2omuu {
          margin-right: 0.5rem;
}

[dir="rtl"] .LogTable_plus-icon-filled__2omuu {
          margin-left: 0.5rem;
}

.LogTable_plus-icon-filled__2omuu {
  -webkit-margin-end: 0.5rem;
}

.LogTable_btn-add__25zUS {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 114%;
  color: #8ba7b4;
  font-weight: 500;
  transition: color 0.12s ease-in-out;
  white-space: nowrap
}

.LogTable_btn-add__25zUS:hover {
    color: #5c899c;
  }

.LogTable_control__1m6Ti {
  display: flex;
  height: 100%;
  padding: 0.5rem 0

  /* stylelint-disable-next-line */
}

[dir="ltr"] .LogTable_control__1m6Ti p {
            margin-right: 1rem;
}

[dir="rtl"] .LogTable_control__1m6Ti p {
            margin-left: 1rem;
}

.LogTable_control__1m6Ti p {
    -webkit-margin-before: 0.375rem;
            margin-top: 0.375rem;
    -webkit-margin-end: 1rem;
  }

.LogTable_btn__1eugA {
  display: flex;
  align-items: center;
  height: 2rem;
  -webkit-margin-before: -0.125rem;
          margin-top: -0.125rem;
  padding: 0 1rem;
  border-radius: 3px;
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  white-space: nowrap;
}

.LogTable_btn-remove__1Xr7i {
  color: var(--white);
  background-color: var(--red);
}

.LogTable_adding-row__35e7y {
  height: 2.5rem;
  border: 1px solid #dce8ed;
}

[dir="ltr"] .LogTable_remove-icon__vN4SV {
          margin-right: 0.375rem;
}

[dir="rtl"] .LogTable_remove-icon__vN4SV {
          margin-left: 0.375rem;
}

.LogTable_remove-icon__vN4SV {
  -webkit-margin-end: 0.375rem;
  width: 0.875rem;
  height: 0.875rem

  /* stylelint-disable-next-line */
}

.LogTable_remove-icon__vN4SV svg {
    color: white;
    fill: white !important;
  }

.LogTable_adding-cell__2fn14 {
  padding: 0 1rem
}

.LogTable_adding-cell__2fn14:first-of-type {
    border-left: 1px solid #dce8ed;
  }

.LogTable_adding-cell__2fn14:last-of-type {
    border-right: 1px solid #dce8ed;
  }

.LogTable_cell-plus__2aplA {
  width: 100%;
}

.LogTable_control-cell__3hC6p {
  -webkit-padding-before: 0;
          padding-top: 0;
  -webkit-padding-after: 0;
          padding-bottom: 0;
}

@media all and (min-width: 768px) {

.LogTable_btn-add__25zUS {
    font-size: 1rem;
    line-height: 150%
}
}

.Log_wrapper__RYRBJ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: calc(100vh - 3.5rem)
}

.Log_container__2ZxNR {
  width: 100%;
  padding: 1.5rem 0.5rem 2rem;
  background-color: #f4f7f9
}

.Log_btn-save__1OCRq {
  background-color: var(--green)
}

.Log_btn-save__1OCRq p {
    color: white;
  }

.Log_btn-save__1OCRq svg {
    width: 0.9375rem;
    height: 0.5625rem;
  }

.Log_btn-cancel__22QMS svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.Log_btn-edit__UnmJs {
  background-color: var(--blue)
}

.Log_btn-edit__UnmJs p {
    color: white;
  }

.Log_btn-edit__UnmJs svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.Log_btn-remove__22n2t svg {
    width: 0.75rem;
    height: 1rem;
  }

@media all and (min-width: 768px) {

.Log_wrapper__RYRBJ {
    height: auto;
    height: initial
}

.Log_container__2ZxNR {
    padding: 2rem 3.75rem 3rem
}

.Log_header__o9gvT {
    width: 100%;
    margin: 0;
    margin: initial;
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}
}

@media all and (min-width: 1200px) {

.Log_wrapper__RYRBJ {
    padding: 0 3rem
}

.Log_container__2ZxNR {
    max-width: 84rem;
    margin: 0 auto;
    padding: 2rem 0 3rem
}

.Log_form__1M7iy {
    width: calc(100% - var(--comment-block-size));
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem
}
.Log_form-create__2h3aL {
    width: 100%
}
}

.ResearchesHeader_header__2l56G {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

[dir="ltr"] .ResearchesHeader_title__ZGTyT {
          margin-right: 0.75rem;
}

[dir="rtl"] .ResearchesHeader_title__ZGTyT {
          margin-left: 0.75rem;
}

.ResearchesHeader_title__ZGTyT {
  -webkit-margin-end: 0.75rem;
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.ResearchesHeader_buttons__84cxa {
  display: flex;
}

[dir="ltr"] .ResearchesHeader_button__2mI1O:not(:last-child) {
            margin-right: 0.5rem;
}

[dir="rtl"] .ResearchesHeader_button__2mI1O:not(:last-child) {
            margin-left: 0.5rem;
}

.ResearchesHeader_button__2mI1O:not(:last-child) {
    -webkit-margin-end: 0.5rem
  }

.ResearchesHeader_button-add__3wvMr {
  background-color: var(--blue)
}

.ResearchesHeader_button-add__3wvMr p {
    color: var(--white);
  }

.ResearchesHeader_button-add__3wvMr svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.ResearchesHeader_button-reload__1Lqrt svg {
    width: 1rem;
    height: 1.25rem;
  }

@media all and (min-width: 768px) {

.ResearchesHeader_title__ZGTyT {
    font-size: 2rem;
    line-height: 2.5rem
}

[dir="ltr"] .ResearchesHeader_button__2mI1O:not(:last-child) {
              margin-right: 1rem;
  }

[dir="rtl"] .ResearchesHeader_button__2mI1O:not(:last-child) {
              margin-left: 1rem;
  }

.ResearchesHeader_button__2mI1O:not(:last-child) {
      -webkit-margin-end: 1rem
  }
}

.ResearchesSearchTable_wrapper__zTgqO {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  width: calc(100% + 0.125rem);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden
}

.ResearchesSearchTable_table-row__3B3Vv {
  grid-template: auto / 7rem 13rem 8.5rem 6.4375rem 13rem 9.9375rem;
}

.ResearchesSearchTable_table-body-row__NNkyT {
  display: grid;
  grid-gap: 0 1.5rem;
  min-height: 2rem;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid #f0f5f8
}

.ResearchesSearchTable_table-body-row__NNkyT:hover {
    box-shadow: 0 -0.0625rem 0 0 #d2e1e7;
    border-bottom-color: #d2e1e7;
    background-color: #f3f7f9;
    cursor: pointer;
  }

.ResearchesSearchTable_table-cell__2Kcod:first-child,
  .ResearchesSearchTable_table-cell__2Kcod:nth-child(4) {
    text-align: right;
  }

.ResearchesSearchTable_footer__HIPST {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px
}

[dir="ltr"] .ResearchesSearchTable_arrow__10Lv9 {
          margin-left: 0.5625rem
}

[dir="rtl"] .ResearchesSearchTable_arrow__10Lv9 {
          margin-right: 0.5625rem
}

.ResearchesSearchTable_arrow__10Lv9 {
  -webkit-margin-start: 0.5625rem;
  width: 0.5625rem;
  height: 0.9375rem;
}

.ResearchesSearchTable_arrow-ordered__2BEyJ {
  transform: rotate(180deg);
}

.ResearchesSearchTable_button-text__XC3rD {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.875rem;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  white-space: nowrap;
}

@media all and (min-width: 768px) {

.ResearchesSearchTable_wrapper__zTgqO {
    -webkit-margin-before: 1rem;
            margin-top: 1rem
}

.ResearchesSearchTable_table-body-row__NNkyT {
    grid-gap: 0 2rem;
    padding: 0 2rem
}

.ResearchesSearchTable_footer__HIPST {
    display: flex;
    justify-content: space-between;
    align-items: center
}
}

@media all and (min-width: 992px) {

.ResearchesSearchTable_wrapper__zTgqO {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

.LaboratoryResearches_container__16rz1 {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.LaboratoryResearches_wrapper__2Re2Y {
  max-width: 71.875rem;
}

.LaboratoryResearches_header__w5npQ {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.LaboratoryResearches_filters-wrapper__17U-R {
  padding: 1.5rem;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
}

.LaboratoryResearches_empty-results__-eGDC {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

@media all and (min-width: 768px) {

.LaboratoryResearches_container__16rz1 {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.LaboratoryResearches_header__w5npQ {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.LaboratoryResearches_filters-wrapper__17U-R {
    display: flex
}

.LaboratoryResearches_empty-results__-eGDC {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}
}

.ResearchesInfo_wrapper__fXmZa {
  -webkit-padding-after: 0.5rem;
          padding-bottom: 0.5rem;
  border: 1px solid #dce8ed;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

.ResearchesInfo_title__38vaj {
  padding: 1rem;
  font-weight: 700;
  font-size: 1.3125rem;
  line-height: 116%;
  color: var(--black)
}

.ResearchesInfo_contet-row__1NKKH {
  padding: 0.75rem 1rem 1rem;
  border-bottom: 1px solid #f0f5f8
}

.ResearchesInfo_contet-row__1NKKH:first-of-type {
    border-top: 1px solid #f0f5f8;
  }

.ResearchesInfo_field-title__1MLdG {
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.08em;
  line-height: 200%;
  text-transform: uppercase;
  color: #6e8189
}

.ResearchesInfo_field-title-edit__2_16l {
  -webkit-margin-before: 0.25rem;
          margin-top: 0.25rem
}

.ResearchesInfo_field-text__8-0RW {
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.ResearchesInfo_field__196u9 {
  width: 100%;
  min-height: 2rem;
  padding: 0 0.5rem;
  border: 1px solid #d2e1e7;
  border-radius: 5px !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  background: #f3f7f9
}

.ResearchesInfo_field__196u9:hover {
    border-color: #b0c9d3;
  }

.ResearchesInfo_field__196u9:focus {
    border-color: #8fb3c1;
  }

.ResearchesInfo_field__196u9::-ms-input-placeholder {
    font-weight: 400;
  }

&::-ms-input-placeholder {
    font-weight: 400;
  }

.ResearchesInfo_field__196u9::placeholder {
    font-weight: 400;
  }

.ResearchesInfo_field-date__FwKvG {
  max-width: 7.5rem;
}

.ResearchesInfo_field-cheap__1Eq6m {
  max-width: 14.75rem;
}

.ResearchesInfo_textarea__1vC0G {
  width: 100%;
  min-height: 8rem;
  padding: 0.75rem 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  border: 1px solid #d2e1e7;
  border-radius: 5px;
  background: #f3f7f9;
  resize: none
}

.ResearchesInfo_textarea__1vC0G:hover {
    border-color: #b0c9d3;
  }

.ResearchesInfo_textarea__1vC0G:focus {
    border-color: #8fb3c1;
  }

.ResearchesInfo_field-error__1XtRE,
.ResearchesInfo_textarea-error__1qglE {
  border-color: var(--red) !important;
  background-color: #faf5f6 !important;
}

.ResearchesInfo_text-error__2Fqpe {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 145%;
  color: var(--red)
}

.ResearchesInfo_link__tjk6H {
  color: var(--blue);
  text-decoration: underline
}

.ResearchesInfo_link__tjk6H:hover {
    text-decoration: none;
  }

@media all and (min-width: 768px) {

.ResearchesInfo_wrapper__fXmZa {
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.ResearchesInfo_title__38vaj {
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 99%
}

.ResearchesInfo_contet-row__1NKKH {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 8rem 1fr;
    padding: 0.5rem 2.25rem
}

.ResearchesInfo_field-title__1MLdG {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    font-size: 0.75rem
}

.ResearchesInfo_field-title-edit__2_16l {
    text-align: right
}

.ResearchesInfo_field-text__8-0RW {
    font-size: 1.125rem;
    line-height: 134%
}

.ResearchesInfo_text-error__2Fqpe {
    -webkit-margin-before: 0;
            margin-top: 0
}
}

.MultimediaStub_btn-upload__Vlr5J {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.MultimediaStub_btn-upload__Vlr5J:hover {
    cursor: pointer;
  }

.MultimediaStub_icon__2KVcz {
  width: 4.5rem;
  height: 4.5rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

.MultimediaStub_image__-88Wn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
}

.MultimediaStub_btn-remove__34_ss {
  position: absolute;
  bottom: 1rem;
  right: 1rem
}

.MultimediaStub_btn-remove__34_ss svg {
    width: 0.75rem;
    height: 1rem;
  }

.MultimediaStub_file-input__1Ox7R {
  display: none;
}

.MultimediaStub_error__1yKaF {
  color: var(--red)
}

@media all and (min-width: 768px) {

.MultimediaStub_error__1yKaF {
    grid-area: 2 / 2
}
}

@media all and (min-width: 992px) {

.MultimediaStub_error__1yKaF {
    grid-area: initial
}
}

.ResearchImage_container__ajmGR {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-padding-before: 68.8752%;
          padding-top: 68.8752%;
  border-radius: 10px;
  overflow: hidden
}

.ResearchImage_container-empty__jHNGJ {
  border: 2px dashed #c1d1d8;
}

.ResearchImage_btn-upload__1DP6H {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.ResearchImage_btn-upload__1DP6H:hover {
    cursor: pointer;
  }

.ResearchImage_icon__1ODII {
  width: 4.5rem;
  height: 4.5rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

.ResearchImage_image__2iqjR {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
}

.ResearchImage_btn-remove__1L3cV {
  position: absolute;
  top: 1rem;
  right: 1rem
}

.ResearchImage_btn-remove__1L3cV svg {
    width: 0.75rem;
    height: 1rem;
  }

.ResearchImage_file-input__2Bdli {
  display: none;
}

.ResearchImage_error__24Y65 {
  color: var(--red)
}

@media all and (min-width: 480px) {

.ResearchImage_container__ajmGR {
    -webkit-padding-before: 0;
            padding-top: 0;
    width: 19.5rem;
    height: 19.5rem;
    margin: auto
}
}

@media all and (min-width: 768px) {

.ResearchImage_container__ajmGR {
    grid-area: 1 / 2
}

.ResearchImage_error__24Y65 {
    grid-area: 2 / 2
}
}

@media all and (min-width: 992px) {

.ResearchImage_container__ajmGR {
    grid-area: initial
}

.ResearchImage_error__24Y65 {
    grid-area: initial
}
}

.ResearchVideo_container__MqTLW {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden
}

.ResearchVideo_container-empty__39BbG {
  -webkit-padding-before: 68.8752%;
          padding-top: 68.8752%;
  border: 2px dashed #c1d1d8
}

.ResearchVideo_btn-upload__1EZwb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black)
}

.ResearchVideo_btn-upload__1EZwb:hover {
    cursor: pointer;
  }

.ResearchVideo_icon__1hTGn {
  width: 3.9375rem;
  height: 4.5rem;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

.ResearchVideo_video__d9kx9 {
  width: 100%;
}

.ResearchVideo_btn-remove__KrzHd {
  position: absolute;
  top: 1rem;
  right: 1rem
}

.ResearchVideo_btn-remove__KrzHd svg {
    width: 0.75rem;
    height: 1rem;
  }

.ResearchVideo_file-input__3xQtJ {
  display: none;
}

.ResearchVideo_error__26u1n {
  color: var(--red)
}

@media all and (min-width: 480px) {

.ResearchVideo_container__MqTLW {
    -webkit-padding-before: 0;
            padding-top: 0;
    width: 19.5rem;
    height: auto;
    margin: auto
}

.ResearchVideo_container-empty__39BbG {
    width: 19.5rem;
    height: 19.5rem
}
}

@media all and (min-width: 768px) {

.ResearchVideo_container__MqTLW {
    grid-area: 1 / 2
}

.ResearchVideo_error__26u1n {
    grid-area: 2 / 2
}
}

@media all and (min-width: 992px) {

.ResearchVideo_container__MqTLW {
    grid-area: initial
}

.ResearchVideo_error__26u1n {
    grid-area: initial
}
}

.ResearchMultimedia_wrapper__Ln5D6 {
  display: grid;
  grid-template: repeat(2, auto) / auto;
  grid-gap: 1rem 0;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem
}
@media all and (min-width: 768px) {
  .ResearchMultimedia_wrapper__Ln5D6 {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 0 1.5rem;
    justify-self: center;
    -webkit-margin-after: 0;
            margin-bottom: 0
}
}
@media all and (min-width: 1200px) {
  .ResearchMultimedia_wrapper__Ln5D6 {
    grid-template: initial;
    grid-area: 2 / 1;
    grid-gap: 1rem 0;
    justify-self: initial;
    width: 100%
}
}

.LaboratoryResearch_wrapper__30mMB {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: calc(100vh - 3.5rem)
}

.LaboratoryResearch_container__22X8b {
  width: 100%;
  padding: 1.5rem 0.5rem 2rem;
  background-color: #f4f7f9
}

.LaboratoryResearch_form__2dIXx {
  display: grid;
  grid-gap: 1rem 0
}

.LaboratoryResearch_head__2NZGA {
  grid-area: initial
}

.LaboratoryResearch_btn-save__2039y {
  background-color: var(--green)
}

.LaboratoryResearch_btn-save__2039y p {
    color: white;
  }

.LaboratoryResearch_btn-save__2039y svg {
    width: 0.9375rem;
    height: 0.5625rem;
  }

.LaboratoryResearch_btn-cancel__1Rtdm svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.LaboratoryResearch_btn-edit__3YBe4 {
  background-color: var(--blue)
}

.LaboratoryResearch_btn-edit__3YBe4 p {
    color: white;
  }

.LaboratoryResearch_btn-edit__3YBe4 svg {
    width: 0.875rem;
    height: 0.875rem;
  }

.LaboratoryResearch_btn-remove__1r4Md svg {
    width: 0.75rem;
    height: 1rem;
  }

.LaboratoryResearch_form-create__3v5br {
  width: 100% !important;
}

@media all and (min-width: 768px) {

.LaboratoryResearch_wrapper__30mMB {
    height: auto;
    height: initial
}

.LaboratoryResearch_container__22X8b {
    padding: 2rem 3.75rem 3rem
}

.LaboratoryResearch_form__2dIXx {
    grid-gap: 1.5rem
}
}

@media all and (min-width: 1200px) {

.LaboratoryResearch_wrapper__30mMB {
    padding: 0 3rem
}

.LaboratoryResearch_container__22X8b {
    max-width: 84rem;
    margin: 0 auto;
    padding: 2rem 0 3rem
}

.LaboratoryResearch_form__2dIXx {
    grid-template: auto / minmax(19.5rem, 19.5rem) 1fr;
    width: calc(100% - var(--comment-block-size));
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem
}

.LaboratoryResearch_head__2NZGA {
    grid-area: 1 / span 2
}
}

.UsersHeader_header__28SuP {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UsersHeader_title__2sCLD {
  font-size: 1.75rem;
  line-height: 116%;
  color: var(--black);
  font-weight: 700
}

.UsersHeader_button__21mHZ {
  background-color: var(--blue)
}

.UsersHeader_button__21mHZ p {
    color: var(--white);
  }

.UsersHeader_button__21mHZ svg {
    width: 0.875rem;
    height: 0.875rem;
  }

@media all and (min-width: 768px) {

.UsersHeader_title__2sCLD {
    font-size: 2rem;
    line-height: 2.5rem
}
}

.UserControlButton_button__1R2P2 {
  display: none;
  align-items: center;
  height: 2rem;
  padding: 0 0.625rem;
  border-radius: 3px;
  font-size: 0;
  color: var(--black)
}

[dir="ltr"] .UserControlButton_button__1R2P2:not(:last-child) {
            margin-right: 0.5rem
}

[dir="rtl"] .UserControlButton_button__1R2P2:not(:last-child) {
            margin-left: 0.5rem
}

.UserControlButton_button__1R2P2:not(:last-child) {
    -webkit-margin-end: 0.5rem;
  }

.UserControlButton_button-visible__3c7Cc {
  display: flex;
}

.UserControlButton_button-pen__1eRw3 {
  color: var(--white);
  background-color: var(--blue)
}

.UserControlButton_button-pen__1eRw3 svg {
    fill: var(--white);
  }

.UserControlButton_button-trash__2x_FH {
  border: 1px solid #d2e1e7;
  color: var(--black);
}

.UserControlButton_button-check-bold__30T5T {
  color: var(--white);
  background-color: var(--green);
}

.UserControlButton_button-close-bold__DDNwX {
  border: 1px solid #d2e1e7;
}

.UserControlButton_icon-pen__3qTLA {
  width: 0.75rem;
  height: 0.75rem;
}

.UserControlButton_icon-trash__nvEzI {
  width: 0.875rem;
  height: 0.875rem;
}

.UserControlButton_icon-check-bold__6rVQD {
  width: 0.875rem;
  height: 0.5625rem;
}

.UserControlButton_icon-close-bold__1BcOz {
  width: 0.625rem;
  height: 0.625rem;
}

@media all and (min-width: 768px) {

.UserControlButton_button__1R2P2 {
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
    white-space: nowrap
}

[dir="ltr"] .UserControlButton_icon__3zFhg {
            margin-right: 0.375rem
  }

[dir="rtl"] .UserControlButton_icon__3zFhg {
            margin-left: 0.375rem
  }

.UserControlButton_icon__3zFhg {
    -webkit-margin-end: 0.375rem
}
}

.UserTableHeader_header__M8Wtz {
  display: flex;
  align-items: center;
  height: 3rem
}

.UserTableHeader_buttons__k4FgH {
  display: flex;
  align-items: center;
}

.UserTableHeader_header-cell__28DmO {
  padding: 0 1rem;
}

.UserTableHeader_cell-checkbox__36F8k {
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
}

@media all and (min-width: 768px) {

.UserTableHeader_header__M8Wtz {
    height: 3.625rem
}
}

.UserTableCell_cell__19LO3 {
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.875rem;
  line-height: 114%;
  border: 1px solid #dce8ed;
  color: var(--black)
}

.UserTableCell_cell__19LO3 a {
    color: var(--blue);
  }

.UserTableCell_cell__19LO3:nth-child(2) {
    min-width: 18.75rem;
  }

.UserTableCell_cell__19LO3:nth-child(3) {
    min-width: 11.25rem;
  }

.UserTableCell_cell__19LO3:nth-child(4) {
    min-width: 18.75rem;
  }

.UserTableCell_cell__19LO3:last-child {
    min-width: 13.75rem;
  }

[dir="ltr"] .UserTableCell_cell-title__eGKFe {
  text-align: left
}

[dir="rtl"] .UserTableCell_cell-title__eGKFe {
  text-align: right
}

.UserTableCell_cell-title__eGKFe {
  font-weight: 500;
  background-color: #f9fbfc;
}

.UserTableCell_cell-checkbox__QT0aL {
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
}

.UserTableCell_cell-select__1eq6h {
  background-color: #f3f7f9;
  border-color: #d2e1e7;
}

.UserTableCell_cell-edit__11h1o {
  padding: 0;
}

.UserTableCell_field__38xrD {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  border: 0;
  font-size: 0.875rem;
  color: var(--black);
  background-color: transparent
}

.UserTableCell_field-phone__3cTQQ {
  border: 0;
  border-radius: 0;
  font-weight: 400;
}

@media all and (min-width: 768px) {

.UserTableCell_cell__19LO3 {
    height: 3rem;
    font-size: 1rem;
    line-height: 150%
}

.UserTableCell_field__38xrD {
    font-size: 1rem
}
}

.UsersTable_wrapper__1rsaa {
  border: 1px solid #dce8ed;
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02);
  border-radius: 10px;
  background-color: var(--white);
}

.UsersTable_footer__2qP_b {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px
}

@media all and (min-width: 768px) {

.UsersTable_footer__2qP_b {
    display: flex;
    justify-content: space-between;
    align-items: center
}
}

.UserFieldSet_field-title__2YWcn {
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 200%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #54656c
}

.UserFieldSet_title-wrapper__1Bz1S {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem
}

.UserFieldSet_title-wrapper__1Bz1S p {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }

.UserFieldSet_btn-generate__3bTFZ {
  white-space: nowrap;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 200%;
  color: var(--blue)
}

.UserFieldSet_field__30L3t {
  width: 100%;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  height: 3rem;
  padding: 0 1rem;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

[dir="ltr"] .UserFieldSet_field-password__1X6c6 {
          padding-left: 0
}

[dir="rtl"] .UserFieldSet_field-password__1X6c6 {
          padding-right: 0
}

.UserFieldSet_field-password__1X6c6 {
  display: flex;
  align-items: center;
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  -webkit-padding-start: 0
}

[dir="ltr"] .UserFieldSet_field-password__1X6c6 input {
            padding-left: 1rem
}

[dir="rtl"] .UserFieldSet_field-password__1X6c6 input {
            padding-right: 1rem
}

.UserFieldSet_field-password__1X6c6 input {
    width: 100%;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    height: 100%;
    -webkit-padding-start: 1rem;
    border: 0;
    font-size: 1rem;
    line-height: 150%;
    color: var(--black);
    white-space: nowrap;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

[dir="ltr"] .UserFieldSet_field-password__1X6c6 button {
            margin-left: 1rem
}

[dir="rtl"] .UserFieldSet_field-password__1X6c6 button {
            margin-right: 1rem
}

.UserFieldSet_field-password__1X6c6 button {
    -webkit-margin-start: 1rem;
  }

.UserFieldSet_field-error__3JGU2 {
  -webkit-margin-after: 0;
          margin-bottom: 0;
  border-color: var(--red);
}

.UserFieldSet_field-phone__6OwgY:hover {
    border-color: #d0dfe6;
  }

.UserFieldSet_field-phone__6OwgY:focus {
    border-color: #d0dfe6;
  }

.UserFieldSet_error__NF9KP {
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  color: var(--red);
  font-size: 0.875rem;
  line-height: 145%
}

@media all and (min-width: 768px) {

[dir="ltr"] .UserFieldSet_field-title__2YWcn {
            margin-left: auto
  }

[dir="rtl"] .UserFieldSet_field-title__2YWcn {
            margin-right: auto
  }

.UserFieldSet_field-title__2YWcn {
    -webkit-margin-start: auto;
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.UserFieldSet_btn-generate__3bTFZ {
    font-size: 1rem;
    line-height: 150%;
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial
}

.UserFieldSet_field__30L3t {
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.UserFieldSet_field-password__1X6c6 {
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.UserFieldSet_error__NF9KP {
    grid-area: auto / 2;
    -webkit-margin-before: -1rem;
            margin-top: -1rem;
    -webkit-margin-after: 0;
            margin-bottom: 0
}
}

.CreateUserModal_overlay__2JZyv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 0.5rem;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.CreateUserModal_modal__2I6HQ {
  width: 100%;
  margin: 0.625rem 0;
  background: var(--white);
  outline: none;
  overflow: auto;
  border-radius: 10px
}

.CreateUserModal_wrapper__36lh2 {
  padding: 1.125rem
}

.CreateUserModal_header__ZhXYJ {
  display: grid;
  grid-template: repeat(2, auto) / 1.25rem 1fr 1.25rem;
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.CreateUserModal_title__3mwtk {
  grid-area: 2 / span 3;
  font-size: 1.5rem;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);
}

.CreateUserModal_buttons__18YvE {
  grid-area: auto / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-margin-before: 1.5rem;
          margin-top: 1.5rem
}

.CreateUserModal_btn-close__qzI1j {
  grid-area: 1 / 3;
}

.CreateUserModal_btn-create__MVwck {
  height: 3rem;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem;
  width: 10rem;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--blue);
  border-radius: 4px
}

.CreateUserModal_btn-cancel__mvcX- {
  height: 2rem;
  padding: 0 0.625rem;
  background-color: transparent;
  color: var(--black);
  font-size: 1rem;
  font-weight: 700;
}

@media all and (min-width: 768px) {

.CreateUserModal_modal__2I6HQ {
    max-width: 41.25rem;
    margin: auto
}

.CreateUserModal_wrapper__36lh2 {
    padding: 1.25rem 2.5rem 2.5rem
}

.CreateUserModal_content__WAin2 {
    display: grid;
    grid-template: auto / 11.25rem 1fr;
    grid-gap: 1rem 2rem
}

[dir="ltr"] .CreateUserModal_header__ZhXYJ {
            margin-right: -1.25rem;
  }

[dir="rtl"] .CreateUserModal_header__ZhXYJ {
            margin-left: -1.25rem;
  }

.CreateUserModal_header__ZhXYJ {
    -webkit-margin-end: -1.25rem;
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem
}

.CreateUserModal_buttons__18YvE {
    flex-direction: row;
    align-items: center;
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}

[dir="ltr"] .CreateUserModal_btn-create__MVwck {
            margin-right: 1.5rem;
  }

[dir="rtl"] .CreateUserModal_btn-create__MVwck {
            margin-left: 1.5rem;
  }

.CreateUserModal_btn-create__MVwck {
    -webkit-margin-end: 1.5rem;
    -webkit-margin-after: 0;
            margin-bottom: 0
}
}

.Users_container__2LVjz {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.Users_wrapper__3ANht {
  max-width: 66.875rem;
}

.Users_header__2eW2w {
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.Users_empty-results__vS-lu {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

@media all and (min-width: 768px) {

.Users_container__2LVjz {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.Users_header__2eW2w {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.Users_empty-results__vS-lu {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}
}

.NotificationsFilter_filter-list__l0hd2 {
  display: flex;
  flex-wrap: wrap;
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem
}

.NotificationsFilter_filter-list-item__1gcmX {
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem
}

[dir="ltr"] .NotificationsFilter_filter-list-item__1gcmX:not(:last-child) {
            margin-right: 0.5rem
}

[dir="rtl"] .NotificationsFilter_filter-list-item__1gcmX:not(:last-child) {
            margin-left: 0.5rem
}

.NotificationsFilter_filter-list-item__1gcmX:not(:last-child) {
    -webkit-margin-end: 0.5rem;
  }

.NotificationsFilter_filter-title__1jXNQ {
  -webkit-margin-after: 0.5rem;
          margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 700
}

.NotificationsFilter_fields__14GmW {
  display: flex;
  align-items: center;
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.NotificationsFilter_separator__2Qv6g {
  display: block;
  width: 0.5rem;
  height: 0.0625rem;
  margin: 0 0.5rem;
  background-color: #d2e1e7;
}

.NotificationsFilter_field__2oexI {
  width: 7.8125rem
}

[dir="ltr"] .NotificationsFilter_field__2oexI:not(:last-of-type) {
            margin-right: 0.5rem
}

[dir="rtl"] .NotificationsFilter_field__2oexI:not(:last-of-type) {
            margin-left: 0.5rem
}

.NotificationsFilter_field__2oexI:not(:last-of-type) {
    -webkit-margin-end: 0.5rem
  }

.NotificationsFilter_field__2oexI input {
    border-radius: 3px !important;
  }

.NotificationsFilter_field-icon__yc8gn {
  width: 0.6875rem;
  height: 0.625rem;
}

.NotificationsFilter_buttons__2pXVS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap
}

[dir="ltr"] .NotificationsFilter_buttons__2pXVS:not(:last-of-type) {
            margin-right: 0.5rem
}

[dir="rtl"] .NotificationsFilter_buttons__2pXVS:not(:last-of-type) {
            margin-left: 0.5rem
}

.NotificationsFilter_buttons__2pXVS:not(:last-of-type) {
    -webkit-margin-end: 0.5rem;
  }

.NotificationsFilter_button__2HDo- {
  height: 2rem;
  padding: 0 0.625rem;
  font-size: 0.875rem;
  border-radius: 5px;
  font-weight: 500
}

.NotificationsFilter_button-submit__11Djr {
  background-color: var(--blue);
  color: var(--white);
}

.NotificationsFilter_button-clear__3eOZJ {
  display: flex;
  align-items: center;
  border: 1px solid #cddbe4;
  background: var(--white);
  color: var(--black);
}

[dir="ltr"] .NotificationsFilter_icon-clear__PMZAp {
          margin-right: 0.4375rem
}

[dir="rtl"] .NotificationsFilter_icon-clear__PMZAp {
          margin-left: 0.4375rem
}

.NotificationsFilter_icon-clear__PMZAp {
  width: 0.75rem;
  height: 0.875rem;
  -webkit-margin-end: 0.4375rem;
}

@media all and (min-width: 768px) {

[dir="ltr"] .NotificationsFilter_button__2HDo-:not(:last-of-type) {
              margin-right: 0.5rem
  }

[dir="rtl"] .NotificationsFilter_button__2HDo-:not(:last-of-type) {
              margin-left: 0.5rem
  }

.NotificationsFilter_button__2HDo-:not(:last-of-type) {
      -webkit-margin-end: 0.5rem
  }
}

@media all and (min-width: 992px) {

.NotificationsFilter_filter-list__l0hd2 {
    -webkit-margin-before: 0.25rem;
            margin-top: 0.25rem;
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.NotificationsFilter_filter-list-item__1gcmX {
    -webkit-margin-after: 0.25rem;
            margin-bottom: 0.25rem
}

.NotificationsFilter_filter-title__1jXNQ {
    position: absolute !important;
    width: 0.0625rem !important;
    height: 0.0625rem !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    overflow: hidden
}

[dir="ltr"] .NotificationsFilter_fields__14GmW {
            margin-left: auto
  }

[dir="rtl"] .NotificationsFilter_fields__14GmW {
            margin-right: auto
  }

[dir="ltr"] .NotificationsFilter_fields__14GmW {
            margin-right: 0.5rem
  }

[dir="rtl"] .NotificationsFilter_fields__14GmW {
            margin-left: 0.5rem
  }

.NotificationsFilter_fields__14GmW {
    -webkit-margin-start: auto;
    -webkit-margin-end: 0.5rem;
    -webkit-margin-after: 0;
            margin-bottom: 0
}

.NotificationsFilter_field__2oexI {
    width: 7.8125rem
}

[dir="ltr"] .NotificationsFilter_field__2oexI:not(:last-of-type) {
              margin-right: 0
  }

[dir="rtl"] .NotificationsFilter_field__2oexI:not(:last-of-type) {
              margin-left: 0
  }

.NotificationsFilter_field__2oexI:not(:last-of-type) {
      -webkit-margin-end: 0
  }
}

.PageNotificationsList_wrapper__b8vy2 {
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
  border: 1px solid #dce8ed;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02);
  overflow: hidden
}

.PageNotificationsList_header__3fa7g {
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 1rem;
  border-bottom: 1px solid #dce8ed
}

[dir="ltr"] .PageNotificationsList_check-button__3jRy8 {
          margin-left: 1rem
}

[dir="rtl"] .PageNotificationsList_check-button__3jRy8 {
          margin-right: 1rem
}

.PageNotificationsList_check-button__3jRy8 {
  display: flex;
  align-items: center;
  height: 2rem;
  -webkit-margin-start: 1rem;
  padding: 0 0.625rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--white);
  border-radius: 3px;
  background-color: var(--blue)
}

[dir="ltr"] .PageNotificationsList_check-button__3jRy8 svg {
            margin-right: 0.375rem
}

[dir="rtl"] .PageNotificationsList_check-button__3jRy8 svg {
            margin-left: 0.375rem
}

.PageNotificationsList_check-button__3jRy8 svg {
    width: 1.375rem;
    height: 0.875rem;
    -webkit-margin-end: 0.375rem;
  }

.PageNotificationsList_list__YglyW {
  -webkit-margin-after: 1rem;
          margin-bottom: 1rem;
}

.PageNotificationsList_list-item__1uZyH {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #dce8ed
}

.PageNotificationsList_list-item-unread__2GEAm {
  background-color: #f9fbfc;
}

.PageNotificationsList_actions__1_q4G {
  -webkit-margin-before: 1rem;
          margin-top: 1rem;
}

[dir="ltr"] .PageNotificationsList_content__1Kfr8 {
          margin-left: 0.75rem
}

[dir="rtl"] .PageNotificationsList_content__1Kfr8 {
          margin-right: 0.75rem
}

.PageNotificationsList_content__1Kfr8 {
  -webkit-margin-start: 0.75rem
}

.PageNotificationsList_empty-results__2BZYK {
  padding: 1rem;
  font-size: 1rem;
  line-height: 150%
}

@media all and (min-width: 768px) {

.PageNotificationsList_wrapper__b8vy2 {
    -webkit-margin-after: 1.5rem;
            margin-bottom: 1.5rem
}

.PageNotificationsList_header__3fa7g {
    padding: 0 1.75rem
}

[dir="ltr"] .PageNotificationsList_check-button__3jRy8 {
            margin-left: 1.75rem
  }

[dir="rtl"] .PageNotificationsList_check-button__3jRy8 {
            margin-right: 1.75rem
  }

.PageNotificationsList_check-button__3jRy8 {
    -webkit-margin-start: 1.75rem
}

.PageNotificationsList_list-item__1uZyH {
    padding: 1rem 1.75rem
}

[dir="ltr"] .PageNotificationsList_content__1Kfr8 {
            margin-left: 1.75rem
  }

[dir="rtl"] .PageNotificationsList_content__1Kfr8 {
            margin-right: 1.75rem
  }

.PageNotificationsList_content__1Kfr8 {
    -webkit-margin-start: 1.75rem
}

.PageNotificationsList_empty-results__2BZYK {
    padding: 1.5rem;
    font-size: 1.125rem;
    line-height: 134%
}
}

@media all and (min-width: 992px) {

.PageNotificationsList_wrapper__b8vy2 {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem
}
}

.Notifications_container__MwH0g {
  -webkit-padding-before: 1.5rem;
          padding-top: 1.5rem;
  -webkit-padding-after: 1.5rem;
          padding-bottom: 1.5rem
}

.Notifications_wrapper__2VAsA {
  max-width: 63.5rem;
}

.Notifications_wrapper-empty__19ADr {
  max-width: 48rem;
}

.Notifications_header__CLi5x {
  display: flex;
  justify-content: space-between;
  -webkit-margin-after: 1.5rem;
          margin-bottom: 1.5rem
}

.Notifications_title__1aXYK {
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 116%
}

.Notifications_filters-wrapper__1w_uR {
  padding: 1.5rem;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
}

.Notifications_menu__17sv9 {
  left: auto;
  left: initial;
  right: 0;
  min-width: 19rem;
}

.Notifications_empty-results__2UV8z {
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
}

.Notifications_load-btn__2LnBw {
  height: 3rem;
  width: 100%;
  border-radius: 4px;
  background-color: #5c899c;
  color: var(--white);
  font-weight: 700;
  font-size: 1rem
}

.Notifications_pagination-container__1QIVE {
  display: flex;
  justify-content: center;
}

@media all and (min-width: 768px) {

.Notifications_container__MwH0g {
    -webkit-padding-before: 2rem;
            padding-top: 2rem;
    -webkit-padding-after: 2rem;
            padding-bottom: 2rem
}

.Notifications_header__CLi5x {
    -webkit-margin-after: 2rem;
            margin-bottom: 2rem
}

.Notifications_title__1aXYK {
    font-size: 2rem;
    line-height: 2.5rem
}

.Notifications_filters-wrapper__1w_uR {
    display: flex
}

.Notifications_empty-results__2UV8z {
    padding: 1.5rem 2rem;
    font-size: 1.125rem;
    line-height: 134%
}

.Notifications_load-btn__2LnBw {
    height: 3.5rem;
    font-size: 1.125rem
}
}

.StatisticsBlock_block__1h71Z:not(:first-child){
    margin-top: 2.4rem
}

.StatisticsBlock_title__3Zm5N {
    font-size: 1.75rem;
    line-height: 116%;
    color: var(--black);
    font-weight: 700
  }
  .StatisticsBlock_wrapper__rOFgK{
    margin-top: 2.4rem;
    padding: 3.2rem 0 3.2rem 3.2rem;
    background-color: var(--white);
    border: 1px solid #DCE8ED;
    box-shadow: 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02), 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05);
    border-radius: 10px;
  }
  .StatisticsBlock_subtitle__Id6Jq{
    font-size: 1.5rem;
    color: var(--black);
    font-weight: 700;
    margin-bottom: 2.4rem;
  }
  .StatisticsBlock_subtitle__Id6Jq span{
    font-weight: 400;
  }
  .StatisticsBlock_point__1LysO{
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1.6rem;
  }
  .StatisticsBlock_point__1LysO:last-child{
    margin-bottom: 0;
  }
  .StatisticsBlock_point__1LysO span{
    font-weight: 400;
  }
  @media all and (min-width: 768px){
.StatisticsBlock_block__1h71Z:not(:first-child){
        margin-top:0
}
.StatisticsBlock_block__1h71Z:nth-child(3) {
    margin-top: 2.4rem
}

.StatisticsBlock_title__3Zm5N {
      font-size: 2rem;
      line-height: 2.5rem
  }
}
  

.Statistics_container__YESM9 {
    -webkit-padding-before: 2.5rem;
            padding-top: 2.5rem;
    -webkit-padding-after: 2.5rem;
            padding-bottom: 2.5rem;

}
.Statistics_wrapper__249nF {
  max-width: 86.25rem;
}
.Statistics_blocks-wrapper__153u9{
  display: block
}
@media all and (min-width: 768px) {
.Statistics_blocks-wrapper__153u9{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.4rem;
    grid-column-gap: 2.4rem;
    column-gap: 2.4rem
}

}
.Lamb2_container__237n- {
    -webkit-padding-before: 1.5rem;
            padding-top: 1.5rem;
    -webkit-padding-after: 1.5rem;
            padding-bottom: 1.5rem
  }
  
  .Lamb2_wrapper__3lHAS {
    max-width: 80rem;
  }
  .Lamb2_tables-wrapper__Vl5kU {
    display: grid
  }
  .Lamb2_header__AgKWo {
    -webkit-margin-after: 1.5rem;
            margin-bottom: 1.5rem
  }
  .Lamb2_filters-wrapper__19UGd {
    padding: 1.5rem;
    border: 1px solid #dde7ec;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0 2.5rem 3.125rem rgba(17, 46, 58, 0.12)
  }
  .Lamb2_empty-results__39l4U {
    padding: 1rem;
    background-color: var(--white);
    border: 1px solid #dce8ed;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 150%;
    color: var(--black);
    box-shadow: 0 0.5rem 1.875rem rgba(9, 26, 33, 0.05), 0 0.1875rem 0.625rem rgba(10, 27, 34, 0.02)
  }
  @media all and (min-width: 480px) {
  .Lamb2_tables-wrapper__Vl5kU {
      grid-template-columns: auto;
      grid-column-gap: 0;
      grid-column-gap: 0;
      column-gap: 0
  }
}
  @media all and (min-width: 768px) {
  
  .Lamb2_container__237n- {
      -webkit-padding-before: 2rem;
              padding-top: 2rem;
      -webkit-padding-after: 2rem;
              padding-bottom: 2rem
  }
  .Lamb2_tables-wrapper__Vl5kU {
      grid-template-columns: auto
  }
  .Lamb2_header__AgKWo {
      -webkit-margin-after: 2rem;
              margin-bottom: 2rem
  }
  .Lamb2_filters-wrapper__19UGd {
      display: flex
  }
  .Lamb2_empty-results__39l4U {
      padding: 1.5rem 2rem;
      font-size: 1.125rem;
      line-height: 134%
  }
}
  @media all and (min-width: 992px) {
  .Lamb2_tables-wrapper__Vl5kU {
      grid-template-columns: auto auto;
      grid-column-gap: 2rem;
      grid-column-gap: 2rem;
      column-gap: 2rem
  }
}
  
  
.LambSearchTable2_wrapper__24-j3 {
    -webkit-margin-before: 0.5rem;
            margin-top: 0.5rem;
    width: calc(100% + 0.125rem);
    border: 1px solid #dce8ed;
    border-radius: 10px;
    overflow: hidden
  }
  
  .LambSearchTable2_table-row__31sjW {
    grid-template: auto / 5.9375rem 5.9375rem 7.5rem 7.5rem 7.5rem 7.5rem 7.5rem 6.5625rem 7.5rem 3.125rem 6.25rem 6.25rem 6.25rem minmax(
      7.5rem,
      1fr
    );
    
  animation: LambSearchTable2_fadeIn__2yJ5Z 0.3s linear;
  }
  
  .LambSearchTable2_table-body-row__6xjyW {
    display: grid;
    grid-gap: 0 1.5rem;
    min-height: 2rem;
    align-items: center;
    padding: 0 1.5rem;
    border-bottom: 1px solid #f0f5f8
  }
  
  .LambSearchTable2_table-body-row__6xjyW:hover {
      box-shadow: 0 -0.0625rem 0 0 #d2e1e7;
      border-bottom-color: #d2e1e7;
      background-color: #f3f7f9;
      cursor: pointer;
    }
  
  .LambSearchTable2_table-cell__1Ubmq:first-child {
      text-align: right;
    }
  
  .LambSearchTable2_table-cell__1Ubmq:nth-child(2) {
      text-align: right;
    }
  
  .LambSearchTable2_table-cell__1Ubmq:nth-child(5) {
      text-align: right;
    }
  .LambSearchTable2_datepicker__3ZdKn {
    width: 100%;
    border: none;
  }
  
  .LambSearchTable2_footer__3Nk52 {
    width: 100%;
    padding: 1.5rem 2rem;
    background-color: var(--white);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
  }
  
  [dir="ltr"] .LambSearchTable2_arrow__CxIEi {
            margin-left: 0.5625rem
}
  
  [dir="rtl"] .LambSearchTable2_arrow__CxIEi {
            margin-right: 0.5625rem
}
  
  .LambSearchTable2_arrow__CxIEi {
    -webkit-margin-start: 0.5625rem;
    width: 0.5625rem;
    height: 0.9375rem;
  }
  
  .LambSearchTable2_arrow-ordered__175s5 {
    transform: rotate(180deg);
  }
  
  @media all and (min-width: 768px) {
  
  .LambSearchTable2_wrapper__24-j3 {
      -webkit-margin-before: 1rem;
              margin-top: 1rem
  }
  
  .LambSearchTable2_table-body-row__6xjyW {
      grid-gap: 0 2rem;
      padding: 0 2rem
  }
  
  .LambSearchTable2_footer__3Nk52 {
      display: flex;
      justify-content: space-between;
      align-items: center
  }
}
  
  @media all and (min-width: 992px) {
  
  .LambSearchTable2_wrapper__24-j3 {
      -webkit-margin-before: 1.5rem;
              margin-top: 1.5rem
  }
}
.LambTableTotal_wrapper__1cabX {
  -webkit-margin-before: 0.5rem;
          margin-top: 0.5rem;
  width: calc(100% + 0.125rem);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden;
  grid-row-start: 1;
  padding-bottom: 1.25rem
}
.LambTableTotal_table-wrapper__3oq2r {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

}
  .ScrollbarsCustom-Track {
  height: 0.25rem !important;
  background-color: transparent !important;
  bottom: -0.8125rem !important;
}

  .ScrollbarsCustom-Thumb {
  background-color: #c1d1d8 !important;
}

 .ScrollbarsCustom-Wrapper {
  bottom: 0 !important;
}

 .ScrollbarsCustom-Content {
  padding: 0 !important;
}


.LambTableTotal_table__a5lTD {
  width: 100%;
  overflow: hidden;
  overflow: visible;
}
.LambTableTotal_header__1xkHx {
    display: block;
    border-bottom: 1px solid #dce8ed;
    /* overflow: hidden; */
  }

  
  .LambTableTotal_row__HeAJU {
    display: grid;
    grid-gap: 0 1.5rem;
    height: 5.4375rem;
    align-items: center;
    padding: 0 1.5rem;
    background-color: #f8fbfc
  }
  .LambTableTotal_tableRow__3LLZP {
    grid-template-columns: repeat(4, 5.625rem);
    animation: LambTableTotal_fadeIn__1sn29 0.3s linear;
  }
  .LambTableTotal_cell__1Wb4q {
    font-size: 0.875rem;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);
    text-align: left;
    justify-content: space-between !important;
    justify-self: end
  }
  .LambTableTotal_cell__1Wb4q button {
      font-size: 0.875rem;
      line-height: 114%;
      font-weight: 500;
      color: var(--black);
    }
  
  .LambTableTotal_body__34YP8 {
    background-color: var(--white);
  }
  .LambTableTotal_table-body-row__nDnTa {
    display: grid;
    grid-gap: 0 1.5rem;
    min-height: 2rem;
    align-items: center;
    padding: 0 1.5rem;
    border-bottom: 1px solid #f0f5f8
  }
  .LambTableTotal_table-body-row__nDnTa:hover {
      box-shadow: 0 -0.0625rem 0 0 #d2e1e7;
      border-bottom-color: #d2e1e7;
      background-color: #f3f7f9;
      cursor: pointer;
    }
  @media all and (min-width: 480px) {
.LambTableTotal_wrapper__1cabX {
    grid-row-start: 1;
    grid-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0
}
}
  @media all and (min-width: 768px) {
.LambTableTotal_wrapper__1cabX {
    -webkit-margin-before: 1rem;
            margin-top: 1rem;
    grid-row-start: 1
}

  
  .LambTableTotal_row__HeAJU {
      grid-gap: 0 2rem;
      padding: 0 2rem
  }
  .LambTableTotal_table-body-row__nDnTa {
      grid-gap: 0 2rem;
      padding: 0 2rem
  }
}
  @media all and (min-width: 992px) {
.LambTableTotal_wrapper__1cabX {
    -webkit-margin-before: 1.5rem;
            margin-top: 1.5rem;
    grid-row-start: unset
}
}
  
/* Document
 * ========================================================================== *//**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent /* 4 */;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}/* Sections
 * ========================================================================== *//**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}/* Tabular data
 * ========================================================================== *//**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0; /* 1 */
  resize: vertical; /* 3 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -0.125rem; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 0.0625rem dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//* User interaction
 * ========================================================================== *//*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] { /* 1 */
  touch-action: manipulation; /* 2 */
}/* Accessibility
 * ========================================================================== *//**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden] {
  display: inline;
  display: initial;
}
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

:root {
  --white: #fff;
  --black: #0c1116;
  --green: #2cca7e;
  --blue: #00a6ed;
  --red: #eb4458;
  --gray: #f3f7f9;
  --dark-gray: #6e8189;
  --dark-blue: #5c899c;

  --comment-block-size: 23rem;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Lignt'), url(/static/media/pt-root-ui-light.ce7a1d74.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Regular'), url(/static/media/pt-root-ui-regular.99c150c7.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Medium'), url(/static/media/pt-root-ui-medium.3c176d75.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Bold'), url(/static/media/pt-root-ui-bold.ee168f16.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: PT Root UI, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
}

input,
button {
  font-family: PT Root UI, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  font-weight: 400;
}

input,select,textarea {
  outline: 0;
  padding: 0;
}

ol,ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* body {
  background-color: #e5e5e5;
} */

p {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent
}

button:hover {
    cursor: pointer;
  }

/* input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
} */

a {
  text-decoration: none;
}

input,
input[type='search'],
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}



