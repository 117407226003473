.container {
  padding-block-start: 16px;
  padding-block-end: 16px;
}

.title {
  composes: visually-hidden from '../../../../assets/styles/helpers.css';
}

.button-reload {
  display: flex;
  align-items: center;
  block-size: 32px;
  margin-block-end: 16px;
  padding: 0 10px;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background-color: var(--white);

  @media (--md) {
    block-size: 40px;
    padding: 0 13px;
  }

  & :global(svg) {
    inline-size: 16px;
    block-size: 12px;
    margin-inline-end: 7px;

    @media (--md) {
      inline-size: 16px;
      block-size: 20px;
    }
  }

  & :global(p) {
    font-size: 14px;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);

    @media (--md) {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

.grid {
  display: grid;
  grid-gap: 16px 0;

  @media (--lg) {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 16px;
  }
}

.cell-goats {
  min-inline-size: initial;

  &:first-child {
    min-inline-size: 300px;
  }

  &:nth-child(2),
  &:nth-child(4) {
    text-align: right;
  }
}

.cell-emr {
  &:nth-child(2) {
    min-inline-size: 300px;
  }

  &:first-child,
  &:nth-child(3) {
    text-align: right;
  }
}

.cell-logs {
  &:first-child {
    text-align: right;
  }

  &:nth-child(2) {
    min-inline-size: 300px;
  }
}

.cell-researches {
  &:first-child {
    text-align: right;
  }

  &:nth-child(2),
  &:nth-child(3) {
    min-inline-size: 300px;
  }
}
