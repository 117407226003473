.header {
  padding: 16px 24px;
  border-bottom: 1px solid #dce8ed;

  @media (--md) {
    padding: 32px;
  }
}

.title {
  font-size: 21px;
  line-height: 116%;
  color: var(--black);
  font-weight: 700;

  @media (--md) {
    font-size: 24px;
    line-height: 99%;
  }
}

.btn-add {
  block-size: 32px;
  margin: 16px 24px;
  padding: 0 12px;
  border-radius: 3px;
  background-color: var(--blue);
  font-size: 14px;
  line-height: 114%;
  color: var(--white);

  @media (--md) {
    margin: 24px 32px;
    block-size: 40px;
    font-size: 16px;
    padding: 0 16px;
  }
}

.empty-block {
  padding: 16px 24px 24px;

  @media (--md) {
    padding: 32px;
  }
}

.full-block {
  padding-block-end: 16px;
}

.empty-text {
  margin: 8px 0 0;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  @media (--md) {
    margin: 16px 0 0;
  }
}

.table {
  inline-size: 100%;
  margin: -1px 0 0;
}

.hidden-row {
  display: none;
}

.visible-row {
  display: table-row;
}

.cell {
  position: relative;
  inline-size: 50%;
  block-size: 40px;
  padding: 0;
  border: 1px solid #f0f5f8;
  font-size: 16px;
  color: var(--black);

  & :global(.react-datepicker-wrapper) {
    block-size: 100%;
    inline-size: 100%;
  }

  & :global(.react-datepicker__input-container) {
    block-size: 100%;
    inline-size: 100%;
  }
}

.head-cell {
  padding: 0 32px;
  text-align: start;
  font-weight: 500;
  background-color: #f9fbfc;

  &:last-child {
    text-align: right;
  }
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  block-size: 100%;
  padding: 0 32px;
  border: 0;
  color: var(--black);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px #d2e1e7;
    background-color: #f3f7f9;
  }

  &:focus {
    inline-size: calc(100% + 2px);
    block-size: calc(100% + 2px);
    margin-inline-start: -1px;
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 10px 20px rgba(0, 23, 33, 0.07),
      0px 6px 12px rgba(0, 23, 33, 0.07), 0px 3px 6px rgba(0, 23, 33, 0.07);
    cursor: initial;
  }

  &:disabled {
    background-color: var(--white);
    cursor: initial;

    &:hover {
      background-color: var(--white);
      box-shadow: none;
    }
  }
}

.error {
  position: absolute;
  top: -38px;
  display: flex;
  align-items: center;
  inline-size: max-content;
  block-size: 40px;
  padding: 0 14px;
  color: var(--white);
  border-radius: 5px;
  background-color: var(--dark-blue);
  z-index: 1;
  user-select: none;

  &::before {
    position: absolute;
    bottom: -6px;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--dark-blue);
  }
}

.field-weight {
  text-align: right;

  &:focus {
    text-align: initial;
  }
}

.error-date {
  left: 14px;

  &::before {
    left: 27px;
  }
}

.error-weight {
  right: 14px;

  &::before {
    right: 27px;
  }
}

.show-more-button {
  block-size: 42px;
  inline-size: calc(100% - 64px);
  padding: 0 16px;
  margin: 16px 32px 0;
  border: 1px solid var(--blue);
  border-radius: 4px;
  font-size: 16px;
  color: var(--blue);
  font-weight: 500;

  @media (--md) {
    margin: 24px 32px 16px;
  }
}
