.form {
  @media (--md) {
    display: grid;
    grid-template: auto / 180px 1fr;
    grid-column-gap: 32px;
  }
}

.btn {
  margin-block-start: 24px;
  margin-block-end: 12px;
  font-size: 16px;

  @media (--md) {
    grid-area: auto / 2;
    max-inline-size: 160px;
    margin-block-start: 32px;
    margin-block-end: 0;
    margin-inline-end: 19px;
    font-size: 18px;
  }
}
