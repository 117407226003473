.clickable {
  &:hover {
    cursor: pointer;
  }
}

.cell {
  block-size: 40px;
  padding: 6px 16px;
  border: 1px solid #dce8ed;
  font-size: 14px;
  line-height: 114%;
  font-weight: 500;
  background-color: transparent;
  vertical-align: top;

  @media (--md) {
    font-size: 16px;
    line-height: 150%;
  }

  &:last-of-type {
    inline-size: 100%;
  }
}

.control-cell {
  align-items: initial;
  block-size: 80px;
  padding-block-start: 14px;
}

.titled-cell {
  inline-size: 150px;
  min-inline-size: 150px;
  background-color: #f9fbfc;
  word-break: keep-all;

  @media (--md) {
    inline-size: 175px;
    min-inline-size: 175px;
  }
}

.checked-cell {
  vertical-align: middle;
}

.input-cell {
  padding: 0;
}

.selected-cell {
  background-color: #e9f0f3;
  border-color: #b1cbd6;
}

.date-cell {
  & > div {
    block-size: 100%;
  }

  & :global(.react-datepicker__input-container) {
    block-size: 100%;
  }
}

.field {
  display: flex;
  block-size: 100%;
  min-block-size: 100%;
  inline-size: 100%;
  padding: 6px 16px;
  border: 0;
  font-size: 14px;
  line-height: 114%;
  font-weight: 500;
  background-color: transparent;
  resize: none;
  color: var(--black);

  &::-webkit-scrollbar {
    inline-size: 0;
  }

  &:hover {
    background-color: #f3f7f9;
  }

  &:focus {
    background-color: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 10px 20px rgba(0, 23, 33, 0.07),
      0px 6px 12px rgba(0, 23, 33, 0.07), 0px 3px 6px rgba(0, 23, 33, 0.07);
    border-radius: 3px;
    cursor: initial;
  }

  @media (--md) {
    font-size: 16px;
    line-height: 150%;
  }
}

.field-date {
  block-size: 100%;
  padding: 6px 16px;
}
