.filter-list {
  display: flex;
  flex-wrap: wrap;
  margin-block-end: 8px;

  @media (--lg) {
    margin-block-end: 0;
  }
}

.filter-list-item {
  margin-block-end: 8px;

  &:not(:last-child) {
    margin-inline-end: 8px;
  }

  @media (--lg) {
    margin-block-end: 0;
  }
}

.filter-title {
  margin-block-end: 8px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 700;

  @media (--lg) {
    position: absolute !important;
    inline-size: 1px !important;
    block-size: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
  }
}

.fields {
  display: flex;
  align-items: center;
  margin-block-end: 24px;

  @media (--lg) {
    margin-inline-start: auto;
    margin-inline-end: 8px;
    margin-block-end: 0;
  }
}

.separator {
  display: block;
  inline-size: 8px;
  block-size: 1px;
  margin: 0 8px;
  background-color: #d2e1e7;
}

.field {
  inline-size: 124px;

  @media (--lg) {
    inline-size: 114px;
  }

  &:not(:last-of-type) {
    margin-inline-end: 8px;

    @media (--lg) {
      margin-inline-end: 0;
    }
  }
}

.field-icon {
  inline-size: 11px;
  block-size: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-inline-end: 8px;
  }
}

.button {
  block-size: 32px;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;

  &:not(:last-of-type) {
    @media (--md) {
      margin-inline-end: 8px;
    }
  }
}

.button-submit {
  background-color: var(--blue);
  color: var(--white);
}

.button-clear {
  display: flex;
  align-items: center;
  border: 1px solid #cddbe4;
  background: var(--white);
  color: var(--black);
}

.icon-clear {
  inline-size: 12px;
  block-size: 14px;
  margin-inline-end: 7px;
}
