.form {
  inline-size: 100%;
  background-color: var(--white);
  padding: 24px 24px 40px;

  @media (--md) {
    inline-size: 100%;
    max-inline-size: 368px;
    padding: 32px 40px;
    border-radius: 10px;
  }
}

.title {
  margin-block-end: 16px;
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
  line-height: 116%;

  @media (--md) {
    margin-block-end: 24px;
  }
}

.error {
  margin-block-end: 16px;

  @media (--md) {
    margin-block-end: 24px;
  }
}

.btn {
  font-size: 18px;

  @media (--md) {
    block-size: 56px;
  }
}
