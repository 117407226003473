.cell {
  block-size: 32px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 114%;
  border: 1px solid #dce8ed;
  color: var(--black);

  @media (--md) {
    block-size: 48px;
    font-size: 16px;
    line-height: 150%;
  }

  & :global(a) {
    color: var(--blue);
  }

  &:nth-child(2) {
    min-inline-size: 300px;
  }

  &:nth-child(3) {
    min-inline-size: 180px;
  }

  &:nth-child(4) {
    min-inline-size: 300px;
  }

  &:last-child {
    min-inline-size: 220px;
  }
}

.cell-title {
  font-weight: 500;
  text-align: start;
  background-color: #f9fbfc;
}

.cell-checkbox {
  inline-size: 48px;
  min-inline-size: 48px;
  max-inline-size: 48px;
}

.cell-select {
  background-color: #f3f7f9;
  border-color: #d2e1e7;
}

.cell-edit {
  padding: 0;
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  block-size: 100%;
  padding: 0 16px;
  border: 0;
  font-size: 14px;
  color: var(--black);
  background-color: transparent;

  @media (--md) {
    font-size: 16px;
  }
}

.field-phone {
  border: 0;
  border-radius: 0;
  font-weight: 400;
}
