.list {
  margin-inline-start: 20px;

  @media (--lg) {
    display: flex;
  }
}

.item {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-block-end: 24px;

    @media (--lg) {
      margin-block-end: 0;
      margin-inline-end: 32px;
    }
  }
}

.link {
  font-size: 16px;
  line-height: 120%;
  color: var(--white);
  font-weight: 700;
}
