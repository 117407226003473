.wrapper {
  inline-size: 100%;
  border: 1px solid #dce8ed;
  border-radius: 10px;
  background-color: var(--white);
  overflow: hidden;
}

.table-wrapper {
  overflow-x: auto;

  & :global(.ScrollbarsCustom-Track) {
    block-size: 4px !important;
    background-color: transparent !important;
    bottom: 10px !important;
  }

  & :global(.ScrollbarsCustom-Thumb) {
    background-color: #c1d1d8 !important;
  }

  & :global(.ScrollbarsCustom-Wrapper) {
    bottom: 0 !important;
  }
}

.table {
  /* inline-size: 100%; */
  background-color: var(--white);
  overflow: hidden;
}

.plus-icon {
  inline-size: 14px;
  block-size: 14px;
}

.plus-icon-filled {
  margin-inline-end: 8px;
}

.btn-add {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 114%;
  color: #8ba7b4;
  font-weight: 500;
  transition: color 0.12s ease-in-out;
  white-space: nowrap;

  &:hover {
    color: #5c899c;
  }

  @media (--md) {
    font-size: 16px;
    line-height: 150%;
  }
}

.control {
  display: flex;
  block-size: 100%;
  padding: 8px 0;

  /* stylelint-disable-next-line */
  & :global(p) {
    margin-block-start: 6px;
    margin-inline-end: 16px;
  }
}

.btn {
  display: flex;
  align-items: center;
  block-size: 32px;
  margin-block-start: -2px;
  padding: 0 16px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 114%;
  font-weight: 500;
  white-space: nowrap;
}

.btn-remove {
  color: var(--white);
  background-color: var(--red);
}

.adding-row {
  block-size: 40px;
  border: 1px solid #dce8ed;
}

.remove-icon {
  margin-inline-end: 6px;
  inline-size: 14px;
  block-size: 14px;

  /* stylelint-disable-next-line */
  & :global(svg) {
    color: white;
    fill: white !important;
  }
}

.adding-cell {
  padding: 0 16px;

  &:first-of-type {
    border-left: 1px solid #dce8ed;
  }

  &:last-of-type {
    border-right: 1px solid #dce8ed;
  }
}

.cell-plus {
  inline-size: 100%;
}

.control-cell {
  padding-block-start: 0;
  padding-block-end: 0;
}
