.wrapper {
  display: grid;
  grid-template: auto / auto 1fr auto;
  padding: 24px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #dce8ed;
  }

  @media (--xl) {
    padding: 24px 32px;
  }
}

.avatar {
  grid-area: span 2 / 1;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 48px;
  block-size: 48px;
  margin-inline-end: 16px;
  border-radius: 50%;
  border: 1px solid #baebff;
  background-color: #f1fbff;
}

.char {
  font-size: 24px;
  line-height: 99%;
  color: var(--blue);
  font-weight: 700;
}

.name {
  grid-area: 1 / 2;
  align-self: center;
  margin-block-end: 4px;
  font-size: 16px;
  line-height: 129%;
  color: var(--black);
  font-weight: 700;
}

.date {
  grid-area: 2 / 2;
  align-self: center;
  font-size: 14px;
  line-height: 114%;
  color: var(--dark-gray);
}

.comment {
  grid-area: 3 / span 3;
  margin-block-start: 16px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
}

.comment-edited {
  font-size: 14px;
  color: var(--dark-gray);
}
