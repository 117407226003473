.wrapper {
  position: relative;
  display: flex;
}

.wrapper-unread {
  &::before {
    position: absolute;
    top: -4px;
    left: -8px;
    content: '';
    display: block;
    inline-size: 8px;
    block-size: 8px;
    border-radius: 50%;
    background-color: var(--blue);
  }
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-inline-size: 48px;
  block-size: 48px;
  margin-inline-end: 12px;
  border-radius: 50%;
  border: 1px solid #baebff;
  background-color: #f1fbff;

  @media (--md) {
    margin-inline-end: 16px;
  }
}

.char {
  font-size: 21px;
  color: var(--blue);
  font-weight: 700;

  @media (--md) {
    font-size: 24px;
  }
}

.title {
  margin-block-end: 6px;
  font-size: 14px;
  line-height: 133%;

  @media (--md) {
    font-size: 16px;
    line-height: 150%;
  }
}

.date {
  font-size: 14px;
  line-height: 114%;
  color: #6e8189;
}

.name {
  font-weight: 700;
}

.link {
  font-weight: 700;
  color: var(--blue);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
