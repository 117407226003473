.fieldset {
  border: 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 8px;
}

.label {
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}

.error {
  margin-block-start: 8px;
}
