.container {
  right: 8px;
  bottom: 16px;
  left: initial;
  width: initial;
  margin-inline-start: 8px;

  @media (--sm) {
    right: 24px;
    bottom: 8px;
    padding: 0;
  }
}

.toast {
  block-size: 56px;
  margin-block-end: 16px;
  padding: 0;
  border: 1px solid var(--dark-blue);
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 15px 30px rgba(5, 26, 35, 0.07),
    0px 7px 15px rgba(0, 23, 33, 0.07);

  @media (--md) {
    block-size: 72px;
  }
}

.body {
  display: flex;
  align-items: center;
  margin: 0;
}

.info {
  display: inherit;
  align-items: inherit;
  justify-content: center;
  min-inline-size: 40px;
  inline-size: 40px;
  block-size: 100%;
  background-color: var(--dark-blue);
}

.icon {
  inline-size: 18px;
  block-size: 18px;
}

.text {
  padding-inline-start: 12px;
  padding-inline-end: 8px;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);

  @media (--md) {
    padding-inline-start: 24px;
    padding-inline-end: 20px;
    font-size: 16px;
    line-height: 150%;
  }
}

.btn {
  margin-inline-end: 8px;
  margin-block-start: 8px;

  @media (--md) {
    margin-inline-end: 12px;
    margin-block-start: 12px;
  }
}
