.header {
  display: flex;
  align-items: center;
  block-size: 56px;
  background-color: #24292c;
  color: var(--white);

  @media (--md) {
    block-size: 64px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-block {
  display: flex;
  align-items: center;
}

.right-block {
  display: flex;
  align-items: center;
}

.icon-open {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 20px;
  inline-size: 20px;
  margin-inline-start: 18px;

  & :global(svg) {
    block-size: 16px;
    inline-size: 14px;
    stroke: var(--white);
  }
}

.actions-wrapper {
  display: flex;
  align-items: center;
  margin-inline-start: 16px;
}
