.label {
  display: block;
  margin-block-end: 8px;
  font-size: 12px;
  line-height: 200%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #54656c;

  @media (--md) {
    grid-area: auto / 1;
    align-self: center;
    justify-self: end;
    margin-block-end: 0;
  }

  &:not(:first-of-type) {
    margin-block-start: 24px;

    @media (--md) {
      margin-block-start: 16px;
    }
  }
}

.field {
  grid-area: auto / 2;

  &:not(:first-of-type) {
    @media (--md) {
      margin-block-start: 16px;
    }
  }
}

.error {
  grid-area: auto / 2;
}
