.header {
  display: flex;
  align-items: center;
  block-size: 48px;

  @media (--md) {
    block-size: 58px;
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.header-cell {
  padding: 0 16px;
}

.cell-checkbox {
  inline-size: 48px;
  min-inline-size: 48px;
  max-inline-size: 48px;
}
