.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  @media (--md) {
    padding: 24px 32px;
  }
}

.title {
  font-size: 21px;
  line-height: 116%;
  color: var(--black);
  font-weight: 700;

  @media (--md) {
    font-size: 24px;
    line-height: 99%;
  }
}

.select {
  inline-size: 80px;
}

.table {
  inline-size: calc(100% + 2px);
  margin-inline-start: -1px;
  margin-inline-end: -1px;
  margin-block-end: 16px;
}

.cell {
  block-size: 40px;
  border: 1px solid #dce8ed;
  padding: 0 24px;
  font-size: 16px;
  color: var(--black);
  text-align: left;

  &:last-child {
    text-align: right;
  }

  @media (--md) {
    padding: 0 32px;
  }
}

.head-cell {
  inline-size: 50%;
  font-weight: 500;
  background-color: #f9fbfc;
}

.editable-cell {
  border: 1px solid #dce8ed;
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  block-size: 40px;
  padding: 0 24px;
  border: 0;
  text-align: right;

  &::placeholder {
    color: #7f8d94;
    opacity: 1;
  }

  @media (--md) {
    padding: 0 32px;
  }
}

.field-editable {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px #d2e1e7;
    background-color: #f3f7f9;
  }

  &:focus {
    inline-size: calc(100% + 2px);
    block-size: 42px;
    margin: -1px;
    border-radius: 3px;
    text-align: initial;
    background-color: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 10px 20px rgba(0, 23, 33, 0.07),
      0px 6px 12px rgba(0, 23, 33, 0.07), 0px 3px 6px rgba(0, 23, 33, 0.07);
    cursor: initial;
  }
}

.field-disabled {
  background-color: var(--white);

  &:hover {
    cursor: unset;
  }
}

.field-with-content {
  color: var(--black) !important;
}

.text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  block-size: 40px;
  padding: 0 24px;
  color: #7f8d94;

  @media (--md) {
    padding: 0 32px;
  }
}

.content-row {
  display: none;
}

.visible-row {
  display: table-row;
}

.btn-container {
  padding: 16px;
  border-top: 1px solid #dce8ed;

  @media (--md) {
    padding: 24px 36px;
  }
}

.add-btn {
  block-size: 32px;
  padding: 0 12px;
  border-radius: 3px;
  background-color: var(--blue);
  font-size: 14px;
  line-height: 114%;
  color: var(--white);

  @media (--md) {
    block-size: 40px;
    font-size: 16px;
    padding: 0 16px;
  }
}
