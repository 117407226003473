.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-inline-size: 80px;
  min-block-size: 80px;
  inline-size: 80px;
  block-size: 80px;
  border-radius: 50%;
  background-color: #565f64;

  @media (--md) {
    min-inline-size: 96px;
    min-block-size: 96px;
    inline-size: 96px;
    block-size: 96px;
  }

  @media (--lg) {
    min-inline-size: 176px;
    min-block-size: 176px;
    inline-size: 176px;
    block-size: 176px;
  }
}

.img {
  width: 100%;
}

.icon {
  block-size: 40px;

  @media (--md) {
    block-size: 50px;
  }

  @media (--lg) {
    block-size: 90px;
  }
}
