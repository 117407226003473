.header {
  display: flex;
  flex-direction: column;
  margin-block-end: 16px;

  @media (--md) {
    grid-area: 1 / span 2;
    inline-size: calc(100% + 72px);
    margin: 0 -36px 8px;
  }

  @media (--xl) {
    inline-size: initial;
    margin: 0;
    margin-block-end: 8px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-block-end: 16px;

  @media (--md) {
    margin-block-end: 0;
  }
}

.containder-edit {
  align-items: center;
}

.title {
  margin-inline-end: 10px;
  font-size: 22px;
  font-weight: 700;
  color: var(--black);

  @media (--md) {
    margin-inline-end: 24px;
    font-size: 26px;
  }

  & + * {
    margin-inline-end: 10px;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  inline-size: 100%;
}

.actions {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-inline-end: 8px;

    @media (--md) {
      margin-inline-end: 16px;
    }
  }
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  block-size: 40px;
  margin-inline-end: 8px;
  padding: 0 12px;
  background: var(--white);
  border: 1px solid #cddbe4;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  color: var(--black);

  @media (--md) {
    block-size: 48px;
    margin-inline-end: 16px;
    font-size: 22px;
  }

  &::placeholder {
    color: #8ba7b4;
  }
}
