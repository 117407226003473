.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;

  @media (--md) {
    padding: 12px 16px;
  }

  &:hover {
    background-color: #404a50;
    cursor: pointer;

    & .counter {
      border-color: #404a50;
    }
  }
}

.container-active {
  background-color: #404a50;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 40px;
  inline-size: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.wrapper-active {
  @media (--md) {
    background-color: #24292c;
  }
}

.icon {
  margin-block-start: -4px;
  margin-inline-start: 1px;
  inline-size: 20px;
  block-size: 20px;
  fill: var(--white) !important;
}

.menu {
  position: absolute;
  top: 62px;
  right: -48px;
  inline-size: 304px;
  margin-block-start: 2px;
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 30px 70px rgba(5, 26, 35, 0.06),
    0px 20px 40px rgba(0, 23, 33, 0.06);
  z-index: 3;

  @media (--md) {
    top: 70px;
    inline-size: 400px;
  }

  @media (--lg) {
    left: -169px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 70px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);

    @media (--md) {
      right: 78px;
    }

    @media (--lg) {
      right: auto;
      left: 200px;
    }
  }
}

.counter {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-inline-size: 22px;
  min-block-size: 22px;
  border: 2px solid #24292c;
  color: var(--white);
  font-weight: 700;
  font-size: 9px;
  background-color: var(--red);
  border-radius: 50%;

  @media (--md) {
    min-inline-size: 24px;
    min-block-size: 24px;
    border: 0;
    font-size: 12px;
  }
}

.counter-active {
  border-color: #404a50;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 46px;
  background-color: #f3f7f9;
  border-top: 1px solid #d2e1e7;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #538093;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.menu-content {
  padding: 16px 16px 30px;

  @media (--md) {
    padding: 24px 24px 40px;
  }
}

.menu-title {
  margin-block-end: 24px;
  color: var(--black);
  font-weight: 700;
  font-size: 18px;
  line-height: 134%;

  @media (--md) {
    margin-block-end: 32px;
  }
}

.list-item {
  &:not(:last-of-type) {
    margin-block-end: 24px;

    @media (--md) {
      margin-block-end: 32px;
    }
  }
}

.content {
  color: var(--black);
}
