.picker {
  position: absolute !important;
  top: 13px;
  left: 0;
  z-index: 100;
  background: #d4d2d2 !important;
  animation: fadeIn .3s linear;
  & div {
    border-color: transparent transparent #24292c !important;
   }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}