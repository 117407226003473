.container {
  @media (--md) {
    grid-area: auto / span 2;
  }
}

.wrapper {
  padding: 24px 0 8px 0;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);

  @media (--md) {
    padding: 24px 0 16px 0;
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: 21px;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);
}

.textarea {
  inline-size: 100%;
  margin-block-start: 8px;
  padding: 8px 24px;
  border: 1px solid transparent;
  resize: none;

  @media (--md) {
    padding: 16px 24px;
  }

  &:hover {
    background-color: #f3f7f9;
    border-color: #d2e1e7;
  }

  &:focus {
    inline-size: calc(100% - 16px);
    margin-inline-start: 8px;
    margin-inline-end: 8px;
    padding: 8px 16px;
    border-radius: 3px;
    background-color: var(--white);
    border-color: transparent;
    box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px -2px 6px rgba(0, 23, 33, 0.07),
      0px 1px 1px rgba(0, 23, 33, 0.07), 0px 3px 3px rgba(0, 23, 33, 0.07);
    cursor: initial;

    @media (--md) {
      padding: 16px;
    }
  }

  &:placeholder {
    color: #7f8d94;
  }
}

.text {
  padding: 0 24px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  @media (--md) {
    padding: 0 24px 8px;
  }
}

.text-full {
  margin-block-start: 16px;

  @media (--md) {
    margin-block-start: 24px;
  }
}

.text-empty {
  margin-block-start: 8px;

  @media (--md) {
    margin-block-start: 16px;
  }
}
