.wrapper {
  max-inline-size: 960px;

  @media (--lg) {
    padding: 0;
  }
}

.grid {
  display: grid;
  grid-gap: 16px;
  padding-block-end: 24px;

  @media (--md) {
    grid-gap: 24px;
    padding-block-end: 32px;
  }

  @media (--lg) {
    grid-template: auto / 272px 1fr;
  }
}
