.wrapper {
  margin-block-end: 16px;
  border: 1px solid #dce8ed;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0px 8px 30px rgba(9, 26, 33, 0.05), 0px 3px 10px rgba(10, 27, 34, 0.02);
  overflow: hidden;

  @media (--md) {
    margin-block-end: 24px;
  }

  @media (--lg) {
    margin-block-start: 24px;
  }
}

.header {
  display: flex;
  align-items: center;
  block-size: 56px;
  padding: 0 16px;
  border-bottom: 1px solid #dce8ed;

  @media (--md) {
    padding: 0 28px;
  }
}

.check-button {
  display: flex;
  align-items: center;
  block-size: 32px;
  margin-inline-start: 16px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--white);
  border-radius: 3px;
  background-color: var(--blue);

  @media (--md) {
    margin-inline-start: 28px;
  }

  & :global(svg) {
    inline-size: 22px;
    block-size: 14px;
    margin-inline-end: 6px;
  }
}

.list {
  margin-block-end: 16px;
}

.list-item {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #dce8ed;

  @media (--md) {
    padding: 16px 28px;
  }
}

.list-item-unread {
  background-color: #f9fbfc;
}

.actions {
  margin-block-start: 16px;
}

.content {
  margin-inline-start: 12px;

  @media (--md) {
    margin-inline-start: 28px;
  }
}

.empty-results {
  padding: 16px;
  font-size: 16px;
  line-height: 150%;

  @media (--md) {
    padding: 24px;
    font-size: 18px;
    line-height: 134%;
  }
}
