.wrapper {
  position: relative;
  margin-inline-start: 10px;
}

.dots {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.dot {
  display: block;
  min-inline-size: 5px;
  min-block-size: 5px;
  background-color: var(--black);
  border-radius: 50%;

  &:not(:last-of-type) {
    margin-inline-end: 4px;
  }
}

.list {
  position: absolute;
  right: -16px;
  inline-size: max-content;
  margin-block-start: 2px;
  padding: 10px 0;
  border-radius: 3px;
  background-color: #24292c;
  box-shadow: 0 6px 10px 2px rgba(134, 134, 134, 0.2);

  @media (--xl) {
    right: -24px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 22px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #24292c;

    @media (--xl) {
      right: 30px;
    }
  }
}

.btn {
  inline-size: 100%;
  block-size: 28px;
  padding: 0 17px;
  font-size: 14px;
  color: var(--white);
  text-align: start;

  &:hover {
    background-color: var(--blue);
  }
}
