.body {
  background-color: var(--white);
}

.cell {
  padding: 16px 24px;
  border-bottom: 1px solid #f0f5f8;
  font-size: 18px;
  line-height: 134%;
  color: var(--black);

  @media (--md) {
    padding: 24px 32px;
  }
}
