.field {
  composes: truncate from '../../assets/styles/helpers.css';
  block-size: 48px;
  inline-size: 100%;
  padding: 0 16px;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  transition: border-color ease-in-out 0.2s;

  &:hover {
    border-color: #b0c9d3;
  }

  &:focus {
    border-color: #8fb3c1;
  }
}

.field-error {
  border-color: var(--red) !important;
}

.error {
  font-size: 14px;
  line-height: 145%;
  color: var(--red);
}
