.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 32px;
  inline-size: 32px;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background-color: var(--white);
  white-space: nowrap;

  @media (--md) {
    block-size: 40px;
    inline-size: initial;
    padding: 0 14px;
  }
}

.text {
  font-size: 0;

  @media (--md) {
    margin-inline-start: 9px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: var(--black);
  }
}
