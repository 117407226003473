.header {
  display: flex;
  flex-direction: column;
  margin-block-end: 16px;

  @media (--md) {
    grid-area: 1 / span 2;
    inline-size: calc(100% + 72px);
    margin: 0 -36px 8px;
  }

  @media (--xl) {
    inline-size: initial;
    margin: 0;
    margin-block-end: 8px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-block-end: 16px;

  @media (--md) {
    margin-block-end: 0;
  }
}

.title {
  margin-inline-end: 10px;
  font-size: 28px;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);

  @media (--md) {
    margin-inline-end: 24px;
    font-size: 32px;
    line-height: 40px;
  }

  & + * {
    margin-inline-end: 10px;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
}

.actions {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-inline-end: 8px;

    @media (--md) {
      margin-inline-end: 16px;
    }
  }
}
