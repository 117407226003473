.wrapper {
  padding: 26px;
  border-bottom: 1px solid #dce8ed;
}

.comment {
  border: 1px solid #d0dfe6;
  border-radius: 5px;
  background: #f3f8fa;
  padding: 18px 24px;
}

.text {
  margin-block-end: 2px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
}

.button {
  font-size: 16px;
  line-height: 150%;
  color: var(--blue);
}
