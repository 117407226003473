.container {
  inline-size: 100%;
  padding: 0 8px;

  @media (--md) {
    padding: 0 16px;
  }
}

.wrapper {
  max-inline-size: 1530px;
  margin: 0 auto;
}
