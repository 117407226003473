.wrapper {
  background-color: var(--white);
  border-top: 1px solid #dce8ed;

  @media (--md) {
    margin: 0 128px 48px;
    border: 1px solid #dce8ed;
    overflow-y: scroll;
  }

  @media (--xl) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: 0;
    border-left: 1px solid #dce8ed;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.wrapper-open {
  @media (--xl) {
    inline-size: 368px;
  }
}

.wrapper-close {
  display: flex;
  justify-content: center;
  min-block-size: 60px;
  align-items: center;

  @media (--xl) {
    align-items: initial;
    inline-size: 80px;
    block-size: initial;
    padding-block-start: 28px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  block-size: 73px;
  padding: 0 24px;
  border-bottom: 1px solid #dce8ed;

  @media (--xl) {
    padding: 0 32px;
  }
}

.title {
  font-size: 21px;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);

  @media (--xl) {
    font-size: 18px;
    line-height: 134%;
  }
}

.open-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-icon {
  inline-size: 15px;
  block-size: 12px;
}

.open-icon {
  inline-size: 20px;
  block-size: 20px;
}

.open-text {
  margin-block-start: 5px;
  font-size: 9px;
  line-height: 114%;
}

.form {
  padding: 24px;
  border-bottom: 1px solid #dce8ed;

  @media (--xl) {
    padding: 24px 32px;
  }
}

.user-block {
  display: flex;
  align-items: center;
  margin-block-end: 16px;
}

.user-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 48px;
  block-size: 48px;
  margin-inline-end: 16px;
  border-radius: 50%;
  border: 1px solid #baebff;
  background-color: #f1fbff;
}

.user-char {
  font-size: 24px;
  line-height: 99%;
  color: var(--blue);
  font-weight: 700;
}

.user-name {
  font-size: 16px;
  line-height: 129%;
  color: var(--black);
  font-weight: 700;
}

.textarea {
  inline-size: 100%;
  max-block-size: 200px;
  border: 0;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  resize: none;

  &::placeholder {
    color: var(--dark-gray);
  }

  &::-webkit-scrollbar {
    inline-size: 0;
  }

  @media (--xl) {
    font-size: 16px;
    line-height: 150%;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  margin-block-start: 24px;
}

.btn {
  padding: 0 10px;
  block-size: 32px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 500;

  &:not(:last-child) {
    margin-inline-end: 9px;
  }
}

.btn-cancel {
  border: 1px solid #cddbe4;
  color: var(--black);
}

.btn-publish {
  color: var(--white);
  background-color: var(--blue);
}
