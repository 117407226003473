.header {
  display: block;
  border-bottom: 1px solid #dce8ed;
  /* overflow: hidden; */
}

.row {
  display: grid;
  grid-gap: 0px 24px;
  block-size: 87px;
  align-items: center;
  padding: 0px 24px;
  background-color: #f8fbfc;

  @media (--md) {
    grid-gap: 0px 32px;
    padding: 0px 32px;
  }
}
