.note {
  width: 100%;
  resize: none;
  border: none;
  background-color: inherit;
  font-family: inherit;
  color: inherit;
}


