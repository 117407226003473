.modal {
  max-inline-size: 368px;
  padding: 24px;
  border-radius: 10px;

  @media (--md) {
    padding: 32px;
  }
}

.btn {
  margin-block-start: -10px;
  margin-inline-end: -10px;
}

.text {
  font-size: 18px;
  line-height: 133%;
  color: var(--black);
  font-weight: 500;

  @media (--md) {
    font-size: 24px;
  }
}
