.button {
    block-size: 32px;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 500;
  
    &:not(:last-of-type) {
      @media (--md) {
        margin-inline-end: 8px;
      }
    }
  }
  
  .button-show {
    background-color: var(--blue);
    color: var(--white);
  }