.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 100vh;
  block-size: calc(100vh - 56px);

  @media (--md) {
    block-size: initial;
  }

  @media (--xl) {
    padding: 0 48px;
  }
}

.container {
  inline-size: 100%;
  padding: 24px 8px 32px;
  background-color: #f4f7f9;

  @media (--md) {
    padding: 32px 60px 48px;
  }

  @media (--xl) {
    max-inline-size: 1344px;
    margin: 0 auto;
    padding: 32px 0 48px;
  }
}

.form {
  display: grid;
  grid-gap: 16px 0;

  @media (--md) {
    grid-gap: 24px;
  }

  @media (--xl) {
    grid-template: auto / minmax(312px, 312px) 1fr;
    inline-size: calc(100% - var(--comment-block-size));
    margin-block-end: 8px;
  }
}

.head {
  grid-area: initial;

  @media (--xl) {
    grid-area: 1 / span 2;
  }
}

.btn-save {
  background-color: var(--green);

  & :global(p) {
    color: white;
  }

  & :global(svg) {
    inline-size: 15px;
    block-size: 9px;
  }
}

.btn-cancel {
  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.btn-edit {
  background-color: var(--blue);

  & :global(p) {
    color: white;
  }

  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.btn-remove {
  & :global(svg) {
    inline-size: 12px;
    block-size: 16px;
  }
}

.form-create {
  inline-size: 100% !important;
}
