.wrapper {
  inline-size: max-content;
  margin-block-start: 10px;
  margin-inline-start: auto;
  display: flex;
  align-items: center;

  @media (--md) {
    margin-block-start: 0;
    margin-inline-start: initial;
  }
}

.text {
  margin-inline-end: 10px;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
}

.select {
  inline-size: 66px;
}
