.wrapper {
  position: relative;
  z-index: 1;
}

.text {
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
  font-weight: 500;
}

.menu {
  top: 0;
  left: 0;
  position: absolute;
  max-inline-size: 395px;
  inline-size: 100%;
  padding: 16px 24px 24px;
  border: 1px solid #dce8ed;
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0px 40px 50px rgba(17, 46, 58, 0.12);
}

.button-open {
  display: flex;
  align-items: center;
  block-size: 32px;
  padding: 0 10px;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background: var(--white);
}

.button-close {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  padding: 8px;
}

.filter-icon {
  inline-size: 12px;
  block-size: 12px;
  margin-inline-end: 6px;
}

.close-icon {
  inline-size: 12px;
  block-size: 12px;
}
