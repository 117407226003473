.button {
  block-size: 32px;
  padding: 0 12px;
  border: 1px solid #d2e1e7;
  border-radius: 3px;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
  background-color: var(--white);
}

.button-selected {
  border: 0;
  background: #5c899c;
  color: var(--white);
  font-weight: 500;
}

.checkbox {
  display: none;
}

.icon {
  inline-size: 8px;
  block-size: 8px;
  margin-inline-start: 8px;
}
