.btn {
  inline-size: 100%;
  block-size: 48px;
  background-color: var(--blue);
  color: var(--white);
  font-weight: 700;
  border-radius: 4px;

  &[disabled]:hover {
    cursor: not-allowed;
  }
}

.submitting {
  padding: 6px 0;
}

.img {
  block-size: 100%;
}
