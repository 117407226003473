.modal {
  max-inline-size: 416px;
  border-radius: 10px;
}

.content {
  padding: 18px 18px 44px;

  @media (--md) {
    padding-block-end: 57px;
  }
}

.header {
  display: grid;
  grid-template: repeat(2, auto) / 20px 1fr 20px;
  margin-block-end: 16px;

  @media (--md) {
    font-size: 24px;
    line-height: 99%;
  }
}

.title {
  grid-area: 2 / 2;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  line-height: 116%;
  color: var(--black);
}

.text {
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  color: var(--black);

  @media (--md) {
    font-size: 16px;
    line-height: 150%;
  }
}

.btn-close {
  grid-area: 1 / 3;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  block-size: 80px;
  border-top: 1px solid #d2e1e7;
  background-color: #f3f7f9;

  @media (--md) {
    block-size: 95px;
  }
}

.btn-accept {
  block-size: 40px;
  margin-block-start: -20px;
  margin-block-end: 8px;
  padding: 0 16px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: var(--white);
  background-color: var(--blue);

  @media (--md) {
    block-size: 48px;
    margin-block-start: -24px;
    margin-block-end: 16px;
    padding: 0 24px;
  }
}

.btn-accept-danger {
  background-color: var(--red);
}

.btn-decline {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: var(--black);
  background-color: transparent;
}
