.wrapper {
  margin-right: 16px;
  margin-block-start: 8px;
  width: calc(100% + 2px);
  border: 2px solid black;
  overflow: visible;

  @media (--md) {
    margin-block-start: 16px;
  }

  @media (--lg) {
    margin-block-start: 24px;
  }
}

.preHead {
  padding: 0 24px;
  height: 60px;
  display: grid;
  grid-template: auto / 95px 85px 80px 105px 128px 100px 100px 100px 82px 110px 75px 75px 65px 70px 70px 75px minmax(
    120px,
    1fr
  );
  font-weight: 700;
  background: rgb(145, 255, 0);
  border-bottom: 2px solid black;
}

.preHead th {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
}

.year {  
  grid-column: 1 / span 6;
}

.today {
  grid-column: 7 / span 4;
  background: white;
}

.gland {
  grid-column: 11 / span 2;
}

.child {
  grid-column: 13 / span 4;
}

.datepicker {
  width: 100%;
  border: none;
  background-color: inherit;
  color: inherit;
}

.table-row {
  grid-template: auto / 95px 85px 80px 105px 128px 100px 100px 100px 82px 110px 75px 75px 65px 70px 70px 75px minmax(
      120px,
      1fr
    );
  animation: fadeIn 0.3s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.table-body-row {
  display: grid;
  /*grid-gap: 0px 24px;*/
  min-block-size: 32px;
  align-items: center;
  padding: 0px 24px;
  border-bottom: 1px solid black;
  transition: box-shadow 0.2s linear;
  @media (--md) {

  }

  &:hover {
    /* box-shadow: 1px -1px 25px 2px rgba(0, 0, 0, 0.75); */
    cursor: pointer;
  }
}

.table-cell {
  position: relative;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  width: 100%;
  color: inherit;

  &:not(:last-child) {
    border-right: 1px solid black;
  }

  &:first-child {
    justify-content: center;
  }

  /*&:nth-child(2) {*/
  /*  text-align: right;*/
  /*}*/

  /*&:nth-child(5) {*/
  /*  text-align: right;*/
  /*}*/

  &:last-child {
    align-items: center;
    justify-content: center;
  }
}

.footer {
  inline-size: 100%;
  padding: 24px 32px;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  /* @media (--md) { */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* } */
}

.arrow {
  margin-inline-start: 9px;
  inline-size: 9px;
  block-size: 15px;
}

.arrow-ordered {
  transform: rotate(180deg);
}

.button {
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: 500;
  background-color: var(--blue);
  color: var(--white);
}

.button-add {
  width: 30px;
  height: 30px;
  background-color: var(--blue);
  text-align: center;

  &:global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}
