.wrapper {
  position: relative;
  display: flex;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  background-color: var(--white);
  overflow: hidden;
  transition: border-color ease-in-out 0.2s;

  &:hover {
    border-color: #b0c9d3;
  }
}

.wrapper-focused {
  border-color: #8fb3c1;

  &:hover {
    border-color: #8fb3c1;
  }
}

.wrapper-error {
  border-color: var(--red);
  background-color: #faf5f6;

  &:hover {
    border-color: var(--red);
  }
}

.field {
  composes: truncate from '../../assets/styles/helpers.css';
  block-size: 48px;
  inline-size: 100%;
  padding-inline-start: 16px;
  padding-inline-end: 55px;
  border: 0;
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  background-color: transparent;
}

.btn {
  position: absolute;
  right: 0;
  block-size: 100%;
  border: 0;
  background-color: transparent;
}

.icon {
  block-size: 23px;
  inline-size: 23px;
  margin: 0 16px;
  fill: #95b4c1 !important;
}

.error {
  font-size: 14px;
  line-height: 145%;
  color: var(--red);
}
