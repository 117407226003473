.container {
  padding-block-start: 24px;
  padding-block-end: 24px;

  @media (--md) {
    padding-block-start: 32px;
    padding-block-end: 32px;
  }
}

.wrapper {
  max-inline-size: 1070px;
}

.header {
  margin-block-end: 24px;

  @media (--md) {
    margin-block-end: 32px;
  }
}

.empty-results {
  padding: 16px;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0px 8px 30px rgba(9, 26, 33, 0.05), 0px 3px 10px rgba(10, 27, 34, 0.02);

  @media (--md) {
    padding: 24px 32px;
    font-size: 18px;
    line-height: 134%;
  }
}
