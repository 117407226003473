.wrapper {
  padding-block-end: 24px;

  @media (--md) {
    padding-block-end: 40px;
  }

  @media (--lg) {
    grid-area: 2 / 2;
  }
}

.form {
  @media (--md) {
    display: grid;
    grid-template: auto / 180px 1fr;
    grid-column-gap: 32px;
  }
}

.error {
  grid-area: auto / 2;
  margin-block-start: -4px;
  margin-block-end: 16px;
  font-size: 14px;
  line-height: 145%;
  color: var(--red);

  @media (--md) {
    margin-block-start: -16px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 24px;

  @media (--md) {
    grid-area: auto / 2;
    flex-direction: row;
    margin-block-start: 32px;
  }
}

.btn {
  font-size: 16px;

  @media (--md) {
    max-inline-size: 160px;
    font-size: 18px;
  }
}
