.input {
  display: none;
}

.btn {
  block-size: 48px;
  border: 1px dashed #a0b5bf;
  border-radius: 4px;
}

.label {
  display: flex;
  align-items: center;
  block-size: 100%;
  padding: 0 16px;
  font-size: 16px;
  color: #54656c;
  font-weight: 700;

  @media (--md) {
    padding: 0 24px;
    font-size: 18px;
  }

  &:hover {
    cursor: pointer;
  }
}

.icon {
  inline-size: 21px;
  block-size: 19px;
  margin-block-start: -5px;
  margin-inline-end: 15px;

  @media (--md) {
    margin-block-start: -3px;
    margin-inline-end: 10px;
  }
}
