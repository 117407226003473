.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 20px;
  block-size: 20px;
  margin-inline-start: auto;
  border: 0;
  background-color: transparent;
}

.icon {
  inline-size: 12px;
  block-size: 12px;
}
