.wrapper {
  position: relative;
  block-size: 32px;

  & :global(.react-datepicker-wrapper) {
    block-size: 100%;
  }

  & :global(.react-datepicker__input-container) {
    block-size: 100%;
  }

  /* stylelint-disable-next-line */
  & :global(input) {
    /* composes: truncate from '../../../../assets/styles/helpers.css'; */
    block-size: 100%;
    inline-size: 100%;
    border-radius: 3px;
    border: 1px solid #d2e1e7;
    padding: 0 10px 0 30px;
    font-size: 14px;
    color: var(--black);
    background-color: var(--white);
  }
}

.icon-wrapper {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 30px;
  block-size: 100%;
  z-index: 1;

  & + div {
    block-size: 100%;
  }
}
