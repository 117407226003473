.wrapper{
  position: relative;
}
.row {
    display: grid;
    grid-template: auto / auto;
    padding: 11px 16px 17px;
    border-bottom: 1px solid #f0f5f8;
  
    @media (--md) {
      grid-template: auto / 128px auto;
      grid-gap: 0 36px;
      padding: 8px 32px;
    }
  }
  
  .cell {
    inline-size: 100%;
  
    & :global(.react-datepicker-wrapper) {
      inline-size: inherit;
  
      & :local(.field) {
        border-radius: 5px;
      }
    }
  }
  
  .row-title {
    display: block;
    margin-block-end: 4px;
    font-size: 10px;
    line-height: 200%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-weight: 500;
    text-align: start;
    color: var(--dark-gray);
  
    @media (--md) {
      align-self: center;
      min-inline-size: 128px;
      margin-block-end: 0;
      font-size: 12px;
      text-align: end;
    }
  }
  
  .row-read {
    text-align: start;
  
    @media (--md) {
      text-align: start;
    }
  }
  .button {
    block-size: 32px;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 500;
  
    &:not(:last-of-type) {
      @media (--md) {
        margin-inline-end: 8px;
      }
    }
  }
  
  .button-show {
    background-color: var(--blue);
    color: var(--white);
  }
  .disabled{
    opacity: 0.5;
    pointer-events: none
  }
  .saved{
    background-color: var(--green);
  }
  .error {
    color: var(--red);
  
    @media (--md) {
      grid-area: 2 / 2;
    }
  
    @media (--lg) {
      grid-area: initial;
    }
  }
  .btn-remove {
    position: absolute;
    bottom: 16px;
    right: 32px;
  
    & svg {
      inline-size: 12px;
      block-size: 16px;
    }
  }
  .red{
    background-color: var(--red);
  }
  .image-wrapper{
    flex-shrink: 0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .date{
    position: absolute;
    left: 10px;
    top: 10px;
    color: var(--black);
    display: inline-block;
    border-radius: 5px;
    padding: 2px 5px;
    background: var(--gray);
    opacity: 0.8;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      font-size: 12px
    }
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .preview-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 80px);
    column-gap: 2px;
    row-gap: 2px;
    margin-top: 10px;
    @media screen and (min-width: 320px) and (max-width: 480px){
      grid-template-columns: repeat(3, 80px);
    }
  }
  .preview{
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .preview-inner{
    border-radius: 12px;
    overflow: hidden;
    position: relative;
  }
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .icon-wrapper{
    width: 25px;
    height: 25px;
    background-color: var(--gray);
    border-radius: 50%;
    position: absolute;
    right: 2px;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.7;
  }
  .close-icon {
  inline-size: 10px;
  block-size: 13px;
  fill: var(--black) !important;
  }
  .preview-date{
    font-size: 12px;
  }