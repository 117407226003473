.wrapper {
  border: 1px solid #dce8ed;
  box-shadow: 0px 8px 30px rgba(9, 26, 33, 0.05), 0px 3px 10px rgba(10, 27, 34, 0.02);
  border-radius: 10px;
  background-color: var(--white);
}

.footer {
  inline-size: 100%;
  padding: 24px 32px;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (--md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
