.container {
  position: relative;
  display: flex;
  align-items: center;
  margin-inline-end: -8px;
  padding: 8px;

  @media (--md) {
    margin-inline-end: -16px;
    padding: 12px;
  }

  &:hover {
    background-color: #404a50;
    cursor: pointer;
  }
}

.container-active {
  background-color: #404a50;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 40px;
  inline-size: 40px;
  border-radius: 50%;
  background-color: #565f64;
  overflow: hidden;

  @media (--md) {
    margin-inline-end: 16px;
  }
}

.image-wrapper-active {
  @media (--md) {
    background-color: #24292c;
  }
}

.image {
  inline-size: 100%;
}

.user-icon {
  margin-block-start: -4px;
  margin-inline-start: 1px;
  inline-size: 17px;
  block-size: 22px;
}

.user-name {
  composes: truncate from '../../../../assets/styles/helpers.css';
  display: flex;
  align-items: center;
  max-inline-size: 165px;
  font-size: 16px;
  line-height: 150%;
  color: var(--white);
  font-weight: 700;
}

.triangle {
  margin-inline-start: 16px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--white);
}

.triangle-active {
  transform: rotate(180deg);
}

.list {
  position: absolute;
  top: 62px;
  right: 8px;
  inline-size: 150px;
  margin-block-start: 2px;
  padding: 10px 0;
  border-radius: 5px;
  background-color: #24292c;
  box-shadow: 0 6px 10px 2px rgba(134, 134, 134, 0.2);
  z-index: 3;

  @media (--md) {
    top: 70px;
  }

  @media (--lg) {
    right: auto;
    left: 8px;
    inline-size: calc(100% - 16px);
  }

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 16px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #24292c;

    @media (--md) {
      right: 20px;
    }

    @media (--lg) {
      right: auto;
      left: 20px;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  inline-size: 100%;
  block-size: 28px;
  padding: 0 17px;
  font-size: 14px;
  color: var(--white);
  text-align: start;
  font-weight: 500;

  &:hover {
    background-color: var(--blue);
  }
}
