.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 8px;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.modal {
  inline-size: 100%;
  margin: 10px 0;
  background: var(--white);
  outline: none;
  overflow: auto;
  border-radius: 10px;

  @media (--md) {
    max-inline-size: 660px;
    margin: auto;
  }
}

.wrapper {
  padding: 18px;

  @media (--md) {
    padding: 20px 40px 40px;
  }
}

.content {
  @media (--md) {
    display: grid;
    grid-template: auto / 180px 1fr;
    grid-gap: 16px 32px;
  }
}

.header {
  display: grid;
  grid-template: repeat(2, auto) / 20px 1fr 20px;
  margin-block-end: 24px;

  @media (--md) {
    margin-inline-end: -20px;
    margin-block-end: 40px;
  }
}

.title {
  grid-area: 2 / span 3;
  font-size: 24px;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);
}

.buttons {
  grid-area: auto / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 24px;

  @media (--md) {
    flex-direction: row;
    align-items: center;
    margin-block-start: 24px;
  }
}

.btn-close {
  grid-area: 1 / 3;
}

.btn-create {
  block-size: 48px;
  margin-block-end: 12px;
  inline-size: 160px;
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  background-color: var(--blue);
  border-radius: 4px;

  @media (--md) {
    margin-inline-end: 24px;
    margin-block-end: 0;
  }
}

.btn-cancel {
  block-size: 32px;
  padding: 0 10px;
  background-color: transparent;
  color: var(--black);
  font-size: 16px;
  font-weight: 700;
}
