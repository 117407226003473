.wrapper {
  display: flex;
}

.field {
  display: none;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 24px;
  inline-size: 24px;
  margin-inline-end: 12px;
  border-radius: 4px;
  border: 1px solid #d0dfe6;
  background-color: transparent;
}

.checkbox-checked {
  border-color: var(--blue);
}

.checkmark {
  block-size: 10px;
  inline-size: 17px;
  margin-block-start: 1px;
}

.label {
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
}
