.wrapper {
  display: grid;
  grid-template: repeat(2, auto) / auto;
  grid-gap: 16px 0;
  margin-block-end: 12px;

  @media (--md) {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 0 24px;
    justify-self: center;
    margin-block-end: 0;
  }

  @media (--xl) {
    grid-template: initial;
    grid-area: 2 / 1;
    grid-gap: 16px 0;
    justify-self: initial;
    inline-size: 100%;
  }
}
