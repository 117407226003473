.block:not(:first-child){
    margin-top: 2.4rem;
    @media (--md) {
        margin-top:0;
    }
}
.block:nth-child(3) {
  @media (--md) {
    margin-top: 2.4rem;
  }
}

.title {
    font-size: 28px;
    line-height: 116%;
    color: var(--black);
    font-weight: 700;
  
    @media (--md) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .wrapper{
    margin-top: 2.4rem;
    padding: 3.2rem 0 3.2rem 3.2rem;
    background-color: var(--white);
    border: 1px solid #DCE8ED;
    box-shadow: 0px 3px 10px rgba(10, 27, 34, 0.02), 0px 8px 30px rgba(9, 26, 33, 0.05);
    border-radius: 10px;
  }
  .subtitle{
    font-size: 24px;
    color: var(--black);
    font-weight: 700;
    margin-bottom: 2.4rem;
  }
  .subtitle span{
    font-weight: 400;
  }
  .point{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1.6rem;
  }
  .point:last-child{
    margin-bottom: 0;
  }
  .point span{
    font-weight: 400;
  }
  
