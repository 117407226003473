.react-datepicker {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 20px 50px rgba(5, 26, 35, 0.07),
    0px 15px 25px rgba(0, 23, 33, 0.07);
  border-radius: 6px;
  border: 0;
  padding: 0 16px;
  z-index: 10;
  color: inherit;
}

.react-datepicker__header {
  padding: 8px 0;
  background: var(--white);
  border-bottom: 1px solid #dce8ed;
}

.react-datepicker__month {
  margin: 10px 0;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__navigation {
  display: none;
  inline-size: initial;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  z-index: 2;

  &[data-placement^='bottom'] {
    margin-top: 0;
  }

  &[data-placement^='top'] {
    margin-bottom: 0;
  }
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year {
  font-weight: 700;
  font-size: 14px;
  color: var(--black);

  &::first-letter {
    text-transform: uppercase;
  }
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  visibility: visible !important;

  /* &::-webkit-scrollbar {
    inline-size: 1rem;
  } */
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  top: 8px;
  inline-size: auto;
  padding: 6px 0;
  background-color: var(--white);
  box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 6px 12px rgba(0, 23, 33, 0.07),
    0px 3px 6px rgba(0, 23, 33, 0.07);
  border-radius: 6px;
  border: 0;
}

.react-datepicker__month-dropdown {
  left: 0;
}

.react-datepicker__year-dropdown {
  right: 0;
  left: auto;
}

.react-datepicker__month-option {
  padding: 5px 12px;
  text-align: start;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);

  &:hover {
    border-radius: 0;
    background: #f0f5f8;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.react-datepicker__year-option {
  display: flex;
  align-items: center;
  block-size: 24px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);

  &:hover {
    border-radius: 0;
    background: #f0f5f8;
  }
}

.react-datepicker__navigation--years-upcoming {
  display: block;
  border-bottom: 4px solid #242b37 !important;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #242b37 !important;
}

.react-datepicker__navigation--years-previous {
  display: block;
  border-top: 4px solid #242b37 !important;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #242b37 !important;
}

.react-datepicker__month-option--selected,
.react-datepicker__year-option--selected {
  display: none;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option.--selected_month {
  border-radius: 0 !important;
  background: var(--blue);
  color: var(--white);

  &:hover {
    background: var(--blue);
    color: var(--white);
  }
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  all: initial;
  display: flex;
  flex-direction: column;
  block-size: 8px;
  margin-inline-start: 6px;

  &::before {
    all: initial;
    content: '';
    display: block;
    margin-block-end: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #95b4c1;
    border-top: 0;
  }

  &::after {
    content: '';
    display: block;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #95b4c1;
  }
}

.react-datepicker__day-names {
  display: none;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 32px;
  inline-size: 32px;
  margin: 1px;
  font-size: 14px;
  border-radius: 4px;
  color: var(--black);

  &:hover {
    background: #f3f7f9;
    border: 1px solid #d2e1e7;
    border-radius: 4px;
  }
}

.react-datepicker__day--selected {
  font-weight: 400;
  color: var(--white) !important;
  background: var(--blue);

  &:hover {
    border: 0;
    border-radius: 4px;
    background: var(--blue);
  }
}

.react-datepicker__day--weekend {
  color: var(--blue);
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__current-date {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: calc(100% + 32px);
  block-size: 40px;
  margin: 0 -16px;
  border-top: 1px solid #dce8ed;
  font-size: 14px;
  color: var(--blue);
}

.react-datepicker__day--disabled {
  color: #95b4c1;
}

.react-datepicker__day--today {
  font-weight: 400;
}

.react-datepicker__portal {
  & .react-datepicker__day {
    inline-size: 32px;

    @media (--md) {
      inline-size: 46px;
      block-size: 46px;
    }
  }

  & .react-datepicker__current-date {
    @media (--md) {
      block-size: 56px;
    }
  }

  & .react-datepicker__header {
    @media (--md) {
      padding: 16px 0;
    }
  }
}
