.table-wrapper {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

}
& :global(.ScrollbarsCustom-Track) {
  block-size: 4px !important;
  background-color: transparent !important;
  bottom: -13px !important;
}

& :global(.ScrollbarsCustom-Thumb) {
  background-color: #c1d1d8 !important;
}

& :global(.ScrollbarsCustom-Wrapper) {
  bottom: 0 !important;
}

& :global(.ScrollbarsCustom-Content) {
  padding: 0 !important;
}


.table {
  inline-size: 100%;
  overflow: hidden;
  overflow: visible;
}
