.container {
  padding-block-start: 24px;
  padding-block-end: 24px;

  @media (--md) {
    padding-block-start: 32px;
    padding-block-end: 32px;
  }
}

.wrapper {
  max-inline-size: 1016px;
}

.wrapper-empty {
  max-inline-size: 768px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-block-end: 24px;

  @media (--md) {
    margin-block-end: 32px;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 116%;

  @media (--md) {
    font-size: 32px;
    line-height: 40px;
  }
}

.filters-wrapper {
  padding: 24px;
  border: 1px solid #dde7ec;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0px 40px 50px rgba(17, 46, 58, 0.12);

  @media (--md) {
    display: flex;
  }
}

.menu {
  left: initial;
  right: 0;
  min-inline-size: 304px;
}

.empty-results {
  padding: 16px;
  background-color: var(--white);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  box-shadow: 0px 8px 30px rgba(9, 26, 33, 0.05), 0px 3px 10px rgba(10, 27, 34, 0.02);

  @media (--md) {
    padding: 24px 32px;
    font-size: 18px;
    line-height: 134%;
  }
}

.load-btn {
  block-size: 48px;
  inline-size: 100%;
  border-radius: 4px;
  background-color: #5c899c;
  color: var(--white);
  font-weight: 700;
  font-size: 16px;

  @media (--md) {
    block-size: 56px;
    font-size: 18px;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
}
