.branch {
    padding-top: 20px;
    position: relative;
}
/*Add downward connectors from parents*/
.branch .branch::before{
	content: '';
	position: absolute;
    top: 0;
    left: 50%;
	border-left: 1px solid #ccc;
	width: 0; 
    height: 20px;
}
