.wrapper {
  margin-block-start: 8px;
  width: calc(100% + 2px);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden;
  grid-row-start: 1;
  padding-bottom: 20px;
  @media (--sm) {
    grid-row-start: 1;
    column-gap: 0;
  }
  @media (--md) {
    margin-block-start: 16px;
    grid-row-start: 1;
  }

  @media (--lg) {
    margin-block-start: 24px;
    grid-row-start: unset;
  }
}
.table-wrapper {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

}
  :global(.ScrollbarsCustom-Track) {
  block-size: 4px !important;
  background-color: transparent !important;
  bottom: -13px !important;
}

  :global(.ScrollbarsCustom-Thumb) {
  background-color: #c1d1d8 !important;
}

 :global(.ScrollbarsCustom-Wrapper) {
  bottom: 0 !important;
}

 :global(.ScrollbarsCustom-Content) {
  padding: 0 !important;
}


.table {
  inline-size: 100%;
  overflow: hidden;
  overflow: visible;
}
.header {
    display: block;
    border-bottom: 1px solid #dce8ed;
    /* overflow: hidden; */
  }

  
  .row {
    display: grid;
    grid-gap: 0px 24px;
    block-size: 87px;
    align-items: center;
    padding: 0px 24px;
    background-color: #f8fbfc;
  
    @media (--md) {
      grid-gap: 0px 32px;
      padding: 0px 32px;
    }
  }
  .tableRow {
    grid-template-columns: repeat(4, 90px);
    animation: fadeIn 0.3s linear;
  }
  .cell {
    font-size: 14px;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);
    text-align: left;
    justify-content: space-between !important;
    justify-self: end;
  
    & :global(button) {
      font-size: 14px;
      line-height: 114%;
      font-weight: 500;
      color: var(--black);
    }
  }
  
  .body {
    background-color: var(--white);
  }
  .table-body-row {
    display: grid;
    grid-gap: 0px 24px;
    min-block-size: 32px;
    align-items: center;
    padding: 0px 24px;
    border-bottom: 1px solid #f0f5f8;
  
    @media (--md) {
      grid-gap: 0px 32px;
      padding: 0px 32px;
    }
  
    &:hover {
      box-shadow: 0 -1px 0 0 #d2e1e7;
      border-bottom-color: #d2e1e7;
      background-color: #f3f7f9;
      cursor: pointer;
    }
  }
  