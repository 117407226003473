.container {
  padding-block-end: 8px;

  @media (--md) {
    grid-area: 3 / span 2;
    padding-block-end: 16px;
  }

  @media (--xl) {
    grid-area: span 2 / auto;
    block-size: fit-content;
  }
}

.table {
  inline-size: 100%;

  &:first-child {
    margin-block-end: 16px;

    @media (--xl) {
      margin-block-end: 24px;
    }
  }
}

.table-title {
  padding: 16px;
  border-bottom: 1px solid #f0f5f8;
  font-size: 21px;
  line-height: 116%;
  color: var(--black);
  font-weight: 700;
  text-align: left;

  @media (--md) {
    padding: 32px;
    font-size: 24px;
    line-height: 99%;
  }
}

.row {
  display: grid;
  grid-template: auto / auto;
  padding: 11px 16px 17px;
  border-bottom: 1px solid #f0f5f8;

  @media (--md) {
    grid-template: auto / 128px auto;
    grid-gap: 0 36px;
    padding: 8px 32px;
  }
}

.cell {
  inline-size: 100%;

  & :global(.react-datepicker-wrapper) {
    inline-size: inherit;

    & :local(.field) {
      border-radius: 5px;
    }
  }
}

.row-title {
  display: block;
  margin-block-end: 4px;
  font-size: 10px;
  line-height: 200%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: start;
  color: var(--dark-gray);

  @media (--md) {
    align-self: center;
    min-inline-size: 128px;
    margin-block-end: 0;
    font-size: 12px;
    text-align: end;
  }
}

.row-read {
  text-align: start;

  @media (--md) {
    text-align: start;
  }
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  block-size: 32px;
  padding: 0 8px;
  border: 1px solid #d2e1e7;
  border-radius: 5px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  background: var(--gray);
  transition: border-color ease-in-out 0.2s;
  font-family: PT Root UI, system-ui;

  &:hover {
    border-color: #b0c9d3;
  }

  &:focus {
    border-color: #8fb3c1;
  }

  @media (--md) {
    block-size: 40px;
    padding: 0 13px;
    font-size: 18px;
    line-height: 134%;
  }
}

.field-error {
  border-color: var(--red) !important;
  background-color: #faf5f6;
}

.error {
  color: var(--red);

  @media (--md) {
    grid-area: 2 / 2;
  }
}

.emk-btn {
  font-size: 16px;
  line-height: 150%;
  color: var(--blue);
  text-decoration-line: underline;

  &:hover {
    text-decoration-line: none;
  }

  @media (--md) {
    font-size: 18px;
    line-height: 134%;
  }
}

.text {
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  @media (--md) {
    font-size: 18px;
    line-height: 134%;
  }
}

.adding-field-wrapper {
  display: flex;
  align-items: center;
  margin-block-start: 8px;
}

.adding-button {
  block-size: 32px;
  margin-inline-start: 8px;
  padding: 0 8px;
  border-radius: 5px;
  font-size: 16px;
  color: var(--white);
  background: var(--blue);
  white-space: nowrap;

  @media (--md) {
    margin-inline-start: 10px;
    block-size: 40px;
    padding: 0 10px;
    font-size: 18px;
  }
}
