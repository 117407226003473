@custom-selector :--headings h1, h2, h3, h4, h5, h6;
@custom-selector :--inputs input, select, textarea;
@custom-selector :--lists ol, ul;

:--headings {
  margin: 0;
  font-weight: 400;
}

:--inputs {
  outline: 0;
  padding: 0;
}

:--lists {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* body {
  background-color: #e5e5e5;
} */

p {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}

/* input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
} */

a {
  text-decoration: none;
}

input,
input[type='search'],
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
