.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 100vh;
  block-size: calc(100vh - 56px);

  @media (--md) {
    block-size: initial;
  }

  @media (--xl) {
    padding: 0 48px;
  }
}

.container {
  inline-size: 100%;
  padding: 24px 8px 32px;
  background-color: #f4f7f9;

  @media (--md) {
    padding: 32px 60px 48px;
  }

  @media (--xl) {
    max-inline-size: 1344px;
    margin: 0 auto;
    padding: 32px 0 48px;
  }
}

.form {
  @media (--md) {
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 24px;
  }

  @media (--xl) {
    grid-template: auto minmax(312px, auto) auto / 312px 1fr;
    inline-size: calc(100% - var(--comment-block-size));
    margin-block-end: 8px;
  }
}

.form-create {
  @media (--xl) {
    inline-size: 100%;
  }
}

.blocks-wrapper {
  grid-area: auto / span 2;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 24px;

  @media (--xl) {
    grid-template: initial;
    grid-area: 3 / 1;
    block-size: fit-content;
  }
}

/* Header styles */
.btn-save {
  background-color: var(--green);

  & :global(p) {
    color: white;
  }

  & :global(svg) {
    inline-size: 15px;
    block-size: 9px;
  }
}

.btn-cancel {
  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.btn-edit {
  background-color: var(--blue);

  & :global(p) {
    color: white;
  }

  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.btn-remove {
  & :global(svg) {
    inline-size: 12px;
    block-size: 16px;
  }
}

/* Mobile tabs */
.tabs {
  & :global(.react-tabs__tab-list) {
    display: flex;
    margin: 0 -8px 12px;
    padding: 0 8px;
    border-bottom: 1px solid #dde7ec;
  }

  & :global(.react-tabs__tab) {
    display: flex;
    justify-content: center;
    align-items: center;
    block-size: 40px;
    inline-size: 100%;
    font-size: 14px;
    line-height: 114%;
    font-weight: 500;
  }

  & :global(.react-tabs__tab--selected) {
    color: var(--blue);
    box-shadow: inset 0px -2px var(--blue);
  }
}

.emr {
  @media (--between-md-and-xl) {
    flex-direction: column;
    align-items: center;
    padding: 10px 14px 14px;
  }

  /* stylelint-disable-next-line */
  & :global(p) {
    @media (--between-md-and-xl) {
      margin-inline-end: 0;
      margin-block-end: 10px;
    }
  }

  /* stylelint-disable-next-line */
  & :global(div) {
    @media (--between-md-and-xl) {
      display: flex;
      flex-direction: column;
    }
  }

  /* stylelint-disable-next-line */
  & :global(button) {
    min-inline-size: 96px;

    /* &:not(:last-child) {
      margin-inline-end: 0;
      margin-block-end: 8px;
    } */
  }
}

.emr-button {
  &:not(:last-child) {
    @media (--between-md-and-xl) {
      margin-inline-end: 0;
      margin-block-end: 8px;
      justify-content: center;
    }
  }
}
