.container {
  @media (--md) {
    grid-area: auto / span 2;
  }

  @media (--lg) {
    grid-area: auto;
  }
}

.wrapper {
  border: 1px solid #dde7ec;
  border-radius: 10px;
  padding-block-end: 16px;
  background-color: var(--white);
}

.wrapper-empty {
  padding-block-end: 24px;
}

.title {
  padding: 16px 24px;
  font-size: 21px;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);

  @media (--md) {
    padding: 24px;
  }
}

.title-empty {
  padding: 24px 24px 8px;
}

.content {
  border-top: 1px solid #dce8ed;

  & :global(.ScrollbarsCustom-Content) {
    inline-size: 749px;
  }

  & :global(.ScrollbarsCustom-Track) {
    block-size: 4px !important;
    background-color: transparent !important;
    bottom: -11px !important;
  }

  & :global(.ScrollbarsCustom-Thumb) {
    background-color: #c1d1d8 !important;
  }

  & :global(.ScrollbarsCustom-Wrapper) {
    bottom: 0 !important;
  }
}

/* .content-hidden {
  display: none;
} */

.table {
  inline-size: 100%;
}

.table-readable {
  border-bottom: 1px solid #f0f5f8;
}

.text {
  padding: 0 24px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
}

.button-wrapper {
  padding: 16px 24px 0 24px;
  border-top: 1px solid #dce8ed;
}

.button {
  block-size: 32px;
  padding: 0 12px;
  border-radius: 3px;
  background-color: var(--blue);
  font-size: 14px;
  line-height: 114%;
  color: var(--white);
  font-weight: 500;
}
