.modal {
  max-inline-size: 368px;
  padding: 24px;
  border-radius: 6px;

  @media (--md) {
    padding: 32px;
  }
}

.mark {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 112px;
  block-size: 112px;
  margin-block-end: 22px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  border-radius: 50%;
  box-shadow: 0 16px 24px -4px rgba(2, 45, 63, 0.16);

  @media (--md) {
    margin-block-end: 32px;
  }
}

.text {
  margin-block-end: 22px;
  font-size: 18px;
  line-height: 133%;
  color: var(--black);
  font-weight: 500;
  text-align: center;

  @media (--md) {
    margin-block-end: 32px;
    font-size: 24px;
  }
}

.icon {
  inline-size: 37px;
  block-size: 23px;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 48px;
  background-color: var(--blue);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  color: var(--white);
}
