.wrapper {
  padding-block-end: 8px;
  border: 1px solid #dce8ed;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0px 8px 30px rgba(9, 26, 33, 0.05), 0px 3px 10px rgba(10, 27, 34, 0.02);

  @media (--md) {
    padding-block-end: 32px;
  }
}

.title {
  padding: 16px;
  font-weight: 700;
  font-size: 21px;
  line-height: 116%;
  color: var(--black);

  @media (--md) {
    padding: 32px;
    font-size: 24px;
    line-height: 99%;
  }
}

.contet-row {
  padding: 12px 16px 16px;
  border-bottom: 1px solid #f0f5f8;

  @media (--md) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 128px 1fr;
    padding: 8px 36px;
  }

  &:first-of-type {
    border-top: 1px solid #f0f5f8;
  }
}

.field-title {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.08em;
  line-height: 200%;
  text-transform: uppercase;
  color: #6e8189;

  @media (--md) {
    margin-block-end: 0;
    font-size: 12px;
  }
}

.field-title-edit {
  margin-block-start: 4px;

  @media (--md) {
    text-align: right;
  }
}

.field-text {
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  @media (--md) {
    font-size: 18px;
    line-height: 134%;
  }
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  min-block-size: 32px;
  padding: 0 8px;
  border: 1px solid #d2e1e7;
  border-radius: 5px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  background: #f3f7f9;

  &:hover {
    border-color: #b0c9d3;
  }

  &:focus {
    border-color: #8fb3c1;
  }

  &::placeholder {
    font-weight: 400;
  }
}

.field-date {
  max-inline-size: 120px;
}

.field-cheap {
  max-inline-size: 236px;
}

.textarea {
  inline-size: 100%;
  min-block-size: 128px;
  padding: 12px 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  border: 1px solid #d2e1e7;
  border-radius: 5px;
  background: #f3f7f9;
  resize: none;

  &:hover {
    border-color: #b0c9d3;
  }

  &:focus {
    border-color: #8fb3c1;
  }
}

.field-error,
.textarea-error {
  border-color: var(--red) !important;
  background-color: #faf5f6 !important;
}

.text-error {
  margin-block-start: 8px;
  font-size: 14px;
  line-height: 145%;
  color: var(--red);

  @media (--md) {
    margin-block-start: 0;
  }
}

.link {
  color: var(--blue);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
