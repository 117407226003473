.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 28px;
  line-height: 116%;
  color: var(--black);
  font-weight: 700;

  @media (--md) {
    font-size: 32px;
    line-height: 40px;
  }
}

.buttons {
  display: flex;
}

.button {
  &:not(:last-child) {
    margin-inline-end: 8px;

    @media (--md) {
      margin-inline-end: 16px;
    }
  }
}

.button-add {
  background-color: var(--blue);

  & :global(p) {
    color: var(--white);
  }

  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.button-reload {
  & :global(svg) {
    inline-size: 16px;
    block-size: 20px;
  }
}
