.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 100vh;
  block-size: calc(100vh - 56px);

  @media (--md) {
    block-size: initial;
  }

  @media (--xl) {
    padding: 0 48px;
  }
}

.container {
  inline-size: 100%;
  padding: 24px 8px 32px;
  background-color: #f4f7f9;

  @media (--md) {
    padding: 32px 60px 48px;
  }

  @media (--xl) {
    max-inline-size: 1344px;
    margin: 0 auto;
    padding: 32px 0 48px;
  }
}

.form {
  display: grid;
  grid-gap: 16px 0;

  @media (--md) {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 24px;
  }

  @media (--xl) {
    inline-size: calc(100% - var(--comment-block-size));
    margin-block-end: 8px;
  }
}

.form-create {
  @media (--xl) {
    inline-size: 100%;
  }
}

.blocks-wrapper {
  grid-area: auto / span 2;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 24px;

  @media (--xl) {
    grid-template: initial;
    grid-area: 3 / 1;
    block-size: fit-content;
  }
}

/* ---------------------------------------------------------------------------*/

.btn-save {
  background-color: var(--green);

  & :global(p) {
    color: white;
  }

  & :global(svg) {
    inline-size: 15px;
    block-size: 9px;
  }
}

.btn-cancel {
  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.btn-edit {
  background-color: var(--blue);

  & :global(p) {
    color: white;
  }

  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.btn-remove {
  & :global(svg) {
    inline-size: 12px;
    block-size: 16px;
  }
}

.head {
  @media (--md) {
    grid-area: auto / span 2;
  }
}

.feed-table-content {
  border-top: 0;
}

.row {
  display: grid;
  padding: 0 8px;
  border-top: 1px solid #f0f5f8;

  &:not(:last-of-type) {
    margin-block-end: 1px;
  }
}

.head-row {
  position: relative;
  margin-block-start: -1px;
  margin-block-end: -1px;
  padding: 0;
  border-bottom: 1px solid #dce8ed;
  z-index: 1;
}

.vaccinations-row {
  grid-template: auto / 120px 89px minmax(180px, auto);
}

.vaccinations-row-expanded {
  grid-template: auto / 120px 89px 180px minmax(180px, auto);
}

.antiparasitics-row {
  grid-template: auto / 120px 89px minmax(180px, auto);
}

.ills-row {
  grid-template: auto / 160px 160px minmax(180px, auto);
}

.matings-row {
  grid-template: auto / 120px 89px minmax(180px, auto);
}

.lambings-row {
  grid-template: repeat(2, auto) / 120px 100px 80px 80px minmax(80px, auto);
  padding-inline-end: 0;
}

.lambings-row th {  
  &:nth-child(3) {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  &:nth-child(4) {
    display: flex;
    justify-content: center;
    padding: 0;
  }
}

.feed-row {
  grid-template: auto / 60px minmax(180px, auto);
}

.cell {
  position: relative;
  min-block-size: 32px;
  padding: 0;
  border-right: 1px solid transparent;
  font-size: 16px;
  color: var(--black);

  & :global(.react-datepicker-wrapper) {
    block-size: 100%;
    inline-size: 100%;
  }

  & :global(.react-datepicker__input-container) {
    block-size: 100%;
    inline-size: 100%;
  }
}

.head-cell {
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: 14px;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  background-color: #f9fbfc;
}

.text-cell {
  border-left: 1px solid #f0f5f8;
  border-bottom: 1px solid #f0f5f8;
}

.last-text-cell {
  border-bottom: 0;
}

.lambings-date-cell {
  grid-area: span 2 / 1;
}

.lambings-female-data-cell {
  border-bottom: 1px solid #f0f5f8;
}

.lambings-tight-cell {
  padding-inline-end: 8px;
}

.field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  min-block-size: 32px;
  /* block-size: 100%; */
  padding: 0 16px;
  border: 0;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px #d2e1e7;
    background-color: #f3f7f9;
  }

  &:focus {
    border-radius: 3px;
    background-color: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px -2px 6px rgba(0, 23, 33, 0.07),
      0px 1px 1px rgba(0, 23, 33, 0.07), 0px 3px 3px rgba(0, 23, 33, 0.07);
    cursor: initial;
  }

  &::placeholder {
    color: #7f8d94;
  }
}

.new-field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
  background-color: transparent;

  &::placeholder {
    color: #809fad;
  }
}

.text {
  display: flex;
  /* align-items: center; */
  block-size: 100%;
  padding: 4px 16px;
  font-size: 16px;
  color: var(--black);
}

.error {
  position: absolute;
  top: -30px;
  left: 8px;
  display: flex;
  align-items: center;
  inline-size: max-content;
  block-size: 32px;
  padding: 0 14px;
  color: var(--white);
  border-radius: 5px;
  background-color: var(--dark-blue);
  box-shadow: 0 0 3px 1px var(--white);
  z-index: 1;
  user-select: none;

  &:hover {
    z-index: 2;
  }

  &::before {
    position: absolute;
    bottom: -6px;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--dark-blue);
  }
}

/* diseases table styles */
/* -------------------------------------------------------------------------- */

.disease-button-wrapper {
  display: flex;
  align-items: center;
}

.disease-head-button {
  border-bottom: 1px dashed #96b3c0;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
  white-space: nowrap;

  &:hover {
    border-bottom-color: transparent;
  }
}

.sort-icon {
  margin-inline-start: 10px;
  inline-size: 9px;
  block-size: 12px;
}

.sort-icon-invert {
  transform: rotate(180deg);
}

.disease-content-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: var(--blue);
  text-align: initial;
  text-decoration: underline dashed;

  &:hover {
    border-bottom-color: transparent;
    text-decoration: none;
  }
}
