.field-title {
  margin-block-end: 8px;
  font-size: 12px;
  line-height: 200%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #54656c;

  @media (--md) {
    margin-inline-start: auto;
    margin-block-end: 0;
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 8px;

  & :global(p) {
    margin-block-end: 0;
  }
}

.btn-generate {
  white-space: nowrap;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 200%;
  color: var(--blue);

  @media (--md) {
    font-size: 16px;
    line-height: 150%;
    text-transform: initial;
    letter-spacing: initial;
  }
}

.field {
  inline-size: 100%;
  margin-block-end: 16px;
  block-size: 48px;
  padding: 0 16px;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (--md) {
    margin-block-end: 0;
  }
}

.field-password {
  display: flex;
  align-items: center;
  margin-block-end: 16px;
  padding-inline-start: 0;

  @media (--md) {
    margin-block-end: 0;
  }

  & :global(input) {
    inline-size: 100%;
    margin-block-end: 0;
    block-size: 100%;
    padding-inline-start: 16px;
    border: 0;
    font-size: 16px;
    line-height: 150%;
    color: var(--black);
    white-space: nowrap;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & :global(button) {
    margin-inline-start: 16px;
  }
}

.field-error {
  margin-block-end: 0;
  border-color: var(--red);
}

.field-phone {
  &:hover {
    border-color: #d0dfe6;
  }

  &:focus {
    border-color: #d0dfe6;
  }
}

.error {
  margin-block-end: 16px;
  color: var(--red);
  font-size: 14px;
  line-height: 145%;

  @media (--md) {
    grid-area: auto / 2;
    margin-block-start: -16px;
    margin-block-end: 0;
  }
}
