.wrapper {
  padding: 20px;

  @media (--md) {
    display: grid;
    grid-template: auto / repeat(2, auto);
    padding: 40px;
  }

  @media (--lg) {
    grid-area: span 2 / auto;
    min-block-size: 329px;
    block-size: fit-content;
    padding: 32px 24px;
  }
}

.form {
  display: flex;
  align-items: center;

  @media (--md) {
    flex-direction: row-reverse;
  }

  @media (--lg) {
    grid-area: auto / span 2;
    flex-direction: column;
  }
}

.title {
  composes: visually-hidden from '../../../../assets/styles/helpers.css';

  @media (--only-md) {
    all: initial !important;
    align-self: center !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 99%;
    color: var(--black);
  }
}

.avatar {
  margin-inline-end: 28px;
  overflow: hidden;

  @media (--md) {
    margin-inline-end: 0;
    margin-inline-start: 40px;
  }

  @media (--lg) {
    margin-inline-start: 0;
    margin-block-end: 32px;
  }
}

.error {
  margin-block-start: 16px;
  font-size: 15px;
  line-height: 142%;
  color: var(--red);

  @media (--md) {
    grid-area: auto / span 2;
    margin-block-start: 24px;
    text-align: end;
  }

  @media (--lg) {
    text-align: center;
  }
}
