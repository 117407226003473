.leaf{
    width: 7.438rem;
    border-radius: 8px;
    border: 1px solid #C1D1D8;
    background: #F0F5F8;
    cursor: pointer;
}
.inner{
    padding: 1rem;
    text-align: center;
}
.image{
    width: 100%;
    height: 3.5rem;
    border-radius: 4px;
    margin-bottom: 0.8rem;
    overflow: hidden;
}
.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.title{
    font-weight: 500;
    font-size: 16px;
    position: relative;
}
.title[data-tooltip] {
    position: relative;
}
.title[data-tooltip]:hover::before {
    visibility: visible;
    opacity: 1;
  }
.title[data-tooltip]::before {
    text-align:center;
    content: attr(data-tooltip);
    visibility: hidden;
    opacity: 0;
    min-width: 100%;
    width: max-content;
    height: max-content;
    position: absolute;
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    padding: 4px 8px;
    background-color: #5a5a5a;
    color: white;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: 500;
    white-space: nowrap;
    transition: opacity ease-out 150ms, bottom ease-out 150ms;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
.tag{
    font-weight: 400;
    font-size: 14px;
    margin-top: 0.4rem;
}