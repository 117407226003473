:root {
  --white: #fff;
  --black: #0c1116;
  --green: #2cca7e;
  --blue: #00a6ed;
  --red: #eb4458;
  --gray: #f3f7f9;
  --dark-gray: #6e8189;
  --dark-blue: #5c899c;

  --comment-block-size: 23rem;
}
