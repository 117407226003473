.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 8px;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.modal {
  display: grid;
  inline-size: 100%;
  max-inline-size: 690px;
  margin: 10px auto;
  outline: none;
  border-radius: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    inline-size: 0;
  }

  @media (--md) {
    margin: 40px auto;
  }
}

.wrapper {
  margin-block-start: auto;
  margin-block-end: auto;
  padding-block-end: 32px;
  background: var(--white);
  border-radius: 10px;
  overflow: auto;

  @media (--md) {
    padding-block-end: 40px;
  }
}

.btn-close {
  margin-block-start: 15px;
  margin-inline-end: 18px;

  @media (--md) {
    margin-block-start: 25px;
    margin-inline-end: 25px;
  }

  & :global(svg) {
    block-size: 12px;
    inline-size: 12px;

    @media (--md) {
      block-size: 14px;
      inline-size: 14px;
    }
  }
}

.title {
  margin-inline-start: 20px;
  margin-inline-end: 20px;
  margin-block-end: 32px;
  font-size: 28px;
  line-height: 116%;
  font-weight: bold;
  color: var(--black);

  @media (--md) {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    margin-block-end: 40px;
    font-size: 24px;
    line-height: 99%;
  }
}

.contet-row {
  padding: 8px 20px 8px 20px;
  border-bottom: 1px solid #f0f5f8;

  @media (--md) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 180px 1fr;
    padding: 8px 40px 8px 40px;
  }

  &:first-of-type {
    border-top: 1px solid #f0f5f8;
  }
}

.contet-row-editable {
  border-color: transparent;

  &:first-of-type {
    border-color: transparent;
  }
}

.field-title {
  margin-block-end: 4px;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #6e8189;

  @media (--md) {
    margin-block-start: 4px;
    margin-block-end: 0;
    font-size: 12px;
    text-align: right;
  }
}

.field-title-editable {
  @media (--md) {
    margin-block-start: 16px;
  }
}

.field-wrapper {
}

.field-text {
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
}

.modal-field {
  composes: truncate from '../../../../assets/styles/helpers.css';
  inline-size: 100%;
  min-block-size: 48px;
  padding: 0 16px;
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
}

.modal-textarea {
  inline-size: 100%;
  min-block-size: 96px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  border: 1px solid #d0dfe6;
  border-radius: 4px;
  resize: none;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 32px;

  @media (--md) {
    flex-direction: row;
    margin-inline-start: 252px;
    margin-block-start: 40px;
  }
}

.modal-button {
  display: flex;
  align-items: center;
  block-size: 40px;
  padding: 0 13px;
  background-color: var(--white);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  &:first-child {
    margin-block-end: 16px;

    @media (--md) {
      margin-block-end: 0;
      margin-inline-end: 16px;
    }
  }
}

.button-text {
  margin-inline-start: 10px;
}

.button-pen {
  background: var(--blue);
  color: var(--white);

  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
  }
}

.button-trash {
  border: 1px solid #cddbe4;

  & :global(svg) {
    inline-size: 12px;
    block-size: 16px;
  }
}

.button-check-bold {
  background: var(--green);
  color: var(--white);

  & :global(svg) {
    inline-size: 16px;
    block-size: 10px;
  }
}

.button-close-bold {
  border: 1px solid #cddbe4;

  & :global(svg) {
    inline-size: 12px;
    block-size: 12px;
  }
}
