.button {
  display: none;
  align-items: center;
  block-size: 32px;
  padding: 0 10px;
  border-radius: 3px;
  font-size: 0;
  color: var(--black);

  &:not(:last-child) {
    margin-inline-end: 8px;
  }

  @media (--md) {
    font-size: 14px;
    line-height: 114%;
    font-weight: 500;
    white-space: nowrap;
  }
}

.button-visible {
  display: flex;
}

.button-pen {
  color: var(--white);
  background-color: var(--blue);

  & :global(svg) {
    fill: var(--white);
  }
}

.button-trash {
  border: 1px solid #d2e1e7;
  color: var(--black);
}

.button-check-bold {
  color: var(--white);
  background-color: var(--green);
}

.button-close-bold {
  border: 1px solid #d2e1e7;
}

.icon {
  @media (--md) {
    margin-inline-end: 6px;
  }
}

.icon-pen {
  inline-size: 12px;
  block-size: 12px;
}

.icon-trash {
  inline-size: 14px;
  block-size: 14px;
}

.icon-check-bold {
  inline-size: 14px;
  block-size: 9px;
}

.icon-close-bold {
  inline-size: 10px;
  block-size: 10px;
}
