.wrapper {
  margin-block-start: 8px;
  width: calc(100% + 2px);
  border: 1px solid #dce8ed;
  border-radius: 10px;
  overflow: hidden;

  @media (--md) {
    margin-block-start: 16px;
  }

  @media (--lg) {
    margin-block-start: 24px;
  }
}

.table-row {
  grid-template: auto / 112px 208px 136px 103px 208px 159px;
}

.table-body-row {
  display: grid;
  grid-gap: 0px 24px;
  min-block-size: 32px;
  align-items: center;
  padding: 0px 24px;
  border-bottom: 1px solid #f0f5f8;

  @media (--md) {
    grid-gap: 0px 32px;
    padding: 0px 32px;
  }

  &:hover {
    box-shadow: 0 -1px 0 0 #d2e1e7;
    border-bottom-color: #d2e1e7;
    background-color: #f3f7f9;
    cursor: pointer;
  }
}

.table-cell {
  &:first-child,
  &:nth-child(4) {
    text-align: right;
  }
}

.footer {
  inline-size: 100%;
  padding: 24px 32px;
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (--md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.arrow {
  margin-inline-start: 9px;
  inline-size: 9px;
  block-size: 15px;
}

.arrow-ordered {
  transform: rotate(180deg);
}

.button-text {
  display: flex;
  justify-content: space-between;
  inline-size: 100%;
  font-size: 14px;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  white-space: nowrap;
}
