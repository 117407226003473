@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Lignt'), url('../../fonts/pt-root-ui-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Regular'), url('../../fonts/pt-root-ui-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Medium'), url('../../fonts/pt-root-ui-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PT Root UI;
  src: local('PT Root UI Bold'), url('../../fonts/pt-root-ui-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
