.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.container{
    display: flex;
}
.frame{
     overflow: hidden;
     width: 150px;
     @media (--md) {
      width: 512px;
    }
}
.buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.arrow-wrapper{
  display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 32px;
    block-size: 32px;
    margin-block-end: 1px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);
}
.arrow-wrapper:hover{
  border: 1px solid #d2e1e7;
  cursor: pointer;
}
.arrow {
  inline-size: 10px;
  block-size: 13px;
  fill: var(--blue) !important;
}
.arrow-right{
  transform: rotate(180deg);
}
.disabled{
  pointer-events: none;
  opacity: 0.3;
  
}