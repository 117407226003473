.cell {
  font-size: 14px;
  line-height: 114%;
  font-weight: 500;
  color: var(--black);
  text-align: left;
  justify-content: space-between !important;
}

.field {
  margin-block-start: 8px;
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 0px 1px rgba(0, 98, 140, 0.7), 0px 10px 20px rgba(0, 23, 33, 0.04),
    0px 6px 12px rgba(0, 23, 33, 0.04), 0px 3px 6px rgba(0, 23, 33, 0.04);
}

.icon {
  inline-size: 10px;
  block-size: 11px;
}
