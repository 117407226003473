.wrapper {
  display: flex;
  justify-content: space-between;
  margin-block-start: 24px;
  margin-block-end: 24px;

  @media (--md) {
    margin-block-start: 32px;
    margin-block-end: 32px;
  }
}

.title {
  font-size: 28px;
  line-height: 116%;
  color: var(--black);

  @media (--md) {
    font-size: 32px;
    line-height: 40px;
  }
}
