.wrapper {
  display: flex;
  align-items: flex-start;
  /* inline-size: max-content; */
  inline-size: fit-content;
  min-block-size: 40px;
  padding: 4px;
  padding-inline-start: 16px;
  border: 1px solid #cddbe4;
  border-radius: 5px;
  background-color: var(--white);

  @media (--md) {
    padding: 3px;
    padding-inline-start: 16px;
  }
}

.title {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: var(--black);
  font-weight: 500;
  vertical-align: middle;
}

.text {
  margin-inline-end: 16px;
  font-size: 14px;
  line-height: 27px;
  font-weight: 500;

  @media (--md) {
    font-size: 16px;
  }
}

.number {
  display: inline-block;
  vertical-align: middle;
}

.link {
  font-size: inherit;
  line-height: inherit;
  color: var(--blue);
}

.buttons {
  display: flex;
}

.button {
  min-block-size: 32px;
  padding: 0 12px;
  border-radius: 3px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  &:not(:last-child) {
    margin-inline-end: 5px;
  }
}

.button-make {
  display: flex;
  align-items: center;
  background-color: var(--blue);
}

.button-bind {
  background-color: #5c899c;
}

.button-unbind {
  background-color: #5c899c;
}
