.list {
  display: flex;
  flex-wrap: wrap;

  & :global(a) {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 32px;
    block-size: 32px;

    &:focus {
      outline: 0;
    }
  }

  /* stylelint-disable-next-line */
  & > :global(li) {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 32px;
    block-size: 32px;
    margin-block-end: 1px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 114%;
    font-weight: 500;
    color: var(--black);

    &:hover {
      border: 1px solid #d2e1e7;
      cursor: pointer;
    }

    &:not(:last-of-type) {
      margin-inline-end: 3px;
    }

    &:global(.disabled) {
      & :global(svg) {
        fill: #c4cdd0 !important;
      }
    }

    /* stylelint-disable-next-line */
    &:global(.selected) {
      color: var(--white);
      background-color: var(--blue);

      &:hover {
        border: 0;
      }
    }
  }
}

.arrow {
  inline-size: 10px;
  block-size: 13px;
  fill: var(--blue) !important;
}

.arrow-left {
  transform: rotate(180deg);
}

.dots {
  display: flex;
}

.dot {
  inline-size: 4px;
  block-size: 4px;
  background-color: var(--blue);
  border-radius: 50%;

  &:not(:last-child) {
    margin-inline-end: 2px;
  }
}
