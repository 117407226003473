.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 8px;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.modal {
  inline-size: 100%;
  margin: auto;
  background: var(--white);
  outline: none;
  overflow: auto;
}
