.node{
    list-style-type: none;
	float: left;
    text-align: center;
	
	position: relative;
	padding: 20px 5px 0 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
/*We will use ::before and ::after to draw the connectors*/
.node::before, .node::after{
	content: '';
	position: absolute;
    top: 0;
    right: 50%;
	border-top: 1px solid #ccc;
	width: 50%;
    height: 20px;
}
.node::after{
	right: auto;
    left: 50%;
	border-left: 1px solid #ccc;
}
/*We need to remove left-right connectors from elements without 
any siblings*/
.node:only-child::after, .node:only-child::before {
	display: none;
}
/*Remove space from the top of single children*/
.node:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.node:first-child::before, .node:last-child::after{
	border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.node:last-child::before{
	border-right: 1px solid #ccc;
	border-radius: 0 5px 0 0;
	-webkit-border-radius: 0 5px 0 0;
	-moz-border-radius: 0 5px 0 0;
}
.node:first-child::after{
	border-radius: 5px 0 0 0;
	-webkit-border-radius: 5px 0 0 0;
	-moz-border-radius: 5px 0 0 0;
}