.wrapper {
  padding: 20px 20px 12px;

  @media (--md) {
    padding: 40px;
  }
}

.title {
  margin-block-end: 20px;
  font-size: 21px;
  line-height: 116%;
  font-weight: 700;
  color: var(--black);

  @media (--md) {
    margin-block-end: 32px;
    font-size: 24px;
    line-height: 99%;
  }
}
