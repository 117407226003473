.wrapper {
    margin-block-start: 8px;
    width: calc(100% + 2px);
    border: 1px solid #dce8ed;
    border-radius: 10px;
    overflow: hidden;
    
    @media (--md) {
      margin-block-start: 16px;
    }
  
    @media (--lg) {
      margin-block-start: 24px;
    }
  }
  
  .table-row {
    grid-template: auto / 95px 95px 120px 120px 120px 120px 120px 105px 120px 50px 100px 100px 100px minmax(
      120px,
      1fr
    );
    
  animation: fadeIn 0.3s linear;
  }
  
  .table-body-row {
    display: grid;
    grid-gap: 0px 24px;
    min-block-size: 32px;
    align-items: center;
    padding: 0px 24px;
    border-bottom: 1px solid #f0f5f8;
  
    @media (--md) {
      grid-gap: 0px 32px;
      padding: 0px 32px;
    }
  
    &:hover {
      box-shadow: 0 -1px 0 0 #d2e1e7;
      border-bottom-color: #d2e1e7;
      background-color: #f3f7f9;
      cursor: pointer;
    }
  }
  
  .table-cell {
    &:first-child {
      text-align: right;
    }
  
    &:nth-child(2) {
      text-align: right;
    }
  
    &:nth-child(5) {
      text-align: right;
    }
  }
  .datepicker {
    width: 100%;
    border: none;
  }
  
  .footer {
    inline-size: 100%;
    padding: 24px 32px;
    background-color: var(--white);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  
    @media (--md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .arrow {
    margin-inline-start: 9px;
    inline-size: 9px;
    block-size: 15px;
  }
  
  .arrow-ordered {
    transform: rotate(180deg);
  }