.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 8px;
  background-color: rgba(12, 17, 22, 0.35);
  z-index: 1;
}

.modal {
  max-inline-size: 420px;
  inline-size: 100%;
  margin: auto;
  background: var(--white);
  outline: none;
  overflow: auto;
  border-radius: 10px;
}

.btn-close {
  margin-block-start: 16px;
  margin-inline-end: 16px;
}

.title {
  margin-block-end: 22px;
  padding: 0 28px;
  font-size: 21px;
  line-height: 138%;
  color: var(--black);
  font-weight: 700;
}

.list {
  margin-block-end: 24px;
}

.binding-btn {
  block-size: 32px;
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  border: 1px solid #95b4c1;
  color: #95b4c1;
  border-radius: 3px;
  white-space: nowrap;
  transition: background-color ease-in-out 0.12s, color ease-in-out 0.12s;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  block-size: 40px;
  padding: 0 28px;
  border-top: 1px solid #f0f5f8;
  border-bottom: 1px solid #f0f5f8;

  &:not(:first-child) {
    margin-block-start: -1px;
  }

  &:hover {
    background-color: #f3f7f9;
    border-color: #d2e1e7;

    & .binding-btn {
      background-color: #5c899c;
      color: #fff;
    }

    & + .list-item {
      border-top-color: #d2e1e7;
    }
  }
}

.identifier {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--black);
  box-shadow: 0 1px 0 black;
  transition: box-shadow ease-in-out 0.1s;

  &:hover {
    box-shadow: 0 1px 0 transparent;
  }
}

.empty-block {
  margin-block-end: 28px;
  padding: 0 28px;
}

.empty-text {
  margin-block-end: 18px;
  font-size: 14px;
  line-height: 140%;
  color: var(--black);
}

.make-btn {
  display: inline-flex;
  align-items: center;
  block-size: 40px;
  padding: 0 16px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
}

.pagination {
  margin-block-end: 18px;
  padding: 0 28px;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
}
