.wrapper {
  padding-block-end: 24px;
  background-color: var(--white);
  box-shadow: 0px 8px 30px rgba(9, 26, 33, 0.05), 0px 3px 10px rgba(10, 27, 34, 0.02);
  border-radius: 10px;
}

.header {
  block-size: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  @media (--md) {
    block-size: 72px;
  }
}

.title {
  & a {
    color: var(--black);
    font-size: 21px;
    line-height: 116%;
    font-weight: 700;
  }

  @media (--md) {
    font-size: 24px;
    line-height: 99%;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 32px;
  inline-size: 32px;
  background-color: var(--blue);
  border-radius: 5px;

  & :global(svg) {
    inline-size: 14px;
    block-size: 14px;
    fill: var(--white) !important;
  }
}

.content {
  & :global(.ScrollbarsCustom-Track) {
    block-size: 4px !important;
    background-color: transparent !important;
    bottom: -13px !important;
  }

  & :global(.ScrollbarsCustom-Thumb) {
    background-color: #c1d1d8 !important;
  }

  & :global(.ScrollbarsCustom-Wrapper) {
    bottom: 0 !important;
  }
}

/* .content-hidden {
  display: none;
} */

.row-content {
  &:hover {
    background-color: #f3f7f9;
    cursor: pointer;

    & :global(td) {
      border-bottom-color: #d2e1e7;
    }

    &:not(:first-child) {
      & :global(td) {
        box-shadow: 0 -1px #d2e1e7;
      }
    }
  }
}

.cell {
  min-block-size: 32px;
  padding: 6px 32px;
  border-top: 1px solid #f0f5f8;
  border-bottom: 1px solid #f0f5f8;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
  /* min-inline-size: 200px; */

  &:last-child {
    min-inline-size: 185px;
  }
}

.cell-heading {
  background-color: #f9fbfc;
  border-top-color: #dce8ed;
  border-bottom-color: #dce8ed;
  font-weight: 500;
  text-align: left;
  white-space: nowrap !important;

  &:nth-child(2) {
    white-space: nowrap !important;
  }
}

.placeholder {
  margin-block-start: -5px;
  padding: 0 24px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  @media (--md) {
    margin-block-start: 0;
  }
}

/* .placeholder-hidden {
  display: none;
} */
