.fieldset {
  &:first-of-type {
    margin-block-end: 10px;
  }
}

.link {
  font-size: 16px;
  line-height: 150%;
  color: var(--blue);
}

.checkbox {
  margin: 24px 0;
}
