.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.visually-hidden {
  position: absolute !important;
  inline-size: 1px !important;
  block-size: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}
