.checkbox-wrapper {
  position: relative;
  display: block;
  inline-size: 16px;
  block-size: 16px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  inline-size: 16px;
  block-size: 16px;
  border: 2px solid #c1d1d8;
  border-radius: 2px;
  background-color: var(--white);

  &::after {
    content: '';
    position: absolute;
    display: none;
    border: solid white;
  }

  &.check {
    &::after {
      left: 3px;
      top: 0px;
      inline-size: 6px;
      block-size: 9px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &.dash {
    &::after {
      left: 1px;
      top: 5px;
      inline-size: 10px;
      border-width: 0 2px 2px 0;
    }
  }
}

.checked {
  background-color: #5c899c;
  border-color: #5c899c;

  &::after {
    display: block;
  }
}
