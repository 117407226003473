.text {
  margin-block-end: 24px;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);

  @media (--md) {
    margin-block-end: 32px;
  }
}

.fieldset {
  margin-block-end: 24px;
}
